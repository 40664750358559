import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";
import { useSnackbar } from "notistack";
import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import axios from "axios";
import ReactQuill from 'react-quill';
import Select from 'react-select';

import { HBox, VBox } from "../../../components/Containers";
import { H3, H5 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText, InputTextarea } from "../../../components/InputText";
import colors from "../../../config/colors";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const BlogEditContainer = styled(VBox)`
    flex-wrap: nowrap;
    overflow-y: auto;
`

const FormatOptionComponent = ({ value, label, image, details }) => {
    return (
        <HBox align='center'>
            {image &&
                <img className='mr-4' src={`${baseURL}${image}`} style={{height: '50px', width: 'auto'}} />
            }
            <HBox>{label}</HBox>
        </HBox>
    )
}

var options = [];
var categoryOptions = [];

const BlogEditDlg = ({ open, setOpen, id, modules }) => {
    const [coverImage, setCoverImage] = useState('');
    const [coverImageData, setCoverImageData] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [authorName, setAuthorName] = useState('');
    const [authorId, setAuthorId] = useState('');
    const [authorDetails, setAuthorDetails] = useState('');
    const [authors, setAuthors] = useState([]);
    const [authorImage, setAuthorImage] = useState('');
    const [authorImageData, setAuthorImageData] = useState('');
    const [optionsAuthorImage, setOptionsAuthorImage] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [optionsCategoryValue, setOptionsCategoryValue] = useState('');
    const [renderFlag, setRenderFlag] = useState(false);
    const [inputTimeout, setInputTimeout] = useState('');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const authorImageRef = useRef();
    const coverImageRef = useRef();

    useEffect(() => {
        if(open) {
            getData();
            getAuthorData();                // get the latest values of options
        }                                   // after changing author image
    }, [open])

    useEffect(() => {
        handleSearch();
    }, [authorName]);

    useEffect(() => {
        getCategoryData();
    }, [])

    useEffect(() => {
        categoryOptions = [];
        categories?.map((category) => {
            categoryOptions.push({
                value: category.id,
                label: category.name
            })
        })
    }, [categories])

    useEffect(() => {
        options = [];
        authors?.map((author) => {
            options.push({ 
                value: author.id, 
                label: author.name, 
                image: author.image, 
                details: author.details
            });
        });
        setRenderFlag(!renderFlag);         // re-render with the latest values of options
    }, [authors])

    const handleClose = (event, reason) => {
        if(reason && reason === 'backdropClick')
            return;
        
        setOpen(false);
        setCoverImage('');
        setCoverImageData('');
        setTitle('');
        setContent('');
        setAuthorName('');
        setAuthorId('');
        setAuthorDetails('');
        setAuthors([]);
        setAuthorImage('');
        setAuthorImageData('');
        setTitle('');
        setOptionsAuthorImage('');
        setSelectedCategory('');
        setOptionsCategoryValue('');
        clearCoverImage();
        clearAuthorImage();
    }                              

    const handleSearch = () => {
        if(inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(()=>{
                getAuthorData();
            }, 300)
        );
    }

    const handleCoverImage = (e) => {
        let file = new FileReader();
        file.onloadend = () => {
            setCoverImageData(file.result);
        }
        file.readAsDataURL(e.target.files[0]);
        
        setCoverImage(e.target.files[0]);
    }

    const clearCoverImage = () => {
        coverImageRef.current.value = ''; 
        setCoverImage('');                    // set this state to empty string here because setting
        setCoverImageData('');                // coverImageRef.current.value does not invoke onChange event
    }
    
    const handleAuthorImage = (e) => {
        let file = new FileReader();
        file.onloadend = () => {
            setAuthorImageData(file.result);
        }
        file.readAsDataURL(e.target.files[0]);
        
        setAuthorImage(e.target.files[0]);
    }
    
    const clearAuthorImage = () => {
        authorImageRef.current.value = ''; 
        setAuthorImage('');                     // set this state to empty string here because setting
        setAuthorImageData('');                 // authorImageRef.current.value does not invoke onChange event
    }

    const submitData = () => {
        if(validate()) {
            let formData = new FormData();
            formData.append('id', id);
            formData.append('title', title);
            formData.append('content', content);
            formData.append('cover_image', coverImage);
            formData.append('author_id', authorId);
            formData.append('author_name', authorName);
            formData.append('author_details', authorDetails);
            formData.append('author_image', authorImage);
            formData.append('categories', JSON.stringify(selectedCategory));

            axios({
                method: 'PUT',
                url: 'nhadmin/blog-details/',
                data: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
                }
            })
            .then((response) => {
                if(response.status===200) {
                    enqueueSnackbar('Blog Successfully Edited', {variant: 'success'});
                    setTimeout(()=>closeSnackbar(), 4000);
                }
                else {
                    enqueueSnackbar('Blog Edit Failed', {variant: 'success'});
                    setTimeout(()=>closeSnackbar(), 4000);
                }
            })
            .catch((error) => {
                enqueueSnackbar('Blog Edit Error', {variant: 'error'});
                setTimeout(()=>closeSnackbar(), 4000);
            })
        }
    }

    const getAuthorData = () => {
        axios({
            method: 'GET',
            url: 'nhadmin/blog-author-list/',
            params: {
                key: authorName,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
            }
        })
        .then((response) => {
            if(response.status === 200) {
                setAuthors(response.data);
            }
            else {
                console.log("AUTHOR DATA FETCH FAILED");
            }
        })
        .catch((error) => {
            console.log("AUTHOR DATA FETCH ERROR", error);
        })
    }

    const getData = () => {
        axios({
            method: 'GET',
            url: 'nhadmin/blog-details/',
            params: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if(response.status === 200) {
                setCoverImage(response.data.cover_image ? response.data.cover_image : '');
                setTitle(response.data.title ? response.data.title : '');
                setContent(response.data.content ? response.data.content : '');
                setAuthorId(response.data.author ? (response.data.author.id ?  response.data.author.id : '') : '');
                setAuthorName(response.data.author ? (response.data.author.name ?  response.data.author.name : '') : '');
                setAuthorImage(response.data.author ? (response.data.author.image ?  response.data.author.image : '') : '');
                setAuthorDetails(response.data.author ? (response.data.author.details ?  response.data.author.details : '') : '');
                setOptionsAuthorImage(response.data.author ? (response.data.author.image ?  response.data.author.image : '') : '');
                
                let categories = response.data.category, categoryArray = [], selectedCategoryArray = [];
                categories?.map((category)=>{
                    categoryArray.push({value: category.id, label: category.name});
                    selectedCategoryArray.push(category.name);
                });
                
                setOptionsCategoryValue(categoryArray);
                setSelectedCategory(selectedCategoryArray);
            }
            else {
                console.log('BLOG DATA FETCH FAILED');
            }
        })
        .catch((response) => {
            console.log('BlOG DATA FETCH ERROR', response);
        })
    }

    const getCategoryData = () => {
        axios({
            method: 'GET',
            url: 'nhadmin/blog-category-list/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
            }
        })
        .then((response) => {
            if (response.status === 200) {
                setCategories(response.data.categories);
            }
            else {
                console.log("CATEGORY DATA FETCH FAILED");
            }
            
        })
        .catch((error) => {
            console.log("CATEGORY DATA FETCH ERROR", error);
        })
    }

    const validate = () => {
        if(!selectedCategory || selectedCategory?.length === 0) {
            enqueueSnackbar('Category cannot be kept blank.', {variant: 'error'});

            return false;
        }
        if(!title) {
            enqueueSnackbar('Title cannot be kept blank.', {variant: 'error'});
            
            return false;
        }
        if(!content) {
            enqueueSnackbar('Content cannot be kept blank.', {variant: 'error'});
            
            return false;
        }
        if(!authorName && authorDetails) {
            enqueueSnackbar('Cannot add author details without author.', {variant: 'error'});
            
            return false
        }
        if(!authorName && authorImage) {
            enqueueSnackbar('Cannot add author image without author.', {variant: 'error'});
            
            return false
        }

        return true;
    }
    
    return (
        <Dialog 
            fullScreen
            scroll='body' 
            open={open} 
            onClose={handleClose}
        >
            <HBox align="center" justify='space-between' className="py-1" style={{ backgroundColor: colors.lightGrey }}>
                <H3 className='ml-2'>Edit Blog Post</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <BlogEditContainer className='p-4'>
                <H3 className='mb-2'>Select Category</H3>
                <Select
                    value = {optionsCategoryValue}
                    options = {categoryOptions}
                    onChange={(val)=>{
                        setOptionsCategoryValue(val);
                        let valueArray = [];
                        val?.map((value) => {
                            valueArray.push(value.label);
                        });
                        setSelectedCategory(valueArray);
                    }}
                    isMulti
                    isClearable
                    isSearchable
                />

                <H3 className='mt-5 mb-2'>Upload New Blog Cover Image</H3>
                <HBox align='center' style={{flexWrap: 'nowrap'}}>
                    <input 
                        type='file' 
                        name='blog_cover_image'
                        accept='image/*'
                        ref={coverImageRef} 
                        onChange={handleCoverImage} 
                    />

                    {
                        coverImageData ?
                            <img className='ml-3' src={coverImageData} style={{height: '50px', width: 'auto'}} />
                            :
                            <>
                                {
                                    coverImage ? 
                                        <img className='ml-3' src={`${baseURL}${coverImage}`} style={{height: '50px', width: 'auto'}} />
                                        :
                                        <H5>No Image</H5>
                                }
                            </>
                    }

                    <Button className='ml-5' size='sm' color='second' onClick={clearCoverImage} outlined>Clear</Button>
                </HBox>

                <H3 className='mt-5 mb-2'>Blog Title</H3>
                <InputText value={title} onChange={(e) => setTitle(e.target.value)} />

                <H3 className='mt-5 mb-2'>Blog Content</H3>
                <ReactQuill theme='snow' 
                            modules={modules} 
                            value={content} 
                            onChange={(value) => setContent(value)}
                />  

                <H3 className='mt-5 mb-2'>Author name</H3>
                <Select 
                    value={{ value: authorId, label: authorName, image: optionsAuthorImage, authorDetails }}
                    options={options}
                    onChange={(val)=>{
                        setAuthorId(val ? val.value : '');
                        setAuthorName(val ? val.label : '');
                        setOptionsAuthorImage(val ? val.image : '');
                        setAuthorImage(val ? val.image : '');
                        setAuthorImageData('');
                        authorImageRef.current.value = ''; 
                        setAuthorDetails(val ? val.details : '');
                    }}
                    onInputChange={(val)=>{
                        if(val) {
                            setAuthorId('');
                            setAuthorName(val);
                        }
                    }}
                    formatOptionLabel={FormatOptionComponent} 
                    isClearable
                    isSearchable
                />

                <H3 className='mt-5 mb-2'>Author details</H3>
                <InputTextarea 
                    value={authorDetails} 
                    onChange={(e) => setAuthorDetails(e.target.value)}
                />

                <H3 className='mt-5 mb-2'>Upload New Author Image</H3>
                <HBox align='center' style={{flexWrap: 'nowrap'}}>
                    <input 
                        type='file' 
                        name='author_image' 
                        accept='image/*'
                        ref={authorImageRef} 
                        onChange={handleAuthorImage} 
                    />

                    {
                        authorImageData ?
                            <img className='ml-3' src={authorImageData} style={{height: '50px', width: 'auto'}} />
                            :
                            <>
                                {
                                    authorImage ? 
                                        <img className='ml-3' src={`${baseURL}${authorImage}`} style={{height: '50px', width: 'auto'}} />
                                        :
                                        <H5>No Image</H5>
                                }
                            </>
                    }

                    <Button className='ml-5' size='sm' color='second' onClick={clearAuthorImage} outlined>Clear</Button>
                </HBox>

                <Button 
                    className='mt-6' 
                    color='first' 
                    onClick={submitData} 
                    style={{width: '100px', flexShrink: '0'}}
                >
                    Submit
                </Button>
            </BlogEditContainer>
        </Dialog>
    )
}

export default BlogEditDlg;