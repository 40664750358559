import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import RSelect  from 'react-select';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import Select from '../../../components/Select';
import colors from "../../../config/colors";
import { discountTypeData, voucherApplicableTo, specialtiesData } from '../../../data';

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const SRSelect = styled(RSelect)`

`

const AddVoucherDlg = ({ open, setOpen, setVouchers }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [code, setCode] = useState('');
    const [type, setType] = useState('');
    const [discount, setDiscount] = useState('');
    const [expireDate, setExpireDate] = useState('');
    const [applicableTo, setApplicableTo] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [doctorId, setDoctorId] = useState('');
    const [doctorOptions, setDoctorOptions] = useState([]);

    const [codeError, setCodeError] = useState("");
    const [typeError, setTypeError] = useState("");
    const [discountError, setDiscountError] = useState("");
    const [specialtyError, setSpecialtyError] = useState("");
    const [doctorError, setDoctorError] = useState("");
    const [expireDateError, setExpireDateError] = useState("");

    useEffect(() => {
        const init = () => {
            setCode('');
            setType('');
            setDiscount('');
            setExpireDate('');
            setApplicableTo('');
            setSpecialty('');
            setDoctorId('');
            
            setCodeError('');
            setTypeError('');
            setDiscountError('');
            setSpecialtyError('');
            setDoctorError('');
            setExpireDateError('');
        }
        if (open) init();
    }, [open]);

    const fetchDoctors = (key) => {
        axios({
            method: "GET",
            url: 'patient/doctor-list/',
            params: {
                key: key,
                offset: 0,
                limit: 10
            }
        }).then(function (res) {
            if (res.status === 200) {
                handleDoctorOptions(res.data.doctors);
            }
            else {
                console.log('DOCTOR FETCH FAILED');
            }
        }).catch((error) => {
            console.log('DOCTOR FETCH ERROR', error);
            
        });

    }

    const handleDoctorOptions = (doctors) => {
        let options = [];
        for(let i=0; i<doctors.length; i++) {
            options.push({value: doctors[i].id, label: `#${doctors[i].id} ${doctors[i].name} ${doctors[i].specialty}`})
        }

        setDoctorOptions(options);
    }

    const handleSelectDoctor = (option) => {
        setDoctorId(option.value);
    }

    const handleSave = () => {
        if (validate()) {
            enqueueSnackbar('Adding the voucher...', { persist: true });
    
            axios({
                method: "POST",
                url: 'nhadmin/voucher-list/',
                data: {
                    code: code,
                    type: type,
                    discount: discount,
                    expire_date: expireDate,
                    applicable_to: applicableTo,
                    specialty: specialty,
                    doctor_id: doctorId,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            }).then(function (res) {
                if (res.status === 201) {
                    setVouchers(vouchers => [...vouchers, res.data]);

                    closeSnackbar();
                    enqueueSnackbar('Successfully added.', {variant: 'success'});
                    setOpen(false);
                }
                else {
                    closeSnackbar();
                    console.log('VOUCHER ADD FAILURE');
                    enqueueSnackbar('Voucher add failure!', {variant: 'error'});
                }
            }).catch((error) => {
                closeSnackbar();
                console.log('VOUCHER ADD ERROR', error);
                
                if (error.response.status === 409) {
                    enqueueSnackbar(error.response.data.message, { variant: 'error' });
                }
                else { 
                    enqueueSnackbar('Voucher add error!', {variant: 'error'});
                }
            });
        }
    }

    const validate = () => {
        var ok = true;

        if(!code) {
            setCodeError('This field cannot be kept blank.');
            ok = false;
        }
        else {
            setCodeError();
        }

        if(!type) {
            setTypeError('Please select a discount type.');
            ok = false;
        }
        else {
            setTypeError('');
        }

        if(!discount) {
            setDiscountError('This field cannot be zero or kept blank.');
            ok = false;
        }
        else {
            setDiscountError('');
        }

        if(applicableTo === 'Specialty') {
            if(!specialty) {
                setSpecialtyError('Please select a specialty.');
                ok = false
            }
            else {
                setSpecialtyError('');
            }
        }
        else if(applicableTo === 'Doctor') {
            if(!doctorId) {
                setDoctorError('Please select a doctor.');
                ok = false
            }
            else {
                setDoctorError('');
            }
        }

        if(!expireDate) {
            setExpireDateError('This field cannot be kept blank.');
            ok = false;
        }
        else {
            setExpireDateError('');
        }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="xs" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Add Voucher</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                <HBox align='center'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='mr-1'>Voucher Code</P2>
                    </HBox>
                    <InputText
                        placeholder='Ex: CODE123'
                        value={code}
                        helpText={codeError}
                        onChange={(e) => setCode(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Discount Type</P2>
                    </HBox>
                    <VBox style={{ width: '60%' }}>
                        <Select
                            onChange={e => setType(e.target.value)}
                        >
                            <option value="" selected={type === ''} style={{ display: 'none' }}>No Type Selected</option>
                            {discountTypeData.map((_type, index) => (
                                <option value={_type} selected={type === _type}>{_type}</option>
                            ))}
                        </Select>
                        {typeError && <P2 color='second'>{typeError}</P2>}
                    </VBox>
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Discount</P2>
                    </HBox>
                    <InputText
                        type='number'
                        placeholder={type==='Percent' ? '%' : type==='Amount' ? 'BDT' : 'Select Discount Type'}
                        value={discount}
                        helpText={discountError}
                        onChange={(e) => setDiscount(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Applicable to</P2>
                    </HBox>
                    <Select style={{ width: '60%' }} onChange={e => setApplicableTo(e.target.value)}>
                        {voucherApplicableTo.map((_applicableTo, index) => (
                            <option value={_applicableTo} selected={applicableTo === _applicableTo}>{_applicableTo}</option>
                        ))}
                    </Select>
                </HBox>
                {applicableTo==='Specialty' && <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Specialty</P2>
                    </HBox>
                    <VBox>
                        <Select style={{ width: '60%' }} onChange={e => setSpecialty(e.target.value)}>
                            <option value="" selected={specialty === ''} style={{ display: 'none' }}>No Specialty Selected</option>
                            {specialtiesData.map((_specialty, index) => (
                                <option value={_specialty} selected={specialty === _specialty}>{_specialty}</option>
                            ))}
                        </Select>
                        {specialtyError && <P2 color='second'>{specialtyError}</P2>}
                    </VBox>
                </HBox>}
                {applicableTo==='Doctor' && <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Doctor</P2>
                    </HBox>
                    <VBox style={{ width: '60%' }}>
                        <HBox>
                            <RSelect
                                placeholder='Search doctor ...'
                                options={doctorOptions}
                                onInputChange={key => fetchDoctors(key)}
                                onChange={handleSelectDoctor}
                                isSearchable
                            />
                        </HBox>
                        {doctorError && <P2 color='second'>{doctorError}</P2>}
                    </VBox>
                </HBox>}
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Expire Date</P2>
                    </HBox>
                    <InputText
                        type='date'
                        value={expireDate}
                        helpText={expireDateError}
                        onChange={(e) => setExpireDate(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleSave}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default AddVoucherDlg;