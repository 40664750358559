import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const AddAffiliationDlg = ({doctorId, open, setOpen, affiliations, setAffiliations}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [institution, setInstitution] = useState("");
    const [designation, setDesignation] = useState("");
    const [department, setDepartment] = useState("");
    const [showInSummary, setShowInSummary] = useState(false);
    const [suggestedInstitutions, setSuggestedInstitutions] = useState([]);

    const [instError, setInstError] = useState("");
    const [desigError, setDesigError] = useState("");
    const [deptError, setDeptError] = useState("");

    useEffect(() => {
        const init = () => {
            setInstitution("");
            setDesignation("");
            setDepartment("");
            setShowInSummary(false);

            setInstError("");
            setDesigError("");
            setDeptError("");
        }
        if (open) init();
    }, [open]);

    const handleChangeInstitution = (text) => {
        setInstitution(text);

        axios({
            method: 'GET',
            url: `nhadmin/suggested-institution-list/`,
            params: {
                key: text
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                setSuggestedInstitutions(response.data);
            } else {
                console.log('SUGGESTED INSTITUTIONS GET FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('SUGGESTED INSTITUTIONS GET ERROR', error);
        })
    }

    const handleSave = () => {
        if (validate()) {
            axios({
                method: "POST",
                url: 'nhadmin/affiliation-list/',
                data: {
                    doctor_id: doctorId,
                    institution: institution,
                    designation: designation,
                    department: department,
                    show_in_summary: showInSummary
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            }).then(function (res) {
                if (res.status === 201) {
                    setAffiliations([res.data, ...affiliations]);

                    enqueueSnackbar('Affiliation add success.', { variant: 'success' });
                    setOpen(false);
                }
                else {
                    console.log('AFFILIATION CREATE FAILED');
                    enqueueSnackbar('Affiliation add failed!', { variant: 'error' });
                }
            }).catch((error) => {
                console.log('AFFILIATION CREATE ERROR', error);
                enqueueSnackbar('Affiliation add error!', { variant: 'error' });
            });
        }
    }

    const validate = () => {
        var ok = true;
        if (institution) {
            setInstError("");
        }
        else {
            setInstError("This field cannot be kept blank.");
            ok = false;
        }
        if (designation) {
            setDesigError("");
        }
        else {
            setDesigError("This field cannot be kept blank.");
            ok = false;
        }
        // if (department) {
        //     setDeptError("");
        // }
        // else {
        //     setDeptError("This field cannot be kept blank.");
        //     ok = false;
        // }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Add Affiliation</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                <HBox className='mb-1'>
                    <InputText
                        style={{ width: '100%' }}
                        className="mr-0_5"
                        list='institutions'
                        label="Institution/Hospital*"
                        type='text'
                        value={institution}
                        helpText={instError}
                        onChange={e => handleChangeInstitution(e.target.value)}
                        autoFocus={true}
                    />
                </HBox>
                <datalist id='institutions'>
                    {suggestedInstitutions.map((inst, index) => {
                        return <option
                                    key={inst.id}
                                    // value={inst.name}
                                >{inst.name}</option>
                    })}
                </datalist>
                <HBox className='mb-3'>
                    <InputText
                        style={{ width: 'calc(50% - 4px)' }}
                        className="mr-0_5"
                        label="Designation*"
                        type='text'
                        value={designation}
                        helpText={desigError}
                        onChange={e => setDesignation(e.target.value)}
                    />
                    <InputText
                        style={{ width: 'calc(50% - 4px)' }}
                        className="ml-0_5"
                        label="Department"
                        type='text'
                        value={department}
                        helpText={deptError}
                        onChange={e => setDepartment(e.target.value)}
                    />
                </HBox>
                {/* <HBox className='mb-3'>
                    <InputText
                        style={{ width: 'calc(50% - 4px)' }}
                        className="mr-0_5"
                        label="Joined On"
                        type='date'
                        value={startDate}
                        helpText={startDError}
                        onChange={e => setStartDate(e.target.value)}
                    />
                    {!currentlyWorking && <InputText
                        style={{ width: 'calc(50% - 4px)' }}
                        className="ml-0_5"
                        label="Left On"
                        type='date'
                        value={endDate}
                        helpText={endDError}
                        onChange={e => setEndDate(e.target.value)}
                    />}
                </HBox> */}
                {/* <HBox className='mb-1' justify='center' align='center'>
                    <P2>Currently working here?</P2>
                    <input
                        style={{ transform: 'scale(1.25)' }}
                        className='mx-1'
                        type='checkbox'
                        defaultChecked={currentlyWorking}
                        onChange={e => setCurrentlyWorking(e.target.checked)}
                    />
                </HBox> */}
                <HBox className='mb-1' justify="center" align='center'>
                    <P2>Highlight this affiliation in doctor's profile?</P2>
                    <input
                        style={{ transform: 'scale(1.25)' }}
                        className='mx-1'
                        type='checkbox'
                        defaultChecked={showInSummary}
                        onChange={e => setShowInSummary(e.target.checked)}
                    />
                </HBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleSave}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default AddAffiliationDlg;