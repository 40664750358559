export const specialtiesData = [
    "General Physician",
    // Medicine
    "Internal Medicine",
    "Cardiology",
    "Respiratory Medicine",
    "Neurology",
    "Nephrology",
    "Gastroenterology",
    "Hepatology",
    "Endocrinology",
    "Pediatrics",
    "Oncology",
    "Rheumatology",
    "Hematology",
    "Dermatology & Venereology",
    "Psychiatry",
    "Physical Medicine & Rehabilitation",
    "Aesthetic Dermatology",
    // Surgery
    "General Surgery",
    // "Cardiovascular Surgery",
    "Orthopedic Surgery",
    "Neurosurgery",
    "Gynecology & Obstetrics",
    "ENT & Head Neck Surgery",
    "Ophthalmology",
    "Oral & Dental Surgery",
    "Urology",
    "Colorectal Surgery",
    "Pediatric Surgery",
    "Vascular Surgery",
    // "Hepatobiliary Surgery",
]

export const genderData = [
    'Female',
    'Male',
    'Other',
]

export const onboardingStatusData = [
    'Draft',
    'On Hold',
    'Onboarded',
]

export const doctorTypeData = [
    'MBBS',
    'BDS',
]

export const appointmentTypeData = [
    'New',
    'Follow up',
]

export const paymentStatusData = [
    'Pending',
    'Paid',
    'Refunded',
]

export const eventLogCategoryData = [
    'login', 
    'registration', 
    'health_profile', 
    'appointment_booking', 
    'consultation', 
    'account_update',
    'chat', 
    'database',
    'promotions',
]

export const logLevelData = [
    'info',
    'warning',
    'error',
]

export const discountTypeData = [
    'Amount',
    'Percent'
]

export const voucherApplicableTo = [
    'All',
    'Specialty',
    'Doctor'
]