import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import RSelect  from 'react-select';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import Select from '../../../components/Select';
import colors from "../../../config/colors";
import { discountTypeData, voucherApplicableTo, specialtiesData } from '../../../data';

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const SRSelect = styled(RSelect)`

`

const AddReferralDlg = ({ open, setOpen, reloadData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [code, setCode] = useState('');
    const [discountType, setDiscountType] = useState('');
    const [bonusType, setBonusType] = useState('');
    const [refBonus, setRefBonus] = useState('');
    const [ptDiscount, setPtDiscount] = useState('');
    const [expireDate, setExpireDate] = useState('');

    useEffect(() => {
        const init = () => {
            setName('');
            setAddress('');
            setPhone('');
            setPaymentMethod('');
            setAccountNumber('');
            setCode('');
            setDiscountType('');
            setBonusType('');
            setRefBonus('');
            setPtDiscount();
            setExpireDate('');
        }
        if (open) init();
    }, [open]);

    const handleSave = () => {
        if (validate()) {
            enqueueSnackbar('Adding the referral...', { persist: true });
    
            axios({
                method: "POST",
                url: 'nhadmin/referral-list/',
                data: {
                    name: name,
                    address: address,
                    phone: phone,
                    payment_method: paymentMethod,
                    account_number: accountNumber,
                    code: code,
                    discount_type: discountType,
                    bonus_type: bonusType,
                    ref_bonus: refBonus,
                    pt_discount: ptDiscount,
                    expire_date: expireDate,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            }).then(function (res) {
                if (res.status === 201) {
                    reloadData();

                    closeSnackbar();
                    enqueueSnackbar('Successfully added.', {variant: 'success'});
                    setOpen(false);
                }
                else {
                    closeSnackbar();
                    console.log('REFERRAL ADD FAILURE');
                    enqueueSnackbar('Referral add failure!', {variant: 'error'});
                }
            }).catch((error) => {
                closeSnackbar();
                console.log('REFERRAL ADD ERROR', error);
                
                if (error.response.status === 409) {
                    enqueueSnackbar(error.response.data.message, { variant: 'error' });
                }
                else { 
                    enqueueSnackbar('Referral add error!', {variant: 'error'});
                }
            });
        }
    }

    const validate = () => {
        if(!name) {
            enqueueSnackbar('Name field cannot be kept blank.', { variant: 'error' });
            return false;
        }

        if(!address) {
            enqueueSnackbar('Address field cannot be kept blank.', { variant: 'error' });
            return false;
        }

        if(!phone) {
            enqueueSnackbar('Phone field cannot be kept blank.', { variant: 'error' });
            return false;
        }

        if(!paymentMethod) {
            enqueueSnackbar('Payment method field cannot be kept blank.', { variant: 'error' });
            return false;
        }

        if(!accountNumber) {
            enqueueSnackbar('Account number field cannot be kept blank.', { variant: 'error' });
            return false;
        }

        if(!code) {
            enqueueSnackbar('Code field cannot be kept blank.', { variant: 'error' });
            return false;
        }

        if(!discountType) {
            enqueueSnackbar('Please select a discount type.', { variant: 'error' });
            return false;
        }

        if(!bonusType) {
            enqueueSnackbar('Please select a bonus type.', { variant: 'error' });
            return false;
        }

        if(!refBonus) {
            enqueueSnackbar('Ref. bonus field cannot be kept blank.', { variant: 'error' });
            return false;
        }

        if(!expireDate) {
            enqueueSnackbar('Expire date field cannot be kept blank.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="xs" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Add Voucher</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                <HBox align='center' className=''>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='mr-1'>Name</P2>
                    </HBox>
                    <InputText
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='mr-1'>Address</P2>
                    </HBox>
                    <InputText
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='mr-1'>Phone</P2>
                    </HBox>
                    <InputText
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='mr-1'>Payment Method</P2>
                    </HBox>
                    <InputText
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='mr-1'>Account Number</P2>
                    </HBox>
                    <InputText
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='mr-1'>Referral Code</P2>
                    </HBox>
                    <InputText
                        placeholder='Ex: CODE123'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Discount Type</P2>
                    </HBox>
                    <VBox style={{ width: '60%' }}>
                        <Select
                            onChange={e => setDiscountType(e.target.value)}
                        >
                            <option value="" selected={discountType === ''} style={{ display: 'none' }}>No Type Selected</option>
                            {discountTypeData.map((_type, index) => (
                                <option value={_type} selected={discountType === _type}>{_type}</option>
                            ))}
                        </Select>
                    </VBox>
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Patient Discount</P2>
                    </HBox>
                    <InputText
                        type='number'
                        placeholder={discountType==='Percent' ? '%' : discountType==='Amount' ? 'BDT' : 'Select Discount Type'}
                        value={ptDiscount}
                        onChange={(e) => setPtDiscount(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Bonus Type</P2>
                    </HBox>
                    <VBox style={{ width: '60%' }}>
                        <Select
                            onChange={e => setBonusType(e.target.value)}
                        >
                            <option value="" selected={bonusType === ''} style={{ display: 'none' }}>No Type Selected</option>
                            {discountTypeData.map((_type, index) => (
                                <option value={_type} selected={bonusType === _type}>{_type}</option>
                            ))}
                        </Select>
                    </VBox>
                </HBox>
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Ref. Bonus</P2>
                    </HBox>
                    <InputText
                        type='number'
                        placeholder={bonusType==='Percent' ? '%' : bonusType==='Amount' ? 'BDT' : 'Select Discount Type'}
                        value={refBonus}
                        onChange={(e) => setRefBonus(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
                
                <HBox align='center' className='mt-0_5'>
                    <HBox style={{ width: '40%' }}>
                        <P2 className='pr-1'>Expire Date</P2>
                    </HBox>
                    <InputText
                        type='date'
                        value={expireDate}
                        onChange={(e) => setExpireDate(e.target.value)}
                        style={{ width: '60%' }}
                    />
                </HBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleSave}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default AddReferralDlg;