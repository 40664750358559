import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import axios from "axios";
import { Dialog } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { FiSearch, FiX } from "react-icons/fi";

import { HBox, VBox } from "../../../components/Containers";
import { Button, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import colors from "../../../config/colors";
import AppointmentBookDlg from "./AppointmentBookDlg";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Container = styled.div`
    overflow-y: hidden;
`

const SearchIcon = styled(FiSearch)`
    color: ${colors.darkBlue};
`

const Input = styled.input`
    width: 90%;
    height: 2.5em;
    border: 0px;
    color: ${colors.darkGrey};
    outline: none;
    font-size: 1rem;
    padding: 16px;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const SearchImage = styled.img`
    width: 10%;
    height: auto;
`

const PtImage = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1px solid ${colors.grey};
`

const PtCardContainer = styled.div`
    width: '100%';
    /* border: 1px solid ${colors.grey}; */
    border-radius: 5px;
    /* cursor: pointer; */

    :hover {
        /* background-color: ${colors.lightGrey}; */
        box-shadow: 0px 1px 5px 0px ${colors.shadow};
        transition: box-shadow 100ms;
    }
`

const OnlineIcon = styled.div`
    background-color: ${colors.green};
    height: 8px;
    width: 8px;
    border-radius: 4px;
`

const PatientCard = ({ id, image, name, phone, email, createdAt, isOnline, doctorId, slotId,
                       setOpenAppSlotManage, setOpenPtSearch }) => {
    const [openAppBook, setOpenAppBook] = useState(false);

    return (
        <PtCardContainer className="p-2 mb-1">
            <HBox justify='space-between'>
                <HBox>
                    <VBox align='center' className="">
                        <PtImage
                            src={`${baseURL}${image}`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src="/images/noImage.svg";
                            }}
                        />
                        <P3 className="mt-0_5">ID: {id}</P3>
                    </VBox>
                    <VBox className="ml-2" justify='center'>
                        <HBox align='center' className="">
                            <H5>{name}</H5>
                            {isOnline ? <OnlineIcon className='ml-0_5' /> : null}
                        </HBox>
                        {phone && <P2>Phone: {phone}</P2>}
                        {email && <P2>Email: {email}</P2>}
                    </VBox>
                </HBox>
                <VBox justify='center'>
                    <Button size='sm' color='first' onClick={() => setOpenAppBook(true)}>
                        Confirm Booking
                    </Button>
                </VBox>
            </HBox>
            
            <AppointmentBookDlg
                open={openAppBook}
                setOpen={setOpenAppBook}
                patientId={id}
                doctorId={doctorId}
                slotId={slotId}
                setOpenAppSlotManage={setOpenAppSlotManage}
                setOpenPtSearch={setOpenPtSearch}
            />
        </PtCardContainer>
    )
}

const SearchPatientDlg = ({ open, setOpen, doctorId, slotId, setOpenAppSlotManage }) => {
    const pageSize = 10;
    const [patients, setPatients] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [inputTimeout, setInputTimeout] = useState(null);
    
    useEffect(() => {
        const init = () => {
            setSearchKey("");
            setOffset(0);
            setLimit(pageSize);
            setHasMore(true);
            setPatients([]);
        }
        if (open) init();
    }, [open]);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'nhadmin/patient-list/',
            params: {
                key: searchKey,
                offset: offset,
                limit: limit
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setPatients(patients.concat(response.data.patients));
                setHasMore(response.data.has_more);
                setCount(response.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            } else {
                console.log('PATIENT LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.log('PATIENT LIST FETCH ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleSearch = (value) => {
        setSearchKey(value);
        setCount(null);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setOffset(0);
                    setLimit(pageSize);
                    setHasMore(true);
                    setPatients([]);
            }, 300)
        )
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="md"
            open={open}
            onClose={handleClose}
            fullWidth
            scroll="body"
        >
            <Container>
                <HBox align="center" justify='space-between' className="mt-1 mb-1">
                    <HBox align="center" style={{ width: '85%' }}>
                        <SearchIcon fontSize={24} className="ml-2" />
                        <Input
                            style={{ fontSize: '1rem', width: '80%' }}
                            placeholder="Search patients..."
                            type="text"
                            autoFocus
                            value={searchKey}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </HBox>
                    <IconButton
                        className="mr-2"
                        onClick={handleClose}
                    >
                        <FiX />
                    </IconButton>
                </HBox>
                <Line />
                {searchKey&&count!==null ?
                    <HBox className="px-2 py-1" style={{ backgroundColor: colors.lightGrey }}>
                        <P2>We have found</P2>
                        <P2 color='third' className="bold" style={{ marginLeft: 4, marginRight: 4 }}>{count}</P2>
                        <P2>{count === 1 ? 'patient' : 'patients'}.</P2>
                    </HBox>
                    :
                    <></>
                }
                {searchKey&&count!==null ? <Line /> : <></>}
                <Scrollbars
                    style={{ height: '70vh' }}
                    onUpdate={handleScrollUpdate}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                }>
                    {patients.length ?
                        <VBox className="m-2">
                            {patients.map((patient, index) => (
                                <PatientCard
                                    index={index}
                                    id={patient.id}
                                    name={patient.full_name}
                                    image={patient.image}
                                    phone={patient.phone}
                                    email={patient.email}
                                    createdAt={patient.created_at}
                                    isOnline={patient.is_online}
                                    doctorId={doctorId}
                                    slotId={slotId}
                                    setOpenAppSlotManage={setOpenAppSlotManage}
                                    setOpenPtSearch={setOpen}
                                />
                            ))}
                        </VBox>
                        :
                        <VBox justify="center" align="center" style={{ height: '80%' }}>
                            <SearchImage src="/images/search.png" />
                        </VBox>
                    }
                </Scrollbars>
            </Container>
        </Dialog>
    )
}

export default SearchPatientDlg;