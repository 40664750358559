import { useState, useEffect } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import moment from "moment";

import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import { HBox, VBox } from "../components/Containers";
import { Button, IconButton } from "../components/Buttons";
import colors from "../config/colors";
import responsive from "../config/responsive";
// import { getDate } from "../utils";
import logoMain from "../assets/images/logo_main.png";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

// Colors
const blue = '#119AC0';
const red = '#C75738';
const green = '#209F5C';
const lightGrey = '#F2F2F2';
const darkGrey = '#5C5C5C';

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 16
    },
    heading: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '0.25px solid black',
        padding: 8,
    },
    subHeading: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '0.25px solid black',
        padding: 8,
    },
    left: {
        width: '65%',
        padding: 16,
    },
    right: {
        width: '35%',
        padding: 16,
        borderLeft: '0.25px solid black',
    },
    logo: {
        // backgroundColor: lightGrey,
        // padding: 4,
        alignItems: 'center',
        // borderRadius: 8,
        // boxShadow: '0px 1px 0px 0px rgba(0,0,0,0.25)',
    },
    hbox: {
        flexDirection: "row",
    },
    image: {
        height: 48
    },
    sign: {
        height: 20,
        width: 75
    },
    rx: {
        height: 16,
        width: 14,
    }
});

const Heading = ({ docName, bmdc, qualification, specialty, affiliationSummary }) => {
    return (
        <View style={styles.heading}>
            <View>
                <Text style={{ fontSize: 20, fontFamily: 'Helvetica', color: blue, marginBottom: 2 }}>{docName}</Text>
                <Text style={{ fontSize: 11, fontFamily: 'Helvetica', marginBottom: 1, maxWidth: '50%' }}>{qualification}</Text>
                <Text style={{ fontSize: 11, fontFamily: 'Helvetica', color: red, marginBottom: 1 }}>{specialty}</Text>
                {affiliationSummary?.length ?
                    <>
                        <Text style={{ fontSize: 11, fontFamily: 'Helvetica', color: green, marginBottom: 1 }}>{affiliationSummary[0].designation}, {affiliationSummary[0].department}</Text>
                        <Text style={{ fontSize: 11, fontFamily: 'Helvetica', marginBottom: 1 }}>{affiliationSummary[0].institution}</Text>
                    </>
                    :
                    <></>
                }
                <Text style={{ fontSize: 11, fontFamily: 'Helvetica', marginBottom: 1 }}>BMDC #{bmdc}</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
                <View style={styles.logo}>
                    <Image
                        src={logoMain}
                        style={styles.image}
                    />
                    <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Oblique' }}>www.nextgenmyhealth.com</Text>
                </View>
                
            </View>
        </View>
    )
}

const Rx = ({ serial, name, instruction1, instruction2 }) => {
    return (
        <View style={{ flexDirection: 'row', marginTop: 4 }}>
            <Text style={{ fontSize: 11, fontFamily: 'Helvetica', marginLeft: 8 }}>{serial})</Text>
            <View style={{ marginLeft: 4 }}>
                <Text style={{ fontSize: 11, fontFamily: 'Helvetica' }}>{name}</Text>
                <View style={styles.hbox}>
                    <Text style={{ fontSize: 10, fontFamily: 'Helvetica' }}>{instruction1}</Text>
                    <Text style={{ fontSize: 10, fontFamily: 'Helvetica', marginHorizontal: 8 }}>-</Text>
                    <Text style={{ fontSize: 10, fontFamily: 'Helvetica' }}>{instruction2}</Text>
                </View>
            </View>
        </View>
    )
}

const ListItem = ({ serial, text }) => {
    return (
        <View style={{ flexDirection: 'row', marginTop: 4 }}>
            {serial ? <Text style={{ fontSize: 11, fontFamily: 'Helvetica', marginLeft: 8 }}>{serial})</Text> : null }
            <Text style={{ fontSize: 11, fontFamily: 'Helvetica', marginLeft: serial ? 4 : 8 }}>{text}</Text>
        </View>
    )
}

const Left = ({ rxs, advices, followUp, sign }) => {
    return (
        <View style={styles.left}>
            <View style={{ marginBottom: 24 }}>
                {/* <Text style={{ fontSize: 12, fontFamily: 'Helvetica' }}>Rx</Text> */}
                {rxs[0].drug_name && <>
                    <Image
                        src='images/rx.png'
                        style={styles.rx}
                    />
                    {rxs.map((rx, index) => (
                        <Rx
                            serial={index + 1}
                            name={rx.drug_name}
                            instruction1={rx.instruction1}
                            instruction2={rx.instruction2}
                        />
                    ))}
                </>}
            </View>
            <View style={{ marginBottom: 24 }}>
                {advices[0].text && <>
                    <Text style={{ fontSize: 12, fontFamily: 'Helvetica' }}>Advice</Text>
                    {advices.map((advice, index) => (
                        <ListItem serial={index + 1} text={advice.text} />
                    ))}
                </>}
            </View>
            {followUp && <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Oblique' }}>[Follow up: {followUp}]</Text>}
            {sign && 
                <>
                <Text style={{ marginTop: 24, marginBottom: 3, fontSize: 11, fontFamily: 'Helvetica' }}>Signature</Text>
                <Image
                    src={`${baseURL}${sign}`}
                    style={styles.sign}
                />
            </>}
        </View>
    )
}

const Right = ({ chiefComplaints, examinations, diagnoses, investigations }) => {
    return (
        <View style={styles.right}>
            <View style={{ marginBottom: 24 }}>
                {chiefComplaints[0].text && <>
                    <Text style={{ fontSize: 12, fontFamily: 'Helvetica' }}>Chief Complain</Text>
                    {chiefComplaints.map((chiefComplaint, index) => (
                        <ListItem text={chiefComplaint.text} />
                    ))}
                </>}
            </View>
            <View style={{ marginBottom: 24 }}>
                {examinations[0].text && <>
                    <Text style={{ fontSize: 12, fontFamily: 'Helvetica' }}>Examination</Text>
                    {examinations.map((examination, index) => (
                        <ListItem text={examination.text} />
                    ))}
                </>}
            </View>
            <View style={{ marginBottom: 24 }}>
                {diagnoses[0].text && <>
                    <Text style={{ fontSize: 12, fontFamily: 'Helvetica' }}>Diagnosis</Text>
                    {diagnoses.map((disgnosis, index) => (
                        <ListItem text={disgnosis.text} />
                    ))}
                </>}
            </View>
            <View>
                {investigations[0].text && <>
                    <Text style={{ fontSize: 12, fontFamily: 'Helvetica' }}>Investigation</Text>
                    {investigations.map((investigation, index) => (
                        <ListItem serial={index + 1} text={investigation.text} />
                    ))}
                </>}
            </View>
        </View>
    )
}

const PatientInfo = ({ name, gender, age, date }) => {
    return (
        <View style={styles.subHeading}>
            <View style={styles.hbox}>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold' }}>Name of Patient:</Text>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica', marginLeft: 4 }}>{name}</Text>
            </View>
            <View style={styles.hbox}>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold' }}>Gender:</Text>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica', marginLeft: 4 }}>{gender}</Text>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold', marginLeft: 8 }}>Age:</Text>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica', marginLeft: 4 }}>{age}</Text>
            </View>
            <View style={styles.hbox}>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold' }}>Date:</Text>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica', marginLeft: 4 }}>{moment(date).format('D MMMM YYYY')}</Text>
            </View>
        </View>
    )
}

const PrescriptionDocument = ({ date, doctorDetails, appointDetails, rxs, advices, followUp,
                                chiefComplaints, examinations, diagnoses, investigations }) => {
    
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Heading
                    docName={doctorDetails.name}
                    bmdc={doctorDetails.doctor_type === 'MBBS' ? 'A-'+doctorDetails.bmdc : doctorDetails.bmdc}
                    qualification={doctorDetails.qualification}
                    specialty={doctorDetails.specialty}
                    affiliationSummary={doctorDetails.affiliation_summary}
                />
                <PatientInfo
                    name={appointDetails.patient_name}
                    gender={appointDetails.patient_gender}
                    age={appointDetails.patient_age}
                    date={date}
                />
                <View style={styles.hbox}>
                    <Left
                        rxs={rxs}
                        advices={advices}
                        followUp={followUp}
                        sign={doctorDetails.sign}
                    />
                    <Right
                        chiefComplaints={chiefComplaints}
                        examinations={examinations}
                        diagnoses={diagnoses}
                        investigations={investigations}
                    />
                </View>
            </Page>
        </Document>
    )
}

const PrescriptionPDF = ({ open, setOpen, prescriptionId }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [appointDetails, setAppointDetails] = useState({
        pid: "", patient_name: "", patient_gender: "", patient_age: "", type: "", created_at: "", edited_at: ""
    });
    const [doctorDetails, setDoctorDetails] = useState({
        name: "", doctor_type: "", bmdc: "", qualification: "", specialty: "", affiliation_summary: []
    });
    const [date, setDate] = useState(null);

    const [chiefComplaints, setChiefComplaints] = useState([
        {  text: "" },
    ]);
    const [examinations, setExaminations] = useState([
        { text: "" },
    ]);
    const [diagnoses, setDiagnoses] = useState([
        { text: "" },
    ]);
    const [investigations, setInvestigations] = useState([
        { text: "" },
    ]);
    const [rxs, setRxs] = useState([
        { drug_name: "", instruction1: "", instruction2: "" },
    ]);
    const [advices, setAdvices] = useState([
        { text: "" },
    ]);
    const [followUp, setFollowUp] = useState("");

    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (open) getData();
    }, [open]);

    const getData = async () => {
        setIsLoading(true);
        enqueueSnackbar("Loading...", { persist: true });
        await axios({
            method: 'GET',
            url: `doctor/prescription-details/`,
            params: {
                prescription_id: prescriptionId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setDate(response.data.prescription.edited_at);
                setAppointDetails(response.data.appointment);
                setDoctorDetails(response.data.doctor);
                setChiefComplaints(response.data.prescription.chief_complaints.length ? response.data.prescription.chief_complaints : [{ text: "" }]);
                setExaminations(response.data.prescription.examinations.length ? response.data.prescription.examinations : [{ text: "" }]);
                setDiagnoses(response.data.prescription.diagnoses.length ? response.data.prescription.diagnoses : [{ text: "" }]);
                setInvestigations(response.data.prescription.investigations.length ? response.data.prescription.investigations : [{ text: "" }]);
                setRxs(response.data.prescription.rxs.length ? response.data.prescription.rxs : [{ drug_name: "", instruction1: "", instruction2: "" }]);
                setAdvices(response.data.prescription.advices.length ? response.data.prescription.advices : [{ text: "" }]);
                setFollowUp(response.data.prescription.follow_up);
            } else {
                console.log('PRESCRIPTION GET FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('PRESCRIPTION GET ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return;
        
        setOpen(false);
    }

    return (
        <Dialog fullScreen scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Prescription</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox>
                {isMobile ?
                    <HBox style={{ alignSelf: 'center', justifySelf: 'center', height: '90vh' }}>
                        <PDFDownloadLink
                            document={
                                <PrescriptionDocument
                                    date={date}
                                    doctorDetails={doctorDetails}
                                    appointDetails={appointDetails}
                                    rxs={rxs}
                                    advices={advices}
                                    followUp={followUp}
                                    chiefComplaints={chiefComplaints}
                                    examinations={examinations}
                                    diagnoses={diagnoses}
                                    investigations={investigations}
                                />}
                            fileName="prescription.pdf"
                            style={{ alignSelf: 'center', justifySelf: 'center', textDecoration: 'none' }}
                        >
                            {({ loading }) =>
                                loading ? "Loading Prescription..."
                                    :
                                    <Button color='first'>Download Prescription</Button>
                            }
                        </PDFDownloadLink>
                    </HBox>
                    :
                    <PDFViewer style={{ height: 'calc(100vh - 47px)' }}>
                        <PrescriptionDocument
                            date={date}
                            doctorDetails={doctorDetails}
                            appointDetails={appointDetails}
                            rxs={rxs}
                            advices={advices}
                            followUp={followUp}
                            chiefComplaints={chiefComplaints}
                            examinations={examinations}
                            diagnoses={diagnoses}
                            investigations={investigations}
                        />
                    </PDFViewer>
                }
            </VBox>
        </Dialog>
    )
}

export default PrescriptionPDF;