import { useState, useEffect } from "react";
import styled from "styled-components"
import { Dialog } from "@mui/material"
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from "notistack";

import { HBox, VBox } from "../../../components/Containers";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import { H3, P2 } from "../../../components/Typography";
import CheckChoice from "../../../components/CheckChoice";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const AppointmentBookDlg = ({ open, setOpen, patientId, doctorId, slotId,
                              setOpenAppSlotManage, setOpenPtSearch }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [payAmount, setPayAmount] = useState('');
    const [paidThrough, setPaidThrough] = useState('');
    const [trxId, setTrxId] = useState('');
    const [ptTypes, setPtTypes] = useState([
        {text: "New", checked: false},
        {text: "Follow up", checked: false},
    ]);

    const [payAmountError, setPayAmountError] = useState('');
    const [paidThroughError, setPaidThroughError] = useState('');
    const [trxIdError, setTrxIdError] = useState('');

    useEffect(() => {
        if(open) {
            fetchAppointmentType();
        }
    }, [open]);

    const fetchAppointmentType = () => {
        enqueueSnackbar('Fetching appointment type...', { persist: true });
        axios({
            method: "GET",
            url: `nhadmin/appointment-type/`,
            params: {
                doctor_id: doctorId,
                patient_id: patientId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
            },
        }).then(function (res) {
            closeSnackbar();
            if (res.status === 200) {
                setAppointmentType(res.data);
            }
            else {
                console.log('APPOINTMENT TYPE FETCH FAILED', res.status);
                enqueueSnackbar('Appointment type fetch failure!', {variant: 'error'});
            }
        }).catch((error) => {
            closeSnackbar();
            console.log('APPOINTMENT TYPE FETCH ERROR', error);
            enqueueSnackbar('Appointment type fetch error!', {variant: 'error'});
        });
    }

    const setAppointmentType = (type) => {
        for (let i=0; ptTypes.length; i++) {
            if (ptTypes[i].text === type) {
                let values = [...ptTypes];
                values[i].checked = true;
                setPtTypes(values);
                break;
            }
        }
    }

    const getAppointmentType = () => {
        if (ptTypes[0].checked) return 'New';
        else return 'Follow up';
    }

    const bookAppointment = () => {
        if (validate()) {
            enqueueSnackbar('Booking appointment... Please wait a moment.', { persist: true });
            axios({
                method: "POST",
                url: `nhadmin/appointment-list/`,
                data: {
                    doctor_id: doctorId,
                    patient_id: patientId,
                    consult_slot_id: slotId,
                    appointment_type: getAppointmentType(),
                    pay_amount: payAmount,
                    paid_through: paidThrough,
                    transaction_id: trxId,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            }).then(function (res) {
                closeSnackbar();
                if (res.status === 201) {
                    setPaidThrough('');
                    setPayAmount('');
                    setTrxId('');

                    setOpen(false);
                    setOpenPtSearch(false);
                    setOpenAppSlotManage(false);
                    enqueueSnackbar('Appointment booking success.', { variant: 'success' });
                }
                else {
                    console.log('APPOINTMENT BOOKING FAILED', res.status);
                    enqueueSnackbar('Appointment booking failure!', {variant: 'error'});
                }
            }).catch((error) => {
                closeSnackbar();
                console.log('APPOINTMENT BOOKING ERROR', error);
                if (error.message === 'Network Error') {
                    enqueueSnackbar('Network error.', { variant: 'error' });
                }
                else if (error.response.status === 409) {
                    enqueueSnackbar(error.response.data.message, { variant: 'error' });
                }
                else {
                    enqueueSnackbar('Appointment booking error!', { variant: 'error' });
                }
            });
        }
    }

    const validate = () => {
        var ok = true;

        if (payAmount) {
            setPayAmountError("");
        }
        else {
            setPayAmountError('This field must not be kept blank.');
            ok = false;
        }

        if (paidThrough) {
            setPaidThroughError("");
        }
        else {
            setPaidThroughError('This field must not be kept blank.');
            ok = false;
        }

        if (trxId) {
            setTrxIdError("");
        }
        else {
            setTrxIdError('This field must not be kept blank.');
            ok = false;
        }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog
            maxWidth="sm"
            scroll='body'
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Payment Info</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className="p-3">
                <InputText
                    className=""
                    type="text"
                    label="Paid Through"
                    placeholder="Example: bKash"
                    value={paidThrough}
                    onChange={e => setPaidThrough(e.target.value)}
                    helpText={paidThroughError}
                />
                <InputText
                    className="mt-1"
                    type="number"
                    label="Pay Amount"
                    value={payAmount}
                    onChange={e => setPayAmount(e.target.value)}
                    helpText={payAmountError}
                />
                <InputText
                    className="mt-1"
                    type="text"
                    label="Transaction ID"
                    value={trxId}
                    onChange={e => setTrxId(e.target.value)}
                    helpText={trxIdError}
                />
                <VBox className="mt-1">
                    <P2>Consultation Type</P2>
                    <CheckChoice
                        items={ptTypes}
                        setItems={setPtTypes}
                        direction="row"
                        radio
                    />
                </VBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={bookAppointment}
                >
                    Finalize Booking
                </Button>
            </HBox>
        </Dialog>
    )
}

export default AppointmentBookDlg;