import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { LowerNav } from "../../layouts/Navbar"

const Dashboard = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        // const getData = async () => {
        //     await axios({
        //         method: 'GET',
        //         url: 'doctor/dashboard/',
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
        //         }
        //     })
        //     .then((response) => {
        //         if (response.status === 200) {
        //             console.log('DASHBOARD DATA FETCH SUCCESS');

        //             setDoctorName(response.data.name);
        //             setAppointments(response.data.appointments);
        //         } else {
        //             console.log('DASHBOARD DATA FETCH FAILED', response.status);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log('DASHBOARD DATA FETCH ERROR', error);
        //     })
        // }
        // getData();
        closeSnackbar();
    }, [])

    return (
        <>
            <LowerNav selected='dashboard' />
        </>
    )
}

export default Dashboard;