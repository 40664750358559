import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import styled from "styled-components";

import { HBox, VBox } from "../../components/Containers";
import { Button, NavBtn, NavBtnActive } from "../../components/Buttons";
import { LowerNav } from "../../layouts/Navbar";
import colors from "../../config/colors";
import Footer from "../../layouts/Footer";
import CategoryTable from './components/CategoryTable';

const Container = styled(VBox)`
    width: 100%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
`

const LeftPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-right: 1px solid ${colors.grey};
`

const RightPanel = styled(VBox)`
    width: 80%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Doctors = () => {
    return (
        <>
            <LowerNav selected='event-logs' />
            <HBox>
                <LeftPanel>
                    <SLink to='/blogs'>
                        <NavBtn size="sm" color="first" className="mt-8" outlined>
                            <div className="ml-1">Blogs</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">Blog Categories</div>
                    </NavBtnActive>
                </LeftPanel>
                <RightPanel>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 50px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='pb-4 pl-4 pr-4'>
                            <CategoryTable />
                        </VBox>
                    </Scrollbars>
                </RightPanel>
            </HBox>
            <Footer />
        </>
    )
}

export default Doctors;