import { useState } from "react";
import styled from "styled-components";
import { FiEye, FiTrash } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Tooltip, IconButton } from "@mui/material";

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import Confirm from "../../../layouts/Confirm";
import colors from "../../../config/colors";
import { getDate } from "../../../utils";

const CardContainer = styled(HBox)`
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const ViewIcon = styled(FiEye)`
    font-size: 16px;
    color: ${colors.green};
`

const DeleteIcon = styled(FiTrash)`
    font-size: 16px;
    color: ${colors.red};
`

const DiscountChip = styled(HBox)`
    background-color: ${colors.green};
    border-radius: 5px;
`

const ApplicableToChip = styled(HBox)`
    background-color: ${colors.blue};
    border-radius: 5px;
`

const VoucherCard = ({ index, id, code, type, discount, applicableTo, specialty, doctorName,
                       expireDate, vouchers, setVouchers }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const handleConfirmDelete = () => {
        axios({
            method: 'DELETE',
            url: `nhadmin/voucher-details/`,
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                let values = [...vouchers];
                values.splice(index, 1);
                setVouchers(values);

                enqueueSnackbar('Voucher deleted.', { variant: 'success' });
            } else {
                console.log('VOUCHER DELETE FAILED', response.status);
                enqueueSnackbar('Voucher delete failed!', { variant: 'error' });
            }
        })
        .catch((error) => {
            enqueueSnackbar('Voucher delete error!', { variant: 'error' });
            console.log('VOUCHER DELETE ERROR', error);
        })
    }

    return (
        <>
        <CardContainer className="p-2 mb-1" justify='space-between' align='center'>
            <HBox align='center'>
                <H5>{code}</H5>
                <P3 className='ml-1'>Expire on {getDate(expireDate, 'long')}</P3>
                <DiscountChip className='p-0_5 ml-1'>
                    <P3 color='white'>{type==='Amount' ? `BDT ${discount}` : `${discount}%`}</P3>
                </DiscountChip>
                <ApplicableToChip className='p-0_5 ml-1'>
                    {(() => {
                        if(applicableTo === 'Specialty')
                            return <P3 color='white'>{specialty}</P3>
                        else if(applicableTo === 'Doctor')
                            return <P3 color='white'>{doctorName}</P3>
                        else
                            return <P3 color='white'>All</P3>
                    })()}
                </ApplicableToChip>
            </HBox>
            <HBox> 
                <Tooltip title='Not implemented yet' arrow>
                    <IconButton>
                        <ViewIcon className="clickable" />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete' arrow>
                    <IconButton>
                        <DeleteIcon
                            className="clickable"
                            onClick={() => setOpenConfirmDelete(true)}
                        />
                    </IconButton>
                </Tooltip>
            </HBox>
        </CardContainer>

        <Confirm
            message="Are you sure to delete this voucher?"
            btnColor="second"
            onConfirm={handleConfirmDelete}
            open={openConfirmDelete}
            setOpen={setOpenConfirmDelete}
        />
        </>
    )
}

export default VoucherCard;