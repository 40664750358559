import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";
import { getDate, getTimeFromDateTime } from '../../../utils';

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const AppointmentLogDlg = ({ appointmentId, open, setOpen }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [appointmentLogs, setAppointmentLogs] = useState([]);

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open]);

    const getData = () => {
        enqueueSnackbar("Loading appointment log...", { persist: true });
        axios({
            method: 'GET',
            url: 'nhadmin/appointment-log-list/',
            params: {
                appointment_id: appointmentId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setAppointmentLogs(response.data);
            } else {
                console.log('APPOINTMENT LOG FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('APPOINTMENT LOG FETCH ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <>
        <Dialog maxWidth='sm' scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Appointment Log</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-4'>
                <table>
                    {appointmentLogs.map((log, index) => (
                        <tr>
                            <td><P2>{log.message}</P2></td>
                            <td><P3 className=''>{getDate(log.created_at, 'short')} at {getTimeFromDateTime(log.created_at)}</P3></td>
                        </tr>
                    ))}
                </table>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
            </HBox>
        </Dialog>
        </>
    )
}

export default AppointmentLogDlg;