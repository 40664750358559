import styled from "styled-components";

import colors from "../config/colors";

const severityProps = {
    success: colors.green,
    info: colors.blue,
    error: colors.red,
};

const Alert = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${props => severityProps[props.severity || 'error']};;
    color: ${props => severityProps[props.severity || 'error']};
    background-color: ${colors.lightGrey};
    border-radius: 5px;
    font-size: 0.75rem;
    padding: 16px;
`

export default Alert;
