import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter,
         TablePagination, TableRow, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FiFilter, FiEdit, FiEye } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import styled from "styled-components";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { FiPlusSquare } from 'react-icons/fi';
import axios from "axios";

import { VBox, HBox } from '../../../components/Containers';
import { H3, P3 } from "../../../components/Typography";
import { InputText } from '../../../components/InputText';
import colors from "../../../config/colors";
import Confirm from '../../../layouts/Confirm';
import BlogAddDlg from './BlogAddDlg';
import BlogFilterDlg from './BlogFilterDlg';
import BlogViewDlg from './BlogViewDlg';
import BlogEditDlg from './BlogEditDlg';

const LoadingTxt = styled(P3)`
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    padding: 4px;
`

const AddIcon = styled(FiPlusSquare)`
    font-size: 24px;
    color: ${colors.blue};
`

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <HBox style={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </HBox>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const BlogTable = ({ modules }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchKey, setSearchKey] = useState();
    const [inputTimeout, setInputTimeout] = useState(null);
    const [count, setCount] = useState();
    const [open, setOpen] = useState(false);
    const [ordering, setOrdering] = useState({orderBy: 'created_at', direction: '-'});
    const [filtering, setFiltering] = useState({addedBy: '', category: ''});
    const [isLoading, setIsLoading] = useState(false);
    const [blogId, setBlogId] = useState(null);

    const [openBlogAdd, setOpenBlogAdd] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [page, rowsPerPage]);

    useEffect(() => {
        handleSearch();
    }, [searchKey])

    useEffect(() => {
        reloadData(); 
    }, [ordering, filtering]);

    useEffect(() => {
        if(openBlogAdd === false || openEdit === false || openConfirm === false) {
            getData();
        }
    }, [openBlogAdd, openEdit, openConfirm])

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);        
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'nhadmin/blog-list/',
            params: {
                key: searchKey,
                order_by: ordering.orderBy,
                direction: ordering.direction,
                added_by: filtering.addedBy,
                category: filtering.category,
                offset: page*rowsPerPage,
                limit: page*rowsPerPage+rowsPerPage
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if(response.status===200) {
                setBlogs(response.data.blogs);
                setCount(response.data.count);
            } else {
                console.log('BLOG LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('BLOG LIST FETCH ERROR', error);
        })
    }

    const onConfirm = () => {
        axios({
            method: 'DELETE',
            url: 'nhadmin/blog-details/',
            params: {
                id: blogId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if(response.status===200) {
                enqueueSnackbar('Blog successfully deleted', {variant: 'success', persist: 'true'});
                setTimeout(()=>closeSnackbar(), 4000);
            }
        })
        .catch((error) => {
            console.log('BLOG DELETE FAILED');
        })
    }

    const reloadData = () => {
        setPage(0);
        getData();
    }

    const handleSearch = () => {
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setPage(0);
                    getData();
            }, 300)
        )
    }

    const clearFilter = () => {
        setFiltering({addedBy: '', category: ''});
    }

    return (
        <>
        <HBox className='mt-5 mb-1' justify="space-between" align="center">
            <HBox align='center'>
                <H3 color='second' align='left' className='mr-1'>Blogs Table</H3>
                <AddIcon
                    className='clickable'
                    onClick={() => setOpenBlogAdd(true)}
                />
                {isLoading && <LoadingTxt className='ml-1'>Loading...</LoadingTxt>}
            </HBox>
            <HBox align='center'>
                <Tooltip title='Filtering' arrow>
                    <IconButton onClick={() => setOpen(true)}>
                        <FiFilter style={{color: `${colors.red}`}} />
                    </IconButton>
                </Tooltip>
                <InputText
                    className='ml-1'
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                    placeholder="Search for a keyword..."
                />
            </HBox>
        </HBox>
        <VBox style={{ border: `1px solid ${colors.grey}`, borderRadius: 5 }}>
            <Table sx={{ minWidth: 500 }} size='small'>
                <TableHead style={{ backgroundColor: colors.lightGrey }}>
                    <TableRow>
                        <TableCell className='bold' align='left'>Title</TableCell>
                        <TableCell className='bold' align='left'>Added By</TableCell>
                        <TableCell className='bold' align='left'>Category</TableCell>
                        <TableCell className='bold' align='left'>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {blogs.map((blog, index) => (
                        <TableRow key={index} style={{ height: 33 }}>
                            <TableCell align='left' sx={{width: '30%'}}>
                                {
                                    blog.title.length>=50 ? blog.title.substring(0, 50)+'...' : blog.title
                                }
                            </TableCell>
                            <TableCell align='left' sx={{width: '20%'}}>
                                {blog.added_by_name}
                            </TableCell>
                            <TableCell sx={{width: '30%'}}>
                                {blog.category.map((c, i)=>(
                                    <span key={i} className={i===0 ? '' : 'ml-2'}>{c.name}</span>
                                ))}
                            </TableCell>
                            <TableCell align='left' sx={{width: '20%'}}>
                                <IconButton value={blog.id} 
                                onClick={(e) => {
                                                    setBlogId(e.currentTarget.value);
                                                    setOpenView(true);
                                                }} >
                                    <FiEye fontSize={16} style={{ color: colors.blue }}/>
                                </IconButton>
                                <IconButton value={blog.id}
                                onClick={(e) => {
                                                    setBlogId(e.currentTarget.value);
                                                    setOpenEdit(true);
                                                }} >
                                    <FiEdit fontSize={16} style={{ color: colors.green }}/>
                                </IconButton>
                                <IconButton value={blog.id} 
                                onClick={(e) => {
                                                    setBlogId(e.currentTarget.value);
                                                    setOpenConfirm(true);
                                                }} >
                                    <AiOutlineDelete fontSize={16} style={{ color: colors.red }}/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                            <TableCell colSpan={8} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            colSpan={8}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </VBox>

        <BlogAddDlg
            open={openBlogAdd}
            setOpen={setOpenBlogAdd}
            modules={modules}
        />
        
        <BlogFilterDlg
            open={open}
            setOpen={setOpen}
            ordering={ordering}
            setOrdering={setOrdering}
            filtering={filtering}
            setFiltering={setFiltering}
            clearFilter={clearFilter}
        />

        <BlogViewDlg
            open={openView}
            setOpen={setOpenView}
            id={blogId}
        />

        <BlogEditDlg
            open={openEdit}
            setOpen={setOpenEdit}
            id={blogId}
            modules={modules}
        />

        <Confirm
            message = 'Are you sure to delete the blog?'
            open={openConfirm}
            setOpen={setOpenConfirm}
            onConfirm={onConfirm}
            btnColor='second'
        />
        </>
    )
}

export default BlogTable;