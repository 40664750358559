import { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from "notistack";

import { HBox, VBox } from "../../../components/Containers";
import { Button, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";
import CheckChoice from "../../../components/CheckChoice";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const ChangePaymentStatusDlg = ({ open, setOpen, appointIndex, appointments, setAppointments }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [statuses, setStatuses] = useState([
        {text: "Pending", checked: false},
        {text: "Paid", checked: false},
        {text: "Refunded", checked: false}
    ]);
    const [payAmount, setPayAmount] = useState(null);
    const [refundAmount, setRefundAmount] = useState(null);

    const [payAmountError, setPayAmountError] = useState("");
    const [refundAmountError, setRefundAmountError] = useState("");

    useEffect(() => {
        if (open) {
            setStatus(appointments[appointIndex].payment_status);
            setPayAmount(appointments[appointIndex].pay_amount);
            setRefundAmount(appointments[appointIndex].refund_amount);
        }
    }, [open]);

    const handleChangePayStatus = () => {
        if (validate()) {
            if (appointments[appointIndex]?.payment_method === 'bKash' && statuses[2].checked) {
                enqueueSnackbar("Refunding through bKash API. This may take a while. Please wait a moment.", { persist: true });
            }
            else {
                enqueueSnackbar("Changing payment status... Please wait a moment.", { persist: true });
            }
            
            setOpen(false);
            axios({
                method: 'PUT',
                url: 'nhadmin/appointment-details/',
                data: {
                    appointment_id: appointments[appointIndex].id,
                    key: 'payment_status',
                    value: getStatus(),
                    pay_amount: payAmount,
                    refund_amount: refundAmount
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
                }
            })
            .then((response) => {
                // setIsLoading(false);
                closeSnackbar();
                if (response.status === 200) {
                    let values = [...appointments];
                    values[appointIndex].pay_amount = response.data.pay_amount;
                    values[appointIndex].refund_amount = response.data.refund_amount;
                    values[appointIndex].payment_status = response.data.payment_status;
                    values[appointIndex].payment_method = response.data.payment_method;
                    setAppointments(values);
                    setPayAmount(null);
                    setRefundAmount(null);
                    enqueueSnackbar('Payment status changed.', {variant: 'success'});
                } else {
                    console.log('PAYMENT STATUS CHANGE FAILED', response.status);
                }
            })
            .catch((error) => {
                // setIsLoading(false);
                closeSnackbar();
                enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
                console.log('PAYMENT STATUS CHANGE ERROR', error);
            })
        }
    }

    const setStatus = (choice) => {
        for (let j=0; j<statuses.length; j++) {
            let values = [...statuses];
            if (values[j].text === choice) {
                values[j].checked = true;
            }
            else {
                values[j].checked = false;
            }
            setStatuses(values);
        }
    }

    const getStatus = () => {
        for (let i=0; i<statuses.length; i++) {
            if (statuses[i].checked) {
                return statuses[i].text;
            }
        }
    }

    const validate = () => {
        var ok = true;

        if (payAmount !== null) {
            setPayAmountError("");
        }
        else {
            if (getStatus() === 'Paid') {
                setPayAmountError("This field cannot be kept blank.");
                ok = false;
            }
        }

        if (refundAmount !== null) {
            setRefundAmountError("");
        }
        else {
            if (getStatus() === 'Refunded' && appointments[appointIndex]?.payment_method === 'Manual') {
                setRefundAmountError("This field cannot be kept blank.");
                ok = false;
            }
        }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth='xs' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Change Payment Status</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className="p-3">
                <CheckChoice
                    items={statuses}
                    setItems={setStatuses}
                    radio={true}
                />
                {statuses[1].checked && <InputText
                    className="mt-1"
                    type="number"
                    placeholder="Pay Amount"
                    value={payAmount}
                    onChange={e => setPayAmount(e.target.value)}
                    helpText={payAmountError}
                />}
                {statuses[2].checked &&
                    (() => {
                        if (appointments[appointIndex]?.payment_method === 'bKash')
                            return <P2 color='' className="mt-1">After saving <span style={{ color: colors.green, fontWeight: 'bold' }}>৳{appointments[appointIndex]?.pay_amount}</span> will be refunded though bKash API. This process is automatic and irreversible.</P2>
                        else
                            return <>
                                {statuses[2].checked && <InputText
                                    className="mt-1"
                                    type="number"
                                    placeholder="Refund Amount"
                                    value={refundAmount}
                                    onChange={e => setRefundAmount(e.target.value)}
                                    helpText={refundAmountError}
                                />}
                                <P2 color='second' className="mt-1">Please note that a manual refund is required since the patient made a manual payment.</P2>
                            </>
                    })()
                }
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleChangePayStatus}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default ChangePaymentStatusDlg;