import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { BiImport } from "react-icons/bi";
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from "react-router-dom";
import { FiPlusSquare } from 'react-icons/fi';

import { HBox, VBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import { Button, NavBtnActive, NavBtn } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import colors from "../../config/colors";
import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import AddPtAppBannerDlg from './components/AddPtAppBannerDlg';
import PtAppBannerCard from './components/PtAppBannerCard';

const SLink = styled(Link)`
    text-decoration: none;
`

const AddIcon = styled(FiPlusSquare)`
    font-size: 24px;
    color: ${colors.blue};
`

const PromotionBanners = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ptAppBanners, setPtAppBanners] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [openAddPtAppBanner, setOpenAddPtAppBanner] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        closeSnackbar();
        enqueueSnackbar('Loading...', { persist: true });
        fetchPtAppBanners();
    }, []);

    const fetchPtAppBanners = () => {
        axios({
            method: "GET",
            url: 'nhadmin/patient-app-banner-list/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
            },
        }).then(function (res) {
            setIsLoading(false);
            closeSnackbar();
            if (res.status === 200) {
                setPtAppBanners(res.data);
            }
            else {
                console.log('PATIENT APP BANNER FETCH FAILED');
                enqueueSnackbar('Something went wrong... Please try again.', {variant: 'error'});
            }
        }).catch((error) => {
            console.log('PATIENT APP BANNER FETCH ERROR', error);
            setIsLoading(false);
            closeSnackbar();
            enqueueSnackbar('Something went wrong... Please try again.', {variant: 'error'});
        });
    }

    return (
        <>
            <LowerNav selected='promotions' />
            <HBox>
                <LeftPanel align="center">
                    <SLink to='/vouchers' className='mt-8'>
                        <NavBtn size="sm" color="first" className="" outlined>
                            <div className="ml-1">Vouchers</div>
                        </NavBtn>
                    </SLink>
                    <SLink to='/referrals' className='mt-1'>
                        <NavBtn size="sm" color="first" className="" outlined>
                            <div className="ml-1">Referrals</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">Banners</div>
                    </NavBtnActive>
                </LeftPanel>
                <CenterPanel>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 50px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='p-4'>
                            <HBox justify='space-between' align='center' className='mb-2'>
                                <H4 color='second'>Patient App Banners</H4>
                                <AddIcon
                                    className='clickable'
                                    onClick={() => setOpenAddPtAppBanner(true)}
                                />
                            </HBox>
                            {ptAppBanners.length ? ptAppBanners.map((ptAppBanner, index) => (
                                    <PtAppBannerCard
                                        index={index}
                                        id={ptAppBanner.id}
                                        image={ptAppBanner.image}
                                        link={ptAppBanner.link}
                                        banners={ptAppBanners}
                                        setBanners={setPtAppBanners}
                                    />
                                ))
                                :
                                !isLoading && <P1 align='center'>No banners.</P1>
                            }
                        </VBox>
                    </Scrollbars>
                </CenterPanel>
                <RightPanel />
            </HBox>
            <Footer />

            <AddPtAppBannerDlg
                open={openAddPtAppBanner}
                setOpen={setOpenAddPtAppBanner}
                setBanners={setPtAppBanners}
            />
        </>
    )
}

export default PromotionBanners;