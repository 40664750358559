import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { BiImport } from "react-icons/bi";
import { Scrollbars } from 'react-custom-scrollbars';

import { HBox, VBox } from "../../components/Containers";
import { Button } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import colors from "../../config/colors";
import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import EventLogTable from "./components/EventLogTable";

const Container = styled(VBox)`
    width: 100%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
`

const Doctors = () => {
    return (
        <>
            <LowerNav selected='event-logs' />
            <Container>
                <Scrollbars
                    style={{ height: 'calc(100vh - 50px)' }}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
                }>
                    <VBox className='p-4'>
                        <EventLogTable />
                    </VBox>
                </Scrollbars>
            </Container>
            <Footer />
        </>
    )
}

export default Doctors;