import { useEffect, useState } from "react";
import styled from 'styled-components';
import axios from "axios";
import { Dialog } from "@mui/material"
import { FiX } from "react-icons/fi";
import { useSnackbar } from "notistack";
import { Scrollbars } from 'react-custom-scrollbars';
import { useNavigate } from "react-router-dom";

import { HBox, VBox } from "../components/Containers";
import { IconButton, Button } from "../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import colors from "../config/colors";
import { getDate, getTimeFromDateTime } from "../utils";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const NotificationCardContainer = styled(HBox)`
    width: '100%';
    /* border: 1px solid ${colors.grey}; */
    border-radius: 5px;
    background-color: ${props => props.seen ? 'white' : colors.lightGrey};

    :hover {
        /* background-color: ${colors.lightGrey}; */
        box-shadow: 0px 1px 5px 0px ${colors.shadow};
        transition: box-shadow 100ms;
    }
`

const ZoomInvitationCard = ({ text, data, createdAt, isSeen, handleClose }) => {
    const { patientName, meetingNumber, password } = JSON.parse(data);

    const handleJoinZoom = () => {
        window.open(`${process.env.REACT_APP_ZOOM_MEETING_URL}p/${meetingNumber}/${password}/${patientName}`);
        handleClose();
    }
    
    return (
        <NotificationCardContainer seen={isSeen} className='p-2 mb-1 clickable' align='center' justify='space-between' onClick={handleJoinZoom}>
            <VBox style={{ width: '60%' }}>
                <H5 className="mb-0_5">Zoom Invitation</H5>
                <P2>{text}</P2>
                <P3 className='mt-0_5' color='second' style={{ fontStyle: 'normal' }}>{getDate(createdAt, 'short')} at {getTimeFromDateTime(createdAt)}</P3>
                
            </VBox>
            <Button size='sm' color='first'>Join Zoom</Button>
        </NotificationCardContainer>
    )
}

const DoctorOnboardingCard = ({ text, data, createdAt, isSeen, handleClose }) => {
    const navigate = useNavigate();

    const handleViewDoctors = () => {
        navigate('/doctors');
        handleClose();
    }

    return (
        <NotificationCardContainer seen={isSeen} className='p-2 mb-1 clickable' align='center' justify='space-between' onClick={handleViewDoctors}>
            <VBox style={{ width: '60%' }}>
                <H5 className="mb-0_5">Doctor Onboarding</H5>
                <P2>{text}</P2>
                <P3 className='mt-0_5' color='second' style={{ fontStyle: 'normal' }}>{getDate(createdAt, 'short')} at {getTimeFromDateTime(createdAt)}</P3>
            </VBox>
            <Button size='sm' color='first'>View</Button>
        </NotificationCardContainer>
    )
}

const DoctorDeleteCard = ({ text, data, createdAt, isSeen, handleClose }) => {
    return (
        <NotificationCardContainer seen={isSeen} className='p-2 mb-1' align='center' justify='space-between'>
            <VBox style={{ width: '60%' }}>
                <H5 className="mb-0_5">Doctor Delete</H5>
                <P2>{text}</P2>
                <P3 className='mt-0_5' color='second' style={{ fontStyle: 'normal' }}>{getDate(createdAt, 'short')} at {getTimeFromDateTime(createdAt)}</P3>
            </VBox>
        </NotificationCardContainer>
    )
}

const NotificationsDlg = ({ open, setOpen, setNotifCount }) => {
    const pageSize = 10;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [notifications, setNotifications] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageSize);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const init = () => {
            setOffset(0);
            setLimit(pageSize);
            setHasMore(true);
            setNotifications([]);
            
            getData();
            setNotifCount(0);
        }
        if (open) init();
    }, [open]);

    const getData = () => {
        axios({
            method: 'GET',
            url: `chat/notification-list/`,
            params: {
                offset: offset,
                limit: limit
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setNotifications(notifications.concat(response.data.notifications));
                setHasMore(response.data.has_more);
                setCount(response.data.count);
                setOffset(offset+pageSize);
                setLimit(limit+pageSize);
            } else {
                enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
                console.log('NOTIFICATION GET FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
            console.log('NOTIFICATION GET ERROR', error);
        })
    }

    const statusChangeToSeen = () => {
        axios({
            method: 'PUT',
            url: `chat/notification-list/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                console.log('NOTIFICATIONS STATUS UPDATED');
            } else {
                console.log('NOTIFICATIONS STATUS CHANGE FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('NOTIFICATIONS STATUS CHANGE ERROR', error);
        })
    }

    const handleFetchData = () => {
        if (hasMore & !isLoading) {
            getData();
        }
    }

    const handleScrollUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1) handleFetchData();
    }
    
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
        statusChangeToSeen();
    }

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            fullWidth
            scroll="body"
        >
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H4 className="ml-2">Notifications</H4>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <Scrollbars
                    style={{ height: '70vh' }}
                    onUpdate={handleScrollUpdate}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
            }>
                <VBox className='p-2'>
                    {notifications.length ?
                        notifications.map((notification, index) => (
                                (() => {
                                    if (notification.type === 'zoom_invitation')
                                        return <ZoomInvitationCard
                                                  text={notification.text}
                                                  data={notification.data}
                                                  createdAt={notification.created_at}
                                                  isSeen={notification.is_seen}
                                                  handleClose={handleClose}
                                              />
                                    else if (notification.type === 'doctor_onboarding')
                                        return <DoctorOnboardingCard
                                                  text={notification.text}
                                                  createdAt={notification.created_at}
                                                  isSeen={notification.is_seen}
                                                  handleClose={handleClose}
                                              />
                                    else if (notification.type === 'doctor_delete')
                                        return <DoctorDeleteCard
                                                    text={notification.text}
                                                    createdAt={notification.created_at}
                                                    isSeen={notification.is_seen}
                                                    handleClose={handleClose}
                                                />
                                })()

                        ))
                        :
                        !isLoading && <VBox align='center' justify='center' style={{ width: '100%', height: '100%' }}>
                            <img src='images/notfound.png' style={{ height: 60 }} />
                            <H3 className="mt-2">No Notifications</H3>
                        </VBox>
                    }
                </VBox>
            </Scrollbars>
            <Line />
            <HBox justify="flex-end" className="p-1">
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
            </HBox>
        </Dialog>
    )
}

export default NotificationsDlg;