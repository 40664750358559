import { useState, useEffect, forwardRef } from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter,
         TablePagination, TableRow, IconButton, Tooltip } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from "axios";
import { useSnackbar } from 'notistack';
import { FiFilter, FiCalendar, FiEdit, FiX, FiEye } from 'react-icons/fi';
import moment from 'moment';

import { VBox, HBox } from '../../../components/Containers';
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from '../../../components/InputText';
import { Button } from '../../../components/Buttons';
import colors from "../../../config/colors";
import EventLogFilterDlg from './EventLogFilterDlg';
import EventLogDataDlg from './EventLogDataDlg';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const colorProps = {
    'On Hold': '#ff6600',
    Onboarded: colors.green,
    Draft: colors.darkGrey,
    'Edit Requested': colors.red,
    default: colors.darkGrey,
};

const Image = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1px solid ${colors.grey};
`

const LoadingTxt = styled(P3)`
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    padding: 4px;
`

const StatusChip = styled(HBox)`
    padding: 2px 5px;
    border-radius: 5px;
    color: white;
    background-color: ${props => colorProps[props.status || 'default']};
    max-width: max-content;
`

const FilterIcon = styled(FiFilter)`
    // size: 16px;
    color: ${colors.blue};
`

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        console.log('next button', count);
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <HBox style={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </HBox>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const EventLogTable = ({  }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [eventLogs, setEventLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState(null);

    const [openFilterBy, setOpenFilterBy] = useState(false);
    const [openEventLogData, setOpenEventLogData] = useState(false);
    const [selectedEventLogId, setSelectedEventLogId] = useState(null);

    const [ordering, setOrdering] = useState({orderBy: 'created_at', direction: '-'});
    const [filtering, setFiltering] = useState({
        logLevel: '', userId: '',
        category: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => {
        getData();
    }, [page, rowsPerPage]);
    useEffect(() => {
        reloadData();
    }, [ordering, filtering]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'nhadmin/event-log-list/',
            params: {
                user_id: filtering.userId,
                log_level: filtering.logLevel,
                category: filtering.category,
                order_by: ordering.orderBy,
                direction: ordering.direction,
                offset: page*rowsPerPage,
                limit: page*rowsPerPage+rowsPerPage
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setEventLogs(response.data.event_logs);
                setCount(response.data.count);
            } else {
                console.log('EVENT LOG LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('EVENT LOG LIST FETCH ERROR', error);
        })
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const reloadData = () => {
        setPage(0);
        getData();
    }

    const clearFilter = () => {
        setFiltering({
            logLevel: '', userId: '',
            category: '',
        });
    }

    return (
        <>
        <HBox justify="space-between" align="center" className='mb-1'>
            <HBox align='center'>
                <H3 color='second' align='left' className='mr-1'>Event Logs Table</H3>
                <Tooltip title='Filtering' arrow>
                    <IconButton onClick={() => setOpenFilterBy(true)}>
                        <FilterIcon />
                    </IconButton>
                </Tooltip>
                {isLoading && <LoadingTxt className='ml-1'>Loading...</LoadingTxt>}
            </HBox>
        </HBox>
        <VBox style={{ border: `1px solid ${colors.grey}`, borderRadius: 5 }}>
            <Table sx={{ minWidth: 500 }} size='small'>
                <TableHead style={{ backgroundColor: colors.lightGrey }}>
                    <TableRow>
                        <TableCell className='bold'>User ID</TableCell>
                        <TableCell className='bold'>User Type</TableCell>
                        <TableCell className='bold'>User Name</TableCell>
                        <TableCell className='bold'>Log Level</TableCell>
                        <TableCell className='bold'>Category</TableCell>
                        <TableCell className='bold'>Message</TableCell>
                        <TableCell className='bold'>Created At</TableCell>
                        <TableCell className='bold'>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventLogs.map((eventLog, index) => (
                        <TableRow key={eventLog.id} style={{ height: 33 }}>
                            <TableCell>
                                {eventLog.user_id}
                            </TableCell>
                            <TableCell>
                                {eventLog.user_type}
                            </TableCell>
                            <TableCell>
                                {eventLog.user_full_name}
                            </TableCell>
                            <TableCell>
                                {eventLog.log_level}
                            </TableCell>
                            <TableCell>
                                {eventLog.category}
                            </TableCell>
                            <TableCell>
                                {eventLog.message}
                            </TableCell>
                            <TableCell>
                                {moment(eventLog.created_at).format('LLL')}
                            </TableCell>
                            <TableCell style={{ width: 150 }}>
                                <Tooltip title='View Data' arrow>
                                    <IconButton>
                                        <FiEye
                                            fontSize={16}
                                            style={{ color: colors.blue }}
                                            onClick={() => {setSelectedEventLogId(eventLog.id);
                                                            setOpenEventLogData(true)}}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                            <TableCell colSpan={8} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            colSpan={8}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </VBox>

        <EventLogFilterDlg
            open={openFilterBy}
            setOpen={setOpenFilterBy}
            ordering={ordering}
            setOrdering={setOrdering}
            filtering={filtering}
            setFiltering={setFiltering}
            clearFilter={clearFilter}
        />
        <EventLogDataDlg
            open={openEventLogData}
            setOpen={setOpenEventLogData}
            eventLogId={selectedEventLogId}
        />
        </>
    );
}

export default EventLogTable;