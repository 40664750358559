import styled from "styled-components";

import colors from "../config/colors";

const Select = styled.select`
    border-radius: 3px;
    width: 100%;
    height: 2.5em;
    background-color: white;
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    ::placeholder {
        color: ${colors.grey};
    }
    :focus {
        outline: 1px solid ${colors.blue};
    }
`

export default Select;