import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { FiEdit3, FiCheck, FiX, FiTrash, FiPlusSquare } from "react-icons/fi";
import { FaCamera } from "react-icons/fa";
import moment from "moment";
import { useSnackbar } from 'notistack';
import { Dialog } from "@mui/material";

import { Button, NavBtnActive, NavBtn, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../../components/Containers";
import { InputTextarea } from "../../../components/InputText";
import Confirm from "../../../layouts/Confirm";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { genderData, specialtiesData } from "../../../data";
import { getDate, getTime } from "../../../utils";
import PictureUploadDlg from "./PictureUploadDlg";
import SignUploadDlg from "./SignUploadDlg";
import AddAffiliationDlg from "./AddAffiliationDlg";
import AddChamberDlg from "./AddChamberDlg";
import AddAppointScheduleDlg from "./AddAppointScheduleDlg";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Paper = styled.div`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const Image = styled.img`
    height: 120px;
    width: 120px;
    border-radius: 60px;
    border: 1px solid ${colors.grey};
`

const Signature = styled.img`
    height: 32px;
    width: 120px;
    border: 1px solid ${colors.grey};
`

const AddPhotoIcon = styled(FaCamera)`
    position: fixed;
    right: 5px;
    bottom: 5px;
    color: ${colors.red};
    font-size: 20px;
    cursor: pointer;
    z-index: 1;

    :hover {
        color: ${colors.darkRed};
    }
`

const AddSignIcon = styled(FaCamera)`
    position: fixed;
    right: 0px;
    bottom: 0px;
    color: ${colors.red};
    font-size: 20px;
    cursor: pointer;
    z-index: 1;

    :hover {
        color: ${colors.darkRed};
    }
`

const AddIcon = styled(FiPlusSquare)`
    color: ${colors.blue};
    cursor: pointer;
`

const ImageOverlay = styled.div`
    position: fixed;
    top: 0px;
    height: 120px;
    width: 120px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0;
    cursor: pointer;

    :hover {
        opacity: 0.05;
        transition: 300ms;
    }
`

const SignOverlay = styled.div`
    position: fixed;
    top: 0px;
    height: 32px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0;
    cursor: pointer;

    :hover {
        opacity: 0.05;
        transition: 300ms;
    }
`

const Input = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};
    
    :focus {
        color: black;
        outline: none;
    }
`

const Select = styled.select`
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};

    :focus {
        color: black;
        outline: none;
    }
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const DeleteIcon = styled(FiTrash)`
    color: ${colors.red};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const HighlightChip = styled(HBox)`
    background-color: ${colors.green};
    border: 1px solid ${colors.green};
    border-radius: 2px;
`

const getDaysStr = (schedule) => {
    let days = [];
    if (schedule.saturday) days.push('Sat');
    if (schedule.sunday) days.push('Sun');
    if (schedule.monday) days.push('Mon');
    if (schedule.tuesday) days.push('Tue');
    if (schedule.wednesday) days.push('Wed');
    if (schedule.thursday) days.push('Thu');
    if (schedule.friday) days.push('Fri');

    return (
        <>
        {days.map((day, index) => (
            index === 0 ?
                <span>{day}</span>
                :
                <span>{',' + day}</span>
        ))}
        </>
    )
}

const AffiliationCard = ({ isMobile, index, id, institution, designation, department,
                           currentlyWorking, showInSummary, affiliations, setAffiliations, className }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleConfirm = () => {
        axios({
            method: 'DELETE',
            url: `nhadmin/affiliation-details/`,
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                let values = [...affiliations];
                values.splice(index, 1);
                setAffiliations(values);

                enqueueSnackbar('Affiliation deleted.', { variant: 'success' });
            } else {
                console.log('AFFILIATION DELETE FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('AFFILIATION DELETE ERROR', error);
        })
    }

    return (
        <VBox className={className} style={{ width: '100%' }}>
            <HBox className="mb-1" align="center">
                <H4 className='bold'>{institution}</H4>
                {showInSummary && <HighlightChip className='p-0_5 ml-1'>
                    <P3 color="white">Highlighted</P3>
                </HighlightChip>}
            </HBox>
            <HBox align="flex-end" justify="space-between" style={{ width: '100%' }}>
                <VBox style={{ width: isMobile ? '30%' : '30%' }}>
                    <P3>Designation</P3>
                    <P1>{designation ? designation : 'Not Set'}</P1>
                </VBox>
                <VBox style={{ width: isMobile ? '30%' : '30%' }}>
                    <P3>Department</P3>
                    <P1>{department ? department : 'Not Set'}</P1>
                </VBox>
                <VBox style={{ width: isMobile ? '30%' : '30%' }}>
                    <P3>Present</P3>
                    <P1>{currentlyWorking ? 'Yes' : 'No'}</P1>
                </VBox>
                {/* <VBox style={{ width: isMobile ? '90%' : '30%' }} className={isMobile ? 'mt-1' : ''}>
                    <P3>Period</P3>
                    <P1>{startDate} - {endDate}</P1>
                </VBox> */}
                <DeleteIcon className={isMobile ? 'mt-1' : ''} onClick={() => setOpenConfirm(true)} />
            </HBox>
            <Confirm
                message="Are you sure to delete this affiliation?"
                btnColor="second"
                onConfirm={handleConfirm}
                open={openConfirm}
                setOpen={setOpenConfirm}
            />
        </VBox>
    )
}

const ChamberCard = ({ isMobile, index, id, address, chambers, setChambers, className }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleConfirm = () => {
        axios({
            method: 'DELETE',
            url: `nhadmin/chamber-details/`,
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                let values = [...chambers];
                values.splice(index, 1);
                setChambers(values);

                enqueueSnackbar('Chamber deleted.', { variant: 'success' });
            } else {
                console.log('CHAMBER DELETE FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('CHAMBER DELETE ERROR', error);
        })
    }

    return (
        <VBox className={className} style={{ width: '100%' }}>
            <HBox className="" align="center" justify='space-between'>
                <H4>{address ? address : 'Not Set'}</H4>
                <DeleteIcon className={isMobile ? 'mt-1' : ''} onClick={() => setOpenConfirm(true)} />
            </HBox>
            <Confirm
                message="Are you sure to delete this chamber?"
                btnColor="second"
                onConfirm={handleConfirm}
                open={openConfirm}
                setOpen={setOpenConfirm}
            />
        </VBox>
    )
}

const AvailabilityCard = ({ isMobile, id, index, schedule, schedules, setSchedules, className }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleConfirm = () => {
        axios({
            method: 'DELETE',
            url: `nhadmin/appointment-schedule-details/`,
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                let values = [...schedules];
                values.splice(index, 1);
                setSchedules(values);

                enqueueSnackbar('Appointment schedule deleted.', { variant: 'success' });
            } else {
                console.log('APPOINTMENT SCHEDULE DELETE FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('APPOINTMENT SCHEDULE DELETE ERROR', error);
        })
    }

    return (
        <VBox className={className} style={{ width: '100%' }}>
            <HBox align="flex-end" justify="space-between" style={{ width: '100%' }}>
                <VBox style={{ width: isMobile ? '45%' : '45%' }}>
                    <P3>Days</P3>
                    <P1>{getDaysStr(schedule) ? getDaysStr(schedule) : 'Not Set'}</P1>
                </VBox>
                <VBox style={{ width: isMobile ? '45%' : '45%' }}>
                    <P3>Time</P3>
                    <P1>{schedule.startTime ? getTime(schedule.startTime) : 'Not Set'} - {schedule.endTime ? getTime(schedule.endTime) : 'Not Set'}</P1>
                </VBox>
                <DeleteIcon className={isMobile ? 'mt-1' : ''} onClick={() => setOpenConfirm(true)} />
            </HBox>
            <Confirm
                message="Are you sure to delete this schedule?"
                btnColor="second"
                onConfirm={handleConfirm}
                open={openConfirm}
                setOpen={setOpenConfirm}
            />
        </VBox>
    )
}

const SetProfileDlg = ({ open, setOpen, doctorId, doctorName, setOpenFees,
                         doctors, setDoctors, doctorIndex }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [profile, setProfile] = useState({
        image: null, sign: null, title: "", first_name: "", last_name: "",
        bangla_title: "", bangla_name: "", gender: "",
        address: "", email: "", phone: "", assistant_phone: "", contact_preference: "", doctor_type: "",
        bmdc: "", qualification: "", trainings: "", specialty: "", about: "",
        account_type: "", account_name: "", account_number: "", bank_name: "", branch_name: "", relation_to_acc_holder: "",
        mobile_banking_number: "", mobile_banking_operator: "",
        alt_mobile_banking_number: "", alt_mobile_banking_operator: ""
    });
    const [editData, setEditData] = useState({
        title: null, first_name: "", last_name: "",
        bangla_title: "", bangla_name: "", gender: "", address: "",
        email: "", phone: "", assistant_phone: "", contact_preference: "", doctor_type: "",
        bmdc: "", qualification: "", trainings: "", experience: "", specialty: "", about: "",
        account_type: "", account_name: "", account_number: "", bank_name: "", branch_name: "", relation_to_acc_holder: "",
        mobile_banking_number: "", mobile_banking_operator: "",
        alt_mobile_banking_number: "", alt_mobile_banking_operator: ""
    });
    const [editState, setEditState] = useState({
        title: false, first_name: false, last_name: false,
        bangla_title: false, bangla_name: false, gender: false, address: false,
        email: false, phone: false, assistant_phone: false, contact_preference: false, doctor_type: false,
        bmdc: false, qualification: false, trainings: false, experience: false, specialty: false, about: false,
        account_type: false, account_name: false, account_number: false, bank_name: false, branch_name: false, relation_to_acc_holder: false,
        mobile_banking_number: false, mobile_banking_operator: false,
        alt_mobile_banking_number: false, alt_mobile_banking_operator: false
    });
    const [affiliations, setAffiliations] = useState([]);
    const [chambers, setChambers] = useState([]);
    const [appointSchedules, setAppointSchedules] = useState([]);

    const aboutChLimit = 2048;  // database table charfield max_length
    const [uploadPicOpen, setUploadPicOpen] = useState(false);
    const [uploadSignOpen, setUploadSignOpen] = useState(false);
    const [openAddAff, setOpenAddAff] = useState(false);
    const [openAddCham, setOpenAddCham] = useState(false);
    const [openAppointSchedule, setOpenAppointSchedule] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';

            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())

        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (open) {
            enqueueSnackbar("Loading...", { persist: true });
            getData();
        }
    }, [open, doctorId]);
    useEffect(() => setAffiliations(profile.affiliations), [profile]);
    useEffect(() => setChambers(profile.chambers), [profile]);
    useEffect(() => setAppointSchedules(profile.appointment_schedules), [profile]);

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `nhadmin/doctor-details/`,
            params: {
                doctor_id: doctorId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
            .then((response) => {
                setIsLoading(false);
                closeSnackbar();
                if (response.status === 200) {
                    setProfile(response.data);
                } else {
                    console.log('PROFILE FETCH FAILED', response.status);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                closeSnackbar();
                console.log('PFOFILE FETCH ERROR', error);
            })
    }

    const handleDoctorDelete = () => {
        enqueueSnackbar('Deleting the doctor...', { persist: true })
        axios({
            method: 'DELETE',
            url: `nhadmin/doctor-details/`,
            data: {
                id: doctorId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 202) {
                let values = [...doctors];
                values.splice(doctorIndex, 1);
                setDoctors(values);

                enqueueSnackbar('Doctor deleted.', { variant: 'success' });

                setOpen(false);
            } else {
                enqueueSnackbar('Doctor delete failed!', { variant: 'error' });
            }
        })
        .catch((error) => {
            console.log('DOCTOR DELETE ERROR', error);
            
            enqueueSnackbar('Doctor delete error!', { variant: 'error' });
        })
    }

    const handleChangeField = (field) => {
        axios({
            method: 'PUT',
            url: `nhadmin/doctor-details/`,
            data: {
                doctor_id: doctorId,
                key: field,
                value: editData[field],
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setProfile(response.data);
                    setEditState({ ...editState, [field]: false });

                    enqueueSnackbar('Profile updated.', { variant: 'success' });
                } else {
                    console.log('PROFILE UPDATE FAILED', response.status);
                    
                    enqueueSnackbar('Profile update failure!', { variant: 'error' });
                }
            })
            .catch((error) => {
                console.log('PFOFILE UPDATE ERROR', error);
                if (error.response?.data?.message) {
                    enqueueSnackbar(error.response.data.message, { variant: 'error' });
                }
                else {
                    enqueueSnackbar('Profile update error!', { variant: 'error' });
                }
            })
    }

    const handleClickEdit = (field) => {
        setEditData({ ...editData, [field]: profile[field] });
        setEditState({ ...editState, [field]: true });
    }

    const inputField = (name, field, type = 'text') => (
        <tr style={{ height: 30 }}>
            <td style={{ width: isMobile ? 120 : 200 }}><H5>{name}</H5></td>
            <td>
                {editState[field] ?
                    <Input
                        type={type}
                        value={editData[field]}
                        autoFocus
                        onChange={(e) => setEditData({ ...editData, [field]: e.target.value })}
                    />
                    :
                    <P1>
                        {profile[field] ?
                            type === 'date' ?
                                getDate(profile[field], 'long')
                                :
                                profile[field]
                            :
                            'Not Set'
                        }
                    </P1>
                }
            </td>
            <td style={{ textAlign: 'right' }}>
                {editState[field] ?
                    <>
                        <CheckIcon onClick={() => handleChangeField(field)} />
                        <XIcon className="ml-1" onClick={() => setEditState({ ...editState, [field]: false })} />
                    </>
                    :
                    <EditIcon onClick={() => handleClickEdit(field)} />
                }
            </td>
        </tr>
    )

    const selectField = (name, field, options, notSelectedTxt = "No Gender Selected") => (
        <tr style={{ height: 30 }}>
            <td style={{ width: isMobile ? 120 : 200 }}><H5>{name}</H5></td>
            <td>
                {editState[field] ?
                    <Select onChange={(e) => setEditData({ ...editData, [field]: e.target.value })}>
                        <option style={{ display: 'none' }}>{notSelectedTxt}</option>
                        {options.map((option, index) => (
                            <option value={option} selected={editData[field] === option}>{option}</option>
                        ))}
                    </Select>
                    :
                    <P1>{profile[field] ? profile[field] : 'Not Set'}</P1>
                }
            </td>
            <td style={{ textAlign: 'right' }}>
                {editState[field] ?
                    <>
                        <CheckIcon onClick={() => handleChangeField(field)} />
                        <XIcon className="ml-1" onClick={() => setEditState({ ...editState, [field]: false })} />
                    </>
                    :
                    <EditIcon onClick={() => handleClickEdit(field)} />
                }
            </td>
        </tr>
    )

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;

        setOpen(false);
    }

    return (
        <Dialog fullScreen scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="pt-1 pb-1" style={{ backgroundColor: colors.lightGrey }}>
                <HBox align="center">
                    <H3 className='ml-2'>Doctor Account</H3>
                    <P1 className="ml-1">for <span style={{ color: colors.red }}>{doctorName}</span></P1>
                </HBox>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <HBox>
                {isMobile && <TopPanel justify='center' className='p-3'>
                    <Button size='sm' color='first'>Doctor Profile</Button>
                    <Button
                        size='sm'
                        color='first'
                        className="mr-1"
                        outlined
                        onClick={() => {setOpenFees(true); setOpen(false)}}
                    >
                        Set Fees
                    </Button>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ height: 'calc(100vh - 50px)' }}>
                    <NavBtnActive size="sm" color="first" className="mt-8" outlined>
                        <div className="ml-1">Doctor Profile</div>
                    </NavBtnActive>
                    <NavBtn size="sm" color="first" className="mt-1" outlined onClick={() => {setOpenFees(true); setOpen(false)}}>
                        <div className="ml-1">Set Fees</div>
                    </NavBtn>
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 50px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4' }} />
                        }>
                        <VBox className="p-3" style={{ width: '100%' }}>
                            <Paper className={isMobile ? 'p-2' : 'p-4'}>
                                <HBox align="center">
                                    <VBox className="mr-3" style={{ contain: 'content' }}>
                                        <Image
                                            src={`${baseURL}${profile.image}`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = "/images/noImage.svg";
                                            }}
                                        />
                                        <AddPhotoIcon onClick={() => setUploadPicOpen(true)} />
                                        <ImageOverlay onClick={() => setUploadPicOpen(true)} />
                                    </VBox>
                                    <VBox className={isMobile ? 'mt-2' : ''}>
                                        <H3 color="first" className="mb-1">Photo</H3>
                                        {/* <P2 style={{ wordWrap: 'break-word' }}>A photo helps to communicate in a better way.</P2> */}
                                    </VBox>
                                </HBox>
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <HBox align="center">
                                    <VBox className="mr-3" style={{ contain: 'content' }}>
                                        <Signature
                                            src={`${baseURL}${profile.sign}`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = "/images/noImage.svg";
                                            }}
                                        />
                                        <AddSignIcon onClick={() => setUploadSignOpen(true)} />
                                        <SignOverlay onClick={() => setUploadSignOpen(true)} />
                                    </VBox>
                                    <VBox className={isMobile ? 'mt-2' : ''}>
                                        <H3 color="first" className="mb-1">Signature</H3>
                                    </VBox>
                                </HBox>
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <VBox>
                                    <H3 color="first">Personal Information</H3>
                                    {/* <P2>We keep your personal information secure.</P2> */}

                                    <table style={{ width: '100%' }} className="mt-2">
                                        <tr style={{ height: 30 }}>
                                            <td style={{ width: isMobile ? 120 : 200 }}><H5>DOCTOR ID</H5></td>
                                            <td><P1>{profile.id}</P1></td>
                                        </tr>
                                        <tr style={{ height: 30 }}>
                                            <td style={{ width: isMobile ? 120 : 200 }}><H5>USER ID</H5></td>
                                            <td><P1>{profile.user_id}</P1></td>
                                        </tr>
                                        {inputField('TITLE', 'title')}
                                        {inputField('FIRST NAME', 'first_name')}
                                        {inputField('LAST NAME', 'last_name')}
                                        {inputField('TITLE (বাংলা)', 'bangla_title')}
                                        {inputField('NAME (বাংলা)', 'bangla_name')}
                                        {selectField('GENDER', 'gender', genderData)}
                                        {inputField('ADDRESS', 'address')}
                                    </table>
                                </VBox>
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <VBox>
                                    <H3 color="first">Contact Information</H3>

                                    <table style={{ width: '100%' }} className="mt-2">
                                        {inputField('PHONE', 'phone')}
                                        {inputField("ASSISTANT'S PHONE", 'assistant_phone')}
                                        {inputField('EMAIL', 'email')}
                                        {selectField('CONTACT PREFERENCE', 'contact_preference', ['Personal', 'Assistant'])}
                                        {/* <tr style={{ height: 30 }}>
                                            <td style={{ width: isMobile ? 120 : 200 }}><H5>PHONE</H5></td>
                                            <td><P1>{profile.phone}</P1></td>
                                        </tr>
                                        <tr style={{ height: 30 }}>
                                            <td style={{ width: isMobile ? 120 : 200 }}><H5>EMAIL</H5></td>
                                            <td><P1>{profile.email ? profile.email : 'Not Set'}</P1></td>
                                        </tr> */}
                                    </table>
                                </VBox>
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <VBox>
                                    <H3 color="first">Initial Password</H3>

                                    <table style={{ width: '100%' }} className="mt-2">
                                        <tr style={{ height: 30 }}>
                                            <td style={{ width: isMobile ? 120 : 200 }}><H5>PASSWORD</H5></td>
                                            <td><P1>{profile.doctor_initial_password ? profile.doctor_initial_password?.password : 'N/A'}</P1></td>
                                        </tr>
                                        <tr style={{ height: 30 }}>
                                            <td style={{ width: isMobile ? 120 : 200 }}><H5>CHANGED BY DOCTOR</H5></td>
                                            <td><P1>{profile.doctor_initial_password ? profile.doctor_initial_password?.is_changed ? 'Yes' : 'No' : 'N/A'}</P1></td>
                                        </tr>
                                    </table>
                                </VBox>
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <VBox>
                                    <H3 color="first">Bank Information</H3>

                                    <table style={{ width: '100%' }} className="mt-2">
                                        {selectField('A/C TYPE', 'account_type', ['Savings', 'Current'])}
                                        {inputField('A/C NAME', 'account_name' )}
                                        {inputField('A/C NUMBER', 'account_number')}
                                        {inputField('NAME OF BANK', 'bank_name')}
                                        {inputField('BRANCH', 'branch_name')}
                                        {inputField('REL. TO A/C HOLDER (IF NOT PERSONAL)', 'relation_to_acc_holder')}
                                    </table>
                                </VBox>
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <VBox>
                                    <H3 color="first">Mobile Banking Information</H3>

                                    <table style={{ width: '100%' }} className="mt-2">
                                        {inputField('MOBILE A/C NO.', 'mobile_banking_number' )}
                                        {inputField('OPERATOR', 'mobile_banking_operator')}
                                    </table>
                                </VBox>
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <VBox>
                                    <H3 color="first">Professional Information</H3>

                                    <table style={{ width: '100%' }} className="mt-2">
                                        {selectField('DOCTOR TYPE', 'doctor_type', ['MBBS', 'BDS'])}
                                        {inputField('BMDC REG. NO.', 'bmdc' )}
                                        {inputField('ACADEMIC QUALIFICATION', 'qualification')}
                                        {inputField('POST-GRAD TRAININGS', 'trainings')}
                                        {inputField('YEARS OF EXPERIENCE', 'experience')}
                                        {selectField('SPECIALTY', 'specialty', specialtiesData)}
                                    </table>
                                </VBox>
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <HBox justify="space-between" align="center">
                                    <H3 color='first' className="mb-2">About Doctor</H3>
                                    {editState.about ?
                                        <HBox>
                                            <CheckIcon onClick={() => handleChangeField("about")} />
                                            <XIcon className="ml-1" onClick={() => setEditState({...editState, about: false})} />
                                        </HBox>
                                        :
                                        <EditIcon onClick={() => handleClickEdit("about")} />
                                    }
                                </HBox>
                                {editState.about ?
                                    <>
                                    <InputTextarea
                                        rows={5}
                                        placeholder='Write something so that patients can know about doctor better...'
                                        value={editData.about}
                                        autoFocus
                                        onChange={(e) => setEditData({...editData, about: e.target.value.slice(0, aboutChLimit)})}
                                    />
                                    <P2 align="right">{editData.about? editData.about.length : 0} / {aboutChLimit}</P2>
                                    </>
                                    :
                                    <P1>{profile.about ? profile.about : 'Not Set'}</P1>
                                }
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <HBox align="center" justify="space-between" style={{ width: '100%' }}>
                                    <H3 color="first">Affiliations</H3>
                                    <AddIcon onClick={() => setOpenAddAff(true)} />
                                </HBox>
                                {affiliations?.map((affiliation, index) => (
                                    <>
                                    <AffiliationCard
                                        className={index===0 ? 'mt-3' : 'mt-4'}
                                        isMobile={isMobile}
                                        index={index}
                                        id={affiliation.id}
                                        institution={affiliation.institution}
                                        designation={affiliation.designation}
                                        department={affiliation.department}
                                        currentlyWorking={affiliation.currently_working}
                                        showInSummary={affiliation.show_in_summary}
                                        affiliations={affiliations}
                                        setAffiliations={setAffiliations}
                                    />
                                    </>
                                ))}
                                <AddAffiliationDlg
                                    doctorId={doctorId}
                                    open={openAddAff}
                                    setOpen={setOpenAddAff}
                                    affiliations={affiliations}
                                    setAffiliations={setAffiliations}
                                />
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <HBox align="center" justify="space-between" style={{ width: '100%' }}>
                                    <H3 color="first">Chambers</H3>
                                    <AddIcon onClick={() => setOpenAddCham(true)} />
                                </HBox>
                                {chambers?.map((chamber, index) => (
                                    <>
                                    <ChamberCard
                                        className={index===0 ? 'mt-3' : 'mt-4'}
                                        isMobile={isMobile}
                                        index={index}
                                        id={chamber.id}
                                        address={chamber.address}
                                        chambers={chambers}
                                        setChambers={setChambers}
                                    />
                                    </>
                                ))}
                                <AddChamberDlg
                                    doctorId={doctorId}
                                    open={openAddCham}
                                    setOpen={setOpenAddCham}
                                    chambers={chambers}
                                    setChambers={setChambers}
                                />
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <HBox align="center" justify="space-between" style={{ width: '100%' }}>
                                    <H3 color="first">Availability</H3>
                                    <AddIcon onClick={() => setOpenAppointSchedule(true)} />
                                </HBox>
                                {appointSchedules?.map((appointSchedule, index) => (
                                    <AvailabilityCard
                                        className={index===0 ? 'mt-3' : 'mt-4'}
                                        isMobile={isMobile}
                                        key={appointSchedule.id}
                                        id={appointSchedule.id}
                                        index={index}
                                        schedule={appointSchedule.schedule}
                                        schedules={appointSchedules}
                                        setSchedules={setAppointSchedules}
                                    />
                                ))}
                                <AddAppointScheduleDlg
                                    doctorId={doctorId}
                                    open={openAppointSchedule}
                                    setOpen={setOpenAppointSchedule}
                                    schedules={appointSchedules}
                                    setSchedules={setAppointSchedules}
                                />
                            </Paper>

                            <Paper className={isMobile ? 'mt-2 p-2' : 'mt-2 p-4'}>
                                <VBox>
                                    <table style={{ width: '100%' }} className="">
                                        <tr style={{ height: 30 }}>
                                            <td style={{ width: isMobile ? 120 : 200 }}><H5>ADDED AT</H5></td>
                                            <td><P1>{moment(profile.created_at).format('LLL')}</P1></td>
                                        </tr>
                                        <tr style={{ height: 30 }}>
                                            <td style={{ width: isMobile ? 120 : 200 }}><H5>EDITED AT</H5></td>
                                            <td><P1>{moment(profile.edited_at).format('LLL')}</P1></td>
                                        </tr>
                                    </table>
                                </VBox>
                            </Paper>
                            
                            <HBox justify='center' className="mt-4" style={{ width: '100%' }}>
                                <Button
                                    size='sm'
                                    color='second'
                                    onClick={() => setOpenConfirmDelete(true)}
                                >
                                    Delete Doctor
                                </Button>
                            </HBox>
                        </VBox>
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel style={{ height: 'calc(100vh - 50px)' }}></RightPanel>}
            </HBox>

            <PictureUploadDlg
                doctorId={doctorId}
                open={uploadPicOpen}
                setOpen={setUploadPicOpen}
                profile={profile}
                setProfile={setProfile}
            />
            <SignUploadDlg
                doctorId={doctorId}
                open={uploadSignOpen}
                setOpen={setUploadSignOpen}
                profile={profile}
                setProfile={setProfile}
            />
            <Confirm
                message="Are you sure to delete this doctor?"
                btnColor="second"
                onConfirm={handleDoctorDelete}
                open={openConfirmDelete}
                setOpen={setOpenConfirmDelete}
            />
        </Dialog>
    )
}

export default SetProfileDlg;
