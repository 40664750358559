import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiEdit3, FiCheck, FiX } from "react-icons/fi";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { Dialog, Tooltip } from "@mui/material";

import { InputText } from "../../../components/InputText";
import { Button, NavBtnActive, NavBtn, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../../components/Containers";
import Confirm from "../../../layouts/Confirm";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { getTime, getDate } from "../../../utils";

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const Paper = styled.div`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const Input = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};
    
    :focus {
        color: black;
        outline: none;
    }
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const SetFeesDlg = ({ open, setOpen, doctorId, doctorName, setOpenAccount, setOpenProfile }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [appConfig, setAppConfig] = useState({
        fee: null, followup_fee: null, urgent_fee: null, followup_duration: null,
    });
    const [editData, setEditData] = useState({
        fee: null, followup_fee: null, urgent_fee: null, followup_duration: null,
    });
    const [editState, setEditState] = useState({
        fee: false, followup_fee: false, urgent_fee: false, followup_duration: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (open) {
            enqueueSnackbar("Loading...", { persist: true });
            getData();
        }
    }, [open, doctorId]);

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `nhadmin/appointment-config-details/`,
            params: {
                doctor_id: doctorId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setAppConfig(response.data);
            } else {
                console.log('APPOINTMENT CONFIG FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('APPOINTMENT CONFIG FETCH ERROR', error);
        })
    }

    const handleClickEdit = (field) => {
        setEditData({...editData, [field]: appConfig[field]});
        setEditState({...editState, [field]: true});
    }

    const handleChangeField = (field) => {
        axios({
            method: 'PUT',
            url: `nhadmin/appointment-config-details/`,
            data: {
                doctor_id: doctorId,
                key: field,
                value: editData[field],
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                setAppConfig(response.data);
                setEditState({...editState, [field]: false});
                
                enqueueSnackbar('Fee updated.', {variant: 'success'});
            } else {
                console.log('APPOINTMENT CONFIG UPDATE FAILED', response.status);

                enqueueSnackbar('Fee update failure!', {variant: 'error'});
            }
        })
        .catch((error) => {
            console.log('APPOINTMENT CONFIG UPDATE ERROR', error);

            enqueueSnackbar('Fee update error!', {variant: 'error'});
        })
    }

    const inputField = (name, field, type='text') => (
        <tr style={{ height: 30 }}>
            <td style={{ width: isMobile ? 120 : 280 }}><H5>{name}</H5></td>
            <td>
            {editState[field] ?
                <Input
                    type={type}
                    value={editData[field]}
                    autoFocus
                    onChange={(e) => setEditData({...editData, [field]: e.target.value})}
                />
                :
                <P1>
                    {appConfig[field] ?
                        type==='date' ?
                            getDate(appConfig[field], 'long')
                            :
                            appConfig[field]
                        :
                        'Not Set'
                    }
                </P1>
            }
            </td>
            <td style={{ textAlign: 'right' }}>
                {editState[field] ?
                    <>
                        <CheckIcon onClick={() => handleChangeField(field)} />
                        <XIcon className="ml-1" onClick={() => setEditState({...editState, [field]: false})} />
                    </>
                    :
                    <EditIcon onClick={() => handleClickEdit(field)} />
                }
            </td>
        </tr>
    )

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog fullScreen scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="pt-1 pb-1" style={{ backgroundColor: colors.lightGrey }}>
                <HBox align="center">
                    <H3 className='ml-2'>Set Fees</H3>
                    <P1 className="ml-1">for <span style={{ color: colors.red }}>{doctorName}</span></P1>
                </HBox>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <HBox>
                {isMobile && <TopPanel justify='center' className='p-3'>
                    <Button size='sm' color='first' outlined onClick={() => {setOpenProfile(true); setOpen(false)}}>Doctor Account</Button>
                    <Button size='sm' color='first' className="mr-1">Set Fees</Button>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ height: 'calc(100vh - 50px)' }}>
                    <NavBtn size="sm" color="first" className="mt-8" outlined onClick={() => {setOpenProfile(true); setOpen(false)}}>
                        <div className="ml-1">Doctor Profile</div>
                    </NavBtn>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">Set Fees</div>
                    </NavBtnActive>
                </LeftPanel>}
                <CenterPanel style={{ width: isMobile ? '100%' : '60%' }}>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 50px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className="p-3" style={{ width: '100%' }}>
                            <Paper className="p-4 mb-2">
                                <VBox>
                                    <H3 color="first">Set Fees</H3>

                                    <table style={{ width: '100%' }} className="mt-2">
                                        {inputField('REGULAR FEE (৳)', 'fee', 'number')}
                                        {inputField('FOLLOW UP FEE (৳)', 'followup_fee', 'number')}
                                        {false && inputField('URGENT FEE (৳)', 'urgent_fee', 'number')}
                                        {inputField('FOLLOW UP DURATION (DAYS)', 'followup_duration', 'number')}
                                    </table>
                                </VBox>
                            </Paper>
                        </VBox>
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel style={{ height: 'calc(100vh - 50px)' }}></RightPanel>}
            </HBox>
        </Dialog>
    )
}

export default SetFeesDlg;
