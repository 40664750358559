import { useState, useEffect } from 'react';
import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import axios from "axios";

import { Button, IconButton } from "../../../components/Buttons";
import { H3 } from "../../../components/Typography";
import { HBox, VBox } from "../../../components/Containers";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const CategoryAddDlg = ({open, setOpen}) => {
    const [category, setCategory] = useState('');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const handleClose = (event, reason) => {
        if(reason && reason == 'backdropClick')
            return;
        
        setOpen(false);
    }

    const submitData = () => {
        if(validate()) {
            axios({
                method: 'POST',
                url: 'nhadmin/blog-category-list/',
                data: {'name': category},
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
                }
            })
            .then((response) => {
                if(response.status===200) {
                    enqueueSnackbar('Category Successfully Created', {variant: 'success', persist: true});
                    setTimeout(() => closeSnackbar(), 4000);

                    setCategory('');
                }
                else {
                    enqueueSnackbar('Category Creation Failed', {variant: 'error', persist: true});
                    setTimeout(() => closeSnackbar(), 4000);    
                }
            })
            .catch((error) => {
                if(error.response.status===403) {
                    enqueueSnackbar('Category Already Exists', {variant: 'error', persist: true});
                    setTimeout(() => closeSnackbar(), 4000);
                }
                else {
                    enqueueSnackbar('Category Creation Error', {variant: 'error', persist: true});
                    setTimeout(() => closeSnackbar(), 4000);
                }
            })
        }
    }

    const validate = () => {
        if(!category) {
            enqueueSnackbar('Name field cannot be empty.', {variant: 'error', persist: true});
            setTimeout(() => closeSnackbar(), 4000);
            return false;
        }
        return true;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='xs'
            fullWidth
        >
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Add Category</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <InputText 
                label='Enter New Category Name' 
                className='m-2' 
                value={category} 
                onChange={e => setCategory(e.target.value)}
            />
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={submitData}
                >
                    Submit
                </Button>
            </HBox>
        </Dialog>
    )
}

export default CategoryAddDlg;