import { useState } from "react";
import styled from "styled-components";
import { FiEye, FiTrash } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2 } from "../../../components/Typography";
import Confirm from "../../../layouts/Confirm";
import colors from "../../../config/colors";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const CardContainer = styled(HBox)`
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const ViewIcon = styled(FiEye)`
    font-size: 16px;
    color: ${colors.green};
`

const DeleteIcon = styled(FiTrash)`
    font-size: 16px;
    color: ${colors.red};
`

const PtAppBannerCard = ({ index, id, image, link, banners, setBanners }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const handleConfirmDelete = () => {
        axios({
            method: 'DELETE',
            url: `nhadmin/patient-app-banner-details/`,
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                let values = [...banners];
                values.splice(index, 1);
                setBanners(values);

                enqueueSnackbar('Banner deleted.', { variant: 'success' });
            } else {
                console.log('BANNER DELETE FAILED', response.status);
                enqueueSnackbar('Banner delete failed!', { variant: 'error' });
            }
        })
        .catch((error) => {
            enqueueSnackbar('Banner delete error!', { variant: 'error' });
            console.log('BANNER DELETE ERROR', error);
        })
    }

    return (
        <>
        <CardContainer className="p-2 mb-1" justify='space-between' align='center'>
            <H6>Banner-{id}</H6>
            <HBox>
                <Tooltip title='View' arrow>
                    <a className="" href={`${baseURL}${image}`} target="_blank">
                        <IconButton>
                            <ViewIcon />
                        </IconButton>
                    </a>
                </Tooltip>
                <Tooltip title='Delete' arrow>
                    <IconButton>
                        <DeleteIcon
                            className="clickable"
                            onClick={() => setOpenConfirmDelete(true)}
                        />
                    </IconButton>
                </Tooltip>
            </HBox>
        </CardContainer>

        <Confirm
            message="Are you sure to delete this banner?"
            btnColor="second"
            onConfirm={handleConfirmDelete}
            open={openConfirmDelete}
            setOpen={setOpenConfirmDelete}
        />
        </>
    )
}

export default PtAppBannerCard;