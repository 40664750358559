import { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableCell, TableFooter,
    TablePagination, TableRow, IconButton, Tooltip } from '@mui/material';
import { AiOutlineDelete } from 'react-icons/ai';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { FiPlusSquare } from 'react-icons/fi';
import styled from "styled-components";
import axios from "axios";

import { VBox, HBox } from '../../../components/Containers';
import { H3, P3 } from "../../../components/Typography";
import Confirm from '../../../layouts/Confirm';
import colors from "../../../config/colors";
import CategoryAddDlg from "./CategoryAddDlg";

const LoadingTxt = styled(P3)`
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    padding: 4px;
`

const AddIcon = styled(FiPlusSquare)`
    font-size: 24px;
    color: ${colors.blue};
`

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <HBox style={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </HBox>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const CategoryTable = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [openCategoryAdd, setOpenCategoryAdd] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);

    useEffect(() => {
        if(openCategoryAdd === false || confirmOpen === false) {
            getData();
        }
    }, [openCategoryAdd, confirmOpen])

    useEffect(() => {
        getData();
    }, [page, rowsPerPage]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);        
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'nhadmin/blog-category-list/',
            params: {
                offset: page*rowsPerPage,
                limit: page*rowsPerPage+rowsPerPage
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if(response.status===200) {
                setCategories(response.data.categories);
                setCount(response.data.count);
            }
            else {
                console.log('CATEGORY DATA FETCH FAILED');
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('CATEGORY DATA FETCH ERROR', error);
        })
    }

    const onConfirm = () => {
        axios({
            method: 'DELETE',
            url: 'nhadmin/blog-category-details/',
            params: {
                id: categoryId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if(response.status===200) {
                enqueueSnackbar('Category successfully deleted', {variant: 'success'});
            }
            else {
                console.log('CATEGORY DELET FAILED');
            }
        })
        .catch((error) => {
            console.log('CATEGORY DELETE ERROR', error);
        })
    }

    return (
        <>
            <HBox className='mt-5 mb-1' justify="space-between" align="center">
                <HBox align='center'>
                    <H3 color='second' align='left' className='mr-1'>Category Table</H3>
                    <AddIcon
                        className='clickable'
                        onClick={() => setOpenCategoryAdd(true)}
                    />
                    {isLoading && <LoadingTxt className='ml-1'>Loading...</LoadingTxt>}
                </HBox>
            </HBox>
            <VBox style={{ border: `1px solid ${colors.grey}`, borderRadius: 5 }}>
                <Table sx={{ minWidth: 500 }} size='small'>
                    <TableHead style={{ backgroundColor: colors.lightGrey }}>
                        <TableRow>
                            <TableCell className='bold' align='left'>Category Name</TableCell>
                            <TableCell className='bold' align='left'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category, index) => (
                            <TableRow key={index} style={{ height: 33 }}>
                                <TableCell align='left'>
                                    {category.name}
                                </TableCell>
                                <TableCell align='left'>
                                    <IconButton value={category.id} sx={{marginLeft: '10px'}} 
                                    onClick={(e) => {
                                                        setCategoryId(e.currentTarget.value);
                                                        setConfirmOpen(true);
                                                    }} 
                                    >
                                        <AiOutlineDelete fontSize={16} style={{ color: colors.red }}/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 33 * emptyRows }}>
                                <TableCell colSpan={8} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                colSpan={8}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </VBox>

            <CategoryAddDlg
                open={openCategoryAdd}
                setOpen={setOpenCategoryAdd}
            />

            <Confirm
                message = 'Are you sure you want to delete the category?'
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onConfirm}
                btnColor='second'
            />
        </>
    )
}

export default CategoryTable;