import { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from "notistack";

import { HBox, VBox } from "../../../components/Containers";
import { Button, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";
import CheckChoice from "../../../components/CheckChoice";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const ChangeConsultStatusDlg = ({ open, setOpen, appointIndex, appointments, setAppointments }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [statuses, setStatuses] = useState([
        {text: "No", checked: false},
        {text: "Yes", checked: false}
    ]);

    useEffect(() => {
        if (open) {
            setStatus(appointments[appointIndex].is_consulted);
        }
    }, [open]);

    const handleChangeConsultStatus = () => {
        let status = getStatus();
        let prevStatus = appointments[appointIndex].is_consulted;

        setOpen(false);
        if (status !== prevStatus) {
            enqueueSnackbar('Changing consultation status... Please wait a moment.', { persist: true });
        
            axios({
                method: 'PUT',
                url: 'nhadmin/appointment-details/',
                data: {
                    appointment_id: appointments[appointIndex].id,
                    key: 'is_consulted',
                    value: getStatus(),
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
                }
            })
            .then((response) => {
                // setIsLoading(false);
                closeSnackbar();
                if (response.status === 200) {
                    let values = [...appointments];
                    values[appointIndex].is_consulted = response.data.is_consulted;
                    setAppointments(values);
                    enqueueSnackbar('Consultation status changed.', {variant: 'success'});
                } else {
                    console.log('CONSULTATION STATUS CHANGE FAILED', response.status);
                }
            })
            .catch((error) => {
                // setIsLoading(false);
                closeSnackbar();
                enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
                console.log('CONSULTATION STATUS CHANGE ERROR', error);
            })
        }
    }

    const setStatus = (isConsulted) => {
        let values = [...statuses];

        if (isConsulted) {
            values[0].checked = false;
            values[1].checked = true;
        }
        else {
            values[0].checked = true;
            values[1].checked = false;
        }

        setStatuses(values);
    }

    const getStatus = () => {
        return statuses[1].checked;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth='xs' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Change Consultation Status</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className="p-3">
                <CheckChoice
                    items={statuses}
                    setItems={setStatuses}
                    radio={true}
                />
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleChangeConsultStatus}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default ChangeConsultStatusDlg;