import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { FiX } from "react-icons/fi";
import Collapse from '@mui/material/Collapse';

const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SnackbarProvider
                dense
                TransitionComponent={Collapse}
                transitionDuration={{enter: 0, exit: 0 }}
                // maxSnack={1}
                preventDuplicate={true}
                ref={notistackRef}
                action={(key) => (
                        <FiX className="mr-1 clickable" onClick={onClickDismiss(key)} />
                    )}
            >
                <App />
            </SnackbarProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
