import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const ResetPasswordDlg = ({open, setOpen, phoneOrEmail}) => {
    let phonePrefix = '+88';
    let navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [otp, setOtp] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [otpError, setOtpError] = useState("");
    const [password1Error, setPassword1Error] = useState("");
    const [password2Error, setPassword2Error] = useState("");

    useEffect(() => {
        const init = () => {
            setOtp("");
            setPassword1("");
            setPassword2("");

            setOtpError("");
            setPassword1Error("");
            setPassword2Error("");
        }
        if (open) init();
    }, [open]);

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const handleResetPass = () => {
        if (validate()) {
            enqueueSnackbar('Resetting password...', { persist: true });
            axios({
                method: "POST",
                url: 'auth/password-reset-check/',
                data: {
                    phone_or_email: phoneOrEmail.includes('@') ? phoneOrEmail : phonePrefix+phoneOrEmail,
                    otp: otp,
                    password1: password1,
                    password2: password1,
                }
            }).then(function (res) {
                closeSnackbar();
                if (res.status === 200) {
                    enqueueSnackbar('Password reset success.', {variant: 'success'});

                    delay(1500).then(() => navigate("/login"));
                }
                else {
                    console.log(res.data.message);
                    enqueueSnackbar(res.data.message, {variant: 'error'});
                }
            }).catch((error) => {
                console.log('PASSWORD RESET ERROR', error);
                closeSnackbar();
                if (error.response.status === 400) {
                    enqueueSnackbar(error.response.data.message, {variant: 'error'});
                }
            });
        }
    }

    const validate = () => {
        var ok = true;

        if (otp.length !== 6) {
            setOtpError("Length must be 6 digit.");
            ok = false;
        }
        else {
            setOtpError("");
        }

        if (password1.length < 8) {
            setPassword1Error("Minimum password length is eight.");
            ok = false;
        }
        else {
            setPassword1Error("");
        }

        if (password1 !== password2) {
            setPassword2Error("Passwords do not match.")
            ok = false;
        }
        else {
            setPassword2Error("");
        }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="xs" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Reset Password</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='pt-2 pb-4 px-4'>
                <P2 color='first' className='mb-2'>A 6 digit OTP has been sent. It will be valid for 5 minutes. Please enter the code below and reset your password.</P2>
                <InputText
                    label="OTP"
                    placeholder="XXXXXX"
                    value={otp}
                    helpText={otpError}
                    onChange={e => setOtp(e.target.value)}
                    autoFocus={true}
                />
                <VBox className="mt-4">
                    <InputText
                        label="New password"
                        type='password'
                        value={password1}
                        helpText={password1Error}
                        onChange={e => setPassword1(e.target.value)}
                    />
                </VBox>
                <VBox className="mt-1">
                    <InputText
                        label="Confirm new password"
                        type='password'
                        value={password2}
                        helpText={password2Error}
                        onChange={e => setPassword2(e.target.value)}
                    />
                </VBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleResetPass}
                >
                    Submit
                </Button>
            </HBox>
        </Dialog>
    )
}

export default ResetPasswordDlg;