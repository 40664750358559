import { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";
import axios from "axios";

import { HBox, VBox } from "../../../components/Containers";
import { H3, H4, H6 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const ClearBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const Select = styled.select`
    border-radius: 5px;
    width: 100%;
    height: 2.5em;
    background-color: white;
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    ::placeholder {
        color: ${colors.grey};
    }
    :focus {
        outline: 1px solid ${colors.blue};
    }
`

const BlogFilterDlg = ({ open, setOpen, ordering, setOrdering, filtering, setFiltering,
                         clearFilter }) => {
    
    const [categories, setCategories] = useState([]);
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        if (open) {
            getCategoryData();
            getAdminData();
        }
    }, [open]);
    
    const getCategoryData = () => {
        axios({
            method: 'GET',
            url: 'nhadmin/blog-category-list/',
            params: {
                get_all: true
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if(response.status===200) {
                setCategories(response.data.categories);
            }
            else {
                console.log('CATEGORY FETCH FAILED');
            }
        })
        .catch((error) => {
            console.log('CATEGORY FETCH ERROR');
        })
    }

    const getAdminData = () => {
        axios({
            method: 'GET',
            url: 'nhadmin/admin-list/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if(response.status === 200) {
                setAdmins(response.data);
            }
            else {
                console.log('ADMIN FETCH FAILED');
            }
        })
        .catch((error) => {
            console.log('ADMIN FETCH ERROR');
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }
    
    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose}>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Filtering</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                <H4 color='first'>Order by</H4>
                <table>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second'>Field</H6></td>
                        <td>
                            <Select onChange={e => setOrdering({...ordering, orderBy: e.target.value})}>
                                <option value='created_at' selected={ordering.orderBy === 'created_at'}>Created at</option>
                            </Select>
                        </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second'>Direction</H6></td>
                        <td>
                            <Select onChange={e => setOrdering({...ordering, direction: e.target.value})}>
                                <option value='' selected={ordering.direction === ''}>Ascending</option>
                                <option value='-' selected={ordering.direction === '-'}>Descending</option>
                            </Select>
                        </td>
                    </tr>
                </table>
                <H4 color='first' className="mt-2">Filter by</H4>
                <table>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second' className='mr-1'>Added By</H6></td>
                        <td>
                            <Select onChange={e => setFiltering({...filtering, addedBy: e.currentTarget.value})}>
                                <option value='' selected={filtering.addedBy === ''}>Allow all</option>
                                {admins?.map((admin)=> (
                                    <option value={admin.id} selected={filtering.addedBy === String(admin.id)}>{admin.name}</option>
                                ))}
                            </Select>
                        </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second' className='mr-1'>Category</H6></td>
                        <td>
                            <Select onChange={e => setFiltering({...filtering, category: e.currentTarget.value})}>
                                <option value='' selected={filtering.category === ''}>Allow all</option>
                                {categories?.map((category)=> (
                                    <option value={category.name} selected={filtering.category === category.name}>{category.name}</option>
                                ))}
                            </Select>
                        </td>
                    </tr>
                </table>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <ClearBtn
                    size='sm'
                    outlined
                    onClick={clearFilter}
                >
                    Clear Filter
                </ClearBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleClose}
                >
                    Done
                </Button>
            </HBox>
        </Dialog>
    );    
}

export default BlogFilterDlg;