import { useState, useEffect, forwardRef } from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter,
         TablePagination, TableRow, IconButton, Tooltip } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from "axios";
import { useSnackbar } from 'notistack';
import { FiFilter, FiPlusSquare, FiEye } from 'react-icons/fi';

import { VBox, HBox } from './../../../components/Containers';
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from '../../../components/InputText';
import colors from "../../../config/colors";
import Confirm from '../../../layouts/Confirm';
import AddReferralDlg from './AddReferralDlg';
import ReferralDetailsDlg from './ReferralDetailsDlg';

const LoadingTxt = styled(P3)`
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    padding: 4px;
`

const FilterIcon = styled(FiFilter)`
    // size: 16px;
    color: ${colors.red};
`

const AddIcon = styled(FiPlusSquare)`
    font-size: 24px;
    color: ${colors.blue};
`

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        console.log('next button', count);
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <HBox style={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </HBox>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const ReferralTable = ({  }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [searchKey, setSearchKey] = useState("");
    const [referrals, setReferrals] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState(null);
    const [inputTimeout, setInputTimeout] = useState(null);

    const [ordering, setOrdering] = useState({orderBy: 'created_at', direction: '-'});
    const [filtering, setFiltering] = useState({
        
    });

    const [isLoading, setIsLoading] = useState(false);
    const [openAddReferral, setOpenAddReferral] = useState(false);
    const [openRefDetails, setOpenRefDetails] = useState(false);
    const [selectedRefIndex, setSelectedRefIndex] = useState(null);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => {
        getData();
    }, [page, rowsPerPage]);
    useEffect(() => {
        handleSearch();
    }, [searchKey]);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);
    useEffect(() => {
        reloadData();
    }, [ordering, filtering]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'nhadmin/referral-list/',
            params: {
                key: searchKey,
                order_by: ordering.orderBy,
                direction: ordering.direction,
                offset: page*rowsPerPage,
                limit: page*rowsPerPage+rowsPerPage
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setReferrals(response.data.referrals);
                setCount(response.data.count);
            } else {
                console.log('REFERRAL LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('REFERRAL LIST FETCH ERROR', error);
        })
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearch = () => {
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setPage(0);
                    getData();
            }, 300)
        )
    }

    const reloadData = () => {
        setPage(0);
        getData();
    }

    const clearFilter = () => {
        setFiltering({
            
        });
    }

    return (
        <>
        <HBox justify="space-between" align="center" className='mb-1'>
            <HBox align='center'>
                <H3 color='second' align='left'>Referrals Table</H3>
                <AddIcon
                    className='ml-1 clickable'
                    onClick={() => setOpenAddReferral(true)}
                />
                {isLoading && <LoadingTxt className='ml-1'>Loading...</LoadingTxt>}
            </HBox>
            <HBox align='center'>
                {/* <Tooltip title='Filtering' arrow>
                    <IconButton onClick={() => setOpenFilterBy(true)}>
                        <FilterIcon />
                    </IconButton>
                </Tooltip> */}
                <InputText
                    className='ml-1'
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                    placeholder="Search for a keyword..."
                />
            </HBox>
        </HBox>
        <VBox style={{ border: `1px solid ${colors.grey}`, borderRadius: 5 }}>
            <Table sx={{ minWidth: 500 }} size='small'>
                <TableHead style={{ backgroundColor: colors.lightGrey }}>
                    <TableRow>
                        <TableCell className='bold'>Name</TableCell>
                        <TableCell className='bold'>Code</TableCell>
                        <TableCell className='bold'>Total Referrals</TableCell>
                        <TableCell className='bold'>Balance</TableCell>
                        <TableCell className='bold'>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {referrals.map((referral, index) => (
                        <TableRow key={referral.id} style={{ height: 33 }}>
                            <TableCell>
                                {referral.name}
                            </TableCell>
                            <TableCell>
                                {referral.code}
                            </TableCell>
                            <TableCell>
                                {referral.ref_count}
                            </TableCell>
                            <TableCell>
                                {referral.balance}
                            </TableCell>
                            <TableCell style={{ width: 150 }}>
                                <Tooltip title='View Details' arrow>
                                    <IconButton>
                                        <FiEye
                                            size={16}
                                            color={colors.blue}
                                            onClick={() => {setSelectedRefIndex(index); setOpenRefDetails(true)}}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                            <TableCell colSpan={5} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            colSpan={5}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>

            <AddReferralDlg
                open={openAddReferral}
                setOpen={setOpenAddReferral}
                reloadData={reloadData}
            />

            <ReferralDetailsDlg
                open={openRefDetails}
                setOpen={setOpenRefDetails}
                referralId={referrals[selectedRefIndex]?.id}
            />
        </VBox>
        </>
    );
}

export default ReferralTable;