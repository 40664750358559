import { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from "notistack";

import { HBox, VBox } from "../../../components/Containers";
import { Button, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, P1, P2 } from "../../../components/Typography";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";
import CheckChoice from "../../../components/CheckChoice";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const ChangeDoctorStatus = ({ open, setOpen, doctorIndex, doctors, setDoctors }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [statuses, setStatuses] = useState([
        {text: "Draft", checked: false},
        {text: "On Hold", checked: false},
        {text: "Onboarded", checked: false},
    ]);

    useEffect(() => {
        if (open) {
            setStatus(doctors[doctorIndex].onboarding_status);
        }
    }, [open]);

    const handleChangeStatus = () => {
        enqueueSnackbar("Changing doctor status...", { persist: true });
        setOpen(false);
        axios({
            method: 'PUT',
            url: 'nhadmin/doctor-details/',
            data: {
                doctor_id: doctors[doctorIndex].id,
                key: 'onboarding_status',
                value: getStatus()
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            // setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                let values = [...doctors];
                values[doctorIndex].onboarding_status = response.data.onboarding_status;
                setDoctors(values);
                enqueueSnackbar('Doctor status changed.', {variant: 'success'});
            } else {
                console.log('DOCTOR STATUS CHANGE FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
            console.log('DOCTOR STATUS CHANGE ERROR', error);
        })
    }

    const setStatus = (status) => {
        let values = [...statuses];
        for (let i=0; i<statuses.length; i++) {
            if (statuses[i].text === status) {
                values[i].checked = true;
            }
            else {
                values[i].checked = false;
            }
        }
        setStatuses(values);
    }

    const getStatus = () => {
        for (let i=0; i<statuses.length; i++) {
            if (statuses[i].checked) return statuses[i].text;
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth='xs' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Change Status</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className="p-3">
                <CheckChoice
                    items={statuses}
                    setItems={setStatuses}
                    radio={true}
                />
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleChangeStatus}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default ChangeDoctorStatus;