import styled from "styled-components";

import { P2 } from "../components/Typography";
import colors from "../config/colors";

const Container = styled.div`
    height: 40px;
    width: 100%;
    position: sticky;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightGrey};
    border-top: 1px solid ${colors.grey};
`

const Footer = () => {
    let getYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    };

    return (
        <Container>
            <P2>Copyright © {getYear()} NextGen DigiTech. All rights reserved.</P2>
        </Container>
    )
}

export default Footer;