import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const AddChamberDlg = ({doctorId, open, setOpen, chambers, setChambers}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [address, setAddress] = useState("");

    const [addrError, setAddrError] = useState("");

    useEffect(() => {
        const init = () => {
            setAddress("");

            setAddrError("");
        }
        if (open) init();
    }, [open]);

    const handleSave = () => {
        if (validate()) {
            axios({
                method: "POST",
                url: 'nhadmin/chamber-list/',
                data: {
                    doctor_id: doctorId,
                    address: address
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            }).then(function (res) {
                if (res.status === 201) {
                    setChambers([res.data, ...chambers]);

                    enqueueSnackbar('Chamber add success.', { variant: 'success' });
                    setOpen(false);
                }
                else {
                    console.log('CHAMBER CREATE FAILED');
                    enqueueSnackbar('Chamber add failed!', { variant: 'error' });
                }
            }).catch((error) => {
                console.log('CHAMBER CREATE ERROR', error);
                enqueueSnackbar('Chamber add error!', { variant: 'error' });
            });
        }
    }

    const validate = () => {
        var ok = true;
        if (address) {
            setAddrError("");
        }
        else {
            setAddrError("This field cannot be kept blank.");
            ok = false;
        }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Add Chamber</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                <HBox className='mb-1'>
                    <InputText
                        style={{ width: '100%' }}
                        className="mr-0_5"
                        label="Address*"
                        type='text'
                        value={address}
                        helpText={addrError}
                        onChange={e => setAddress(e.target.value)}
                        autoFocus={true}
                    />
                </HBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleSave}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default AddChamberDlg;