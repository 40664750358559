const colors = {
    veryLightBlue: '#e7f8fd',
    lightBlue: '#7BD9F5',
    blue: '#13B0DD',
    darkBlue: '#1097bc',
    veryLightRed: '#FAF8F8',
    red: '#C75738',
    darkRed: '#9F462D',
    veryLightGreen: '#F8FAF8',
    green: '#6baf50',
    darkGreen: '#568c40',
    veryLightPurple: '#F8F4FC',
    purple: '#9158a7',
    darkPurple: '#744686',
    veryLightGrey: 'rgb(253,253,253)',
    lightGrey: '#FAFAFA',
    grey: '#DDDDDD',
    lessDarkGrey: '#8c8c8c',
    // darkGrey: '#5C5C5C',
    darkGrey: '#262626',
    shadow: 'rgba(0,0,0,0.25)',
}

export default colors;