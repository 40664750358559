import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";
import { getDate } from '../../../utils';

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const ReferralDetailsDlg = ({ referralId, open, setOpen }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [referralDetails, setReferralDetails] = useState({});

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open]);

    const getData = () => {
        enqueueSnackbar("Loading referral details...", { persist: true });
        axios({
            method: 'GET',
            url: 'nhadmin/referral-details/',
            params: {
                id: referralId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setReferralDetails(response.data);
            } else {
                console.log('REFERRAL DETAILS FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('REFERRAL DETAILS FETCH ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <>
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Referral Details</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-4'>
                <table>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Referral ID</P2></td>
                        <td><P2 className='bold'>{referralDetails.id}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Status</P2></td>
                        <td>
                            <P2
                                className='bold'
                                color={referralDetails.is_active ? 'third' : 'second'}
                            >
                                {referralDetails.is_active ? 'Active' : 'Inactive'}
                            </P2>
                        </td>
                    </tr>
                    <tr>
                        <td><P2>Name</P2></td>
                        <td><P2>{referralDetails.name}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Address</P2></td>
                        <td><P2>{referralDetails.address}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Payment Method</P2></td>
                        <td><P2>{referralDetails.payment_method}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Account Number</P2></td>
                        <td><P2>{referralDetails.account_number}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Code</P2></td>
                        <td><P2>{referralDetails.code}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Pt. Discount</P2></td>
                        <td><P2>{referralDetails.discount_type==='Amount' ? `BDT ${referralDetails.pt_discount}` : `${referralDetails.pt_discount}%`}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Ref. Bonus</P2></td>
                        <td><P2>{referralDetails.bonus_type==='Amount' ? `BDT ${referralDetails.ref_bonus}` : `${referralDetails.ref_bonus}%`}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Expire Date</P2></td>
                        <td><P2>{getDate(referralDetails.expire_date, 'long')}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Amount Earned</P2></td>
                        <td><P2>{referralDetails.amount_earned}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Amount Paid</P2></td>
                        <td><P2>{referralDetails.amount_paid}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Balance</P2></td>
                        <td><P2>{referralDetails.balance}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Ref. Count</P2></td>
                        <td><P2>{referralDetails.ref_count}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Created At</P2></td>
                        <td><P2>{moment(referralDetails.created_at).format('LLL')}</P2></td>
                    </tr>
                </table>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
            </HBox>
        </Dialog>
        </>
    )
}

export default ReferralDetailsDlg;