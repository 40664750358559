import styled from "styled-components";
import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from "../../../components/InputText";
import Select from "../../../components/Select";
import { Button, IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";
import { logLevelData, eventLogCategoryData } from "../../../data";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const ClearBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const EventLogFilterDlg = ({ open, setOpen, ordering, setOrdering, filtering, setFiltering, clearFilter }) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose}>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Filtering</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                <H4 color='first'>Order by</H4>
                <table>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second'>Field</H6></td>
                        <td>
                            <Select onChange={e => setOrdering({...ordering, orderBy: e.target.value})}>
                                {/* <option value='user_id' selected={ordering.orderBy === 'user_id'}>ID</option> */}
                                <option value='created_at' selected={ordering.orderBy === 'created_at'}>Created at</option>
                            </Select>
                        </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second'>Direction</H6></td>
                        <td>
                            <Select onChange={e => setOrdering({...ordering, direction: e.target.value})}>
                                <option value='' selected={ordering.direction === ''}>Ascending</option>
                                <option value='-' selected={ordering.direction === '-'}>Descending</option>
                            </Select>
                        </td>
                    </tr>
                </table>
                <H4 color='first' className="mt-2">Filter by</H4>
                <table>
                    <tr style={{ height: 45 }}>
                        <td><H6 color='second'>User ID</H6></td>
                        <td>
                            <InputText
                                type='number'
                                placeholder='Write a number'
                                value={filtering.userId}
                                onChange={e => setFiltering({...filtering, userId: e.target.value})}
                            />
                        </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second' className='mr-1'>Log Level</H6></td>
                        <td>
                            <Select onChange={e => setFiltering({...filtering, logLevel: e.target.value})}>
                                <option value="" selected={filtering.logLevel === ''}>Allow all</option>
                                {logLevelData.map((logLevel, index) => (
                                    <option value={logLevel} selected={filtering.logLevel === logLevel}>{logLevel}</option>
                                ))}
                            </Select>
                        </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second'>Category</H6></td>
                        <td>
                            <Select onChange={e => setFiltering({...filtering, category: e.target.value})}>
                                <option value="" selected={filtering.category === ''}>Allow all</option>
                                {eventLogCategoryData.map((category, index) => (
                                    <option value={category} selected={filtering.category === category}>{category}</option>
                                ))}
                            </Select>
                        </td>
                    </tr>
                </table>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <ClearBtn
                    size='sm'
                    outlined
                    onClick={clearFilter}
                >
                    Clear Filter
                </ClearBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleClose}
                >
                    Done
                </Button>
            </HBox>
        </Dialog>
    )
}

export default EventLogFilterDlg;