import styled from "styled-components";
import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from "../../../components/InputText";
import Select from "../../../components/Select";
import { Button, IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";
import { appointmentTypeData, specialtiesData,
         paymentStatusData } from "../../../data";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const ClearBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const AppointmentFilterDlg = ({ open, setOpen, filtering, setFiltering, clearFilter }) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose}>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Filter by</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                {/* <H4 color='first' className="">Filter by</H4> */}
                <table>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second' className="mr-1">Appointment Type</H6></td>
                        <td>
                            <Select onChange={e => setFiltering({...filtering, type: e.target.value})}>
                                <option value="" selected={filtering.type === ''}>Allow all</option>
                                {appointmentTypeData.map((type, index) => (
                                    <option value={type} selected={filtering.type === type}>{type}</option>
                                ))}
                            </Select>
                        </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second'>Consulted?</H6></td>
                        <td>
                            <Select onChange={e => setFiltering({...filtering, isConsulted: e.target.value})}>
                                <option value={'[1,0]'} selected={filtering.isConsulted === '[1,0]'}>Allow all</option>
                                <option value={'[1]'} selected={filtering.isConsulted === '[1]'}>Yes</option>
                                <option value={'[0]'} selected={filtering.isConsulted === '[0]'}>No</option>
                            </Select>
                        </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second'>Specialty</H6></td>
                        <td>
                            <Select onChange={e => setFiltering({...filtering, specialty: e.target.value})}>
                                <option value="" selected={filtering.specialty === ''}>Allow all</option>
                                {specialtiesData.map((specialty, index) => (
                                    <option value={specialty} selected={filtering.specialty === specialty}>{specialty}</option>
                                ))}
                            </Select>
                        </td>
                    </tr>
                    <tr style={{ height: 40 }}>
                        <td><H6 color='second' className="mr-1">Payment Status</H6></td>
                        <td>
                            <Select onChange={e => setFiltering({...filtering, paymentStatus: e.target.value})}>
                                <option value="" selected={filtering.paymentStatus === ''}>Allow all</option>
                                {paymentStatusData.map((status, index) => (
                                    <option value={status} selected={filtering.paymentStatus === status}>{status}</option>
                                ))}
                            </Select>
                        </td>
                    </tr>
                    
                </table>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <ClearBtn
                    size='sm'
                    outlined
                    onClick={clearFilter}
                >
                    Clear Filter
                </ClearBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleClose}
                >
                    Done
                </Button>
            </HBox>
        </Dialog>
    )
}

export default AppointmentFilterDlg;