import { useState, useCallback, useRef, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX, FiPlus } from "react-icons/fi";
import { BiImageAdd } from "react-icons/bi";
import axios from "axios";
import { useSnackbar } from 'notistack';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const BrowseContainer = styled(VBox)`
    height: 40vh;
`

const UploadIcon = styled(BiImageAdd)`
    color: ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const PictureUploadDlg = ({ doctorId, open, setOpen, profile, setProfile }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const inputRef = useRef(null);
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const canvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const handleClickBrowse = event => {
        inputRef.current.click();
    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        setUpImg(null);
        setCrop({ unit: '%', width: 30, aspect: 1 });
    }, [open]);

    useEffect(() => {
        if (!completedCrop || !canvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = canvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    const uploadPicture = (canvas, crop) => {
        if (!crop || !canvas) {
            return;
        }
    
        canvas.toBlob(
            (blob) => {
                var formData = new FormData();
                formData.append('image', blob, profile.name);
                formData.append('doctor_id', doctorId);
                
                axios({
                    method: "PUT",
                    url: `nhadmin/image/`,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                    },
                }).then(function (res) {
                    if (res.status === 200) {
                        setProfile({...profile, image: res.data});
                        setOpen(false);

                        enqueueSnackbar('Profile image updated.', {variant: 'success'});
                    }
                    else {
                        console.log('PROFILE IMAGE UPDATE FAILED', res.status);
                        enqueueSnackbar('Profile image update failure!', {variant: 'error'});
                    }
                }).catch((error) => {
                    console.log('PROFILE IMAGE UPDATE ERROR', error);
                    enqueueSnackbar('Profile image update error!', {variant: 'error'});
                });
            }
        );
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="xs" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Update Profile Photo</H3>
                <IconButton
                    className="mr-2"
                    onClick={() => setOpen(false)}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            {upImg ?
                <VBox>
                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                    <canvas
                        hidden
                        ref={canvasRef}
                        
                        style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0)
                        }}
                    />
                    <HBox justify="flex-end" className='px-2 py-1'>
                        <CloseBtn size='sm' outlined onClick={() => setOpen(false)}>Close</CloseBtn>
                        <Button
                            size='sm'
                            color='first'
                            className='ml-1'
                            onClick={() => uploadPicture(canvasRef.current, completedCrop)}
                        >
                            Save
                        </Button>
                    </HBox>
                </VBox>
                :
                <BrowseContainer align='center' justify='center'>
                    <UploadIcon fontSize={64} className='' />
                    <Button color='first' size='sm' className='mt-2' onClick={handleClickBrowse}>
                        Browse Photo
                        <input
                            type="file"
                            accept="image/*"
                            ref={inputRef}
                            onChange={onSelectFile}
                            hidden
                        />
                    </Button>
                    
                </BrowseContainer>
            }
        </Dialog>
    );
}

export default PictureUploadDlg;