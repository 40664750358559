import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { Scrollbars } from 'react-custom-scrollbars';
import { FiPlusSquare } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { HBox, VBox, LeftPanel, CenterPanel, RightPanel } from "../../components/Containers";
import { Button, NavBtnActive, NavBtn } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import colors from "../../config/colors";
import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import AddReferralDlg from './components/AddReferralDlg';
import ReferralTable from './components/ReferralTable';

const SLink = styled(Link)`
    text-decoration: none;
`

const Referrals = () => {
    return (
        <>
            <LowerNav selected='promotions' />
            <HBox>
                <LeftPanel align="center">
                    <SLink to='/vouchers' className='mt-8'>
                        <NavBtn size="sm" color="first" className="" outlined>
                            <div className="ml-1">Vouchers</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">Referrals</div>
                    </NavBtnActive>
                    <SLink to='/promotion-banners' className='mt-1'>
                        <NavBtn size="sm" color="first" className="" outlined>
                            <div className="ml-1">Banners</div>
                        </NavBtn>
                    </SLink>
                </LeftPanel>
                <CenterPanel>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 50px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='p-4'>
                            <ReferralTable />
                        </VBox>
                    </Scrollbars>
                </CenterPanel>
                <RightPanel />
            </HBox>
            <Footer />
        </>
    )
}

export default Referrals;