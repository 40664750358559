import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";
import { getDate, getTime } from '../../../utils';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Image = styled.img`
    height: 100px;
    width: 100px;
    border-radius: 50px;
    border: 1px solid ${colors.grey};
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const PatientDetailsDlg = ({ patientId, open, setOpen }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [patient, setPatient] = useState({});

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open]);

    const getData = () => {
        enqueueSnackbar("Loading appointment details...", { persist: true });
        axios({
            method: 'GET',
            url: 'nhadmin/patient-details/',
            params: {
                id: patientId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setPatient(response.data);
            } else {
                console.log('PATIENT DETAILS FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('PATIENT DETAILS FETCH ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <>
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Patient Details</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-4'>
                <table>
                    <tr>
                        <td>
                            <Image
                                className='mb-2'
                                src={`${baseURL}${patient.image}`}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "/images/noImage.svg";
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Patient ID</P2></td>
                        <td><P2 className=''>{patient.id}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>User ID</P2></td>
                        <td><P2 className=''>{patient.user_id}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Name</P2></td>
                        <td><P2 className='bold'>{patient.full_name}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Gender</P2></td>
                        <td><P2 className=''>{patient.gender ? patient.gender : 'Not given'}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Date of Birth</P2></td>
                        <td><P2 className=''>{patient.date_of_birth ? getDate(patient.date_of_birth, 'long') : 'Not given'}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Address</P2></td>
                        <td><P2 className=''>{patient.address ? patient.address : 'Not given'}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Phone</P2></td>
                        <td><P2 className=''>{patient.phone}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Email</P2></td>
                        <td><P2 className=''>{patient.email ? patient.email : 'Not given'}</P2></td>
                    </tr>

                    <tr>
                        <td style={{ width: '190px' }}><P2 className='mt-2'>Total Appointments</P2></td>
                        <td><P2 className='mt-2'>{patient.appointment_count}</P2></td>
                    </tr>
                    {patient.appointment_count ?
                        <>
                        <tr>
                            <td style={{ width: '190px' }}><P2>Last Appointment</P2></td>
                            <td><P2 className='bold'>{patient?.last_appointment?.doctor_name}</P2></td>
                        </tr>
                        <tr>
                            <td style={{ width: '190px' }}><P2>Specialty</P2></td>
                            <td><P2 className='' color='second'>{patient?.last_appointment?.doctor_specialty}</P2></td>
                        </tr>
                        <tr>
                            <td style={{ width: '190px' }}><P2>Appointment Time</P2></td>
                            <td><P2 className=''>{patient?.last_appointment?.date ? getDate(patient?.last_appointment?.date, 'long') : ''} ({patient?.last_appointment?.start_time ? getTime(patient?.last_appointment?.start_time) : ''})</P2></td>
                        </tr>
                        </>
                        :
                        null
                    }

                    <tr>
                        <td style={{ width: '190px' }}><P2 className='mt-2'>Added At</P2></td>
                        <td><P2 className='mt-2'>{moment(patient.created_at).format('LLL')}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Edited At</P2></td>
                        <td><P2 className=''>{moment(patient.edited_at).format('LLL')}</P2></td>
                    </tr>
                </table>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
            </HBox>
        </Dialog>
        </>
    )
}

export default PatientDetailsDlg;