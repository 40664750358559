import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';

import './App.css';
import { UpperNav } from './layouts/Navbar';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import PasscodeVerification from './pages/PasscodeVerification';
import Dashboard from './pages/Dashboard';
import Appointments from './pages/Appointments';
import Doctors from './pages/Doctors';
import Patients from './pages/Patients';
import DrugDatabase from './pages/DrugDatabase';
import DiagnosticDatabase from './pages/DiagnosticDatabase';
import InstitutionDatabase from './pages/InstitutionDatabase';
import EventLogs from './pages/EventLogs';
import PromotionBanners from './pages/PromotionBanners';
import Vouchers from './pages/Vouchers';
import Referrals from './pages/Referrals';
import Blogs from './pages/Blogs';
import BlogCategories from './pages/BlogCategories';
import { checkAuthentication } from './services/actions/authAction';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

function App({ isAuthenticated, isVerified, checkAuthentication }) {
    useEffect(() => {
        checkAuthentication();
    }, []);

    let initPath;
    if (!isAuthenticated) {
        initPath = '/login';
    }
    else if (!isVerified && isAuthenticated) {
        initPath = '/passcode-verification';
    }
    else {
        initPath = '/appointments';
    }

    return (
        <BrowserRouter>
            {isAuthenticated && <UpperNav />}
            <Routes>
                <Route path="/" element={<Navigate to={initPath} />} />
                <Route path='login' element={<Login />} />
                <Route path='/forget-password' element={<ForgetPassword />} />
                {isAuthenticated && <Route path='passcode-verification' element={<PasscodeVerification />} />}
                {isAuthenticated && <Route path='dashboard' element={<Dashboard />} />}
                {isAuthenticated && <Route path='appointments' element={<Appointments />} />}
                {isAuthenticated && <Route path='doctors' element={<Doctors />} />}
                {isAuthenticated && <Route path='patients' element={<Patients />} />}
                {isAuthenticated && <Route path='drug-database' element={<DrugDatabase />} />}
                {isAuthenticated && <Route path='diagnostic-database' element={<DiagnosticDatabase />} />}
                {isAuthenticated && <Route path='institution-database' element={<InstitutionDatabase />} />}
                {isAuthenticated && <Route path='event-logs' element={<EventLogs />} />}
                {isAuthenticated && <Route path='promotion-banners' element={<PromotionBanners />} />}
                {isAuthenticated && <Route path='vouchers' element={<Vouchers />} />}
                {isAuthenticated && <Route path='referrals' element={<Referrals />} />}
                {isAuthenticated && <Route path='blogs' element={<Blogs />} />}
                {isAuthenticated && <Route path='blog-categories' element={<BlogCategories />} />}
            </Routes>
        </BrowserRouter>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isVerified: state.auth.isVerified,
});

export default connect(mapStateToProps, { checkAuthentication })(App);
