import styled from "styled-components";

import { VBox, HBox } from "../../components/Containers";
import colors from "../../config/colors";
import Form from "./components/Form";
import Footer from "../../layouts/Footer";

const Container = styled(VBox)`
    height: 100vh;
    background-color: ${colors.lightGrey};
`

const ForgetPassword = () => {
    return (
        <>
            <Container justify="center" align="center">
                <Form />
            </Container>
            {/* <Footer /> */}
        </>
    )
}

export default ForgetPassword;