import { useState, useEffect, forwardRef } from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
    Table, TableHead, TableBody, TableCell, TableFooter,
    TablePagination, TableRow, IconButton, Tooltip
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from "axios";
import { useSnackbar } from 'notistack';
import {
    FiEye, FiFilter, FiDollarSign,
    FiFileText, FiAlignLeft, FiDownload,
    FiTrash
} from 'react-icons/fi';
import moment from 'moment';

import { VBox, HBox } from '../../../components/Containers';
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from '../../../components/InputText';
import { Button } from '../../../components/Buttons';
import colors from "../../../config/colors";
import { getTime, getDate } from '../../../utils';
import ChangePaymentStatusDlg from './ChangePaymentStatusDlg';
import ChangeConsultStatusDlg from './ChangeConsultStatusDlg';
import AppointmentDetailsDlg from './AppointmentDetailsDlg';
import AppointmentLogDlg from './AppointmentLogDlg';
import AppointmentFilterDlg from './AppointmentFilterDlg';
import PrescriptionPDF from '../../../layouts/PrescriptionPDF';
import Confirm from '../../../layouts/Confirm';

//const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const colorProps = {
    Pending: '#ff6600',
    Paid: colors.green,
    Refunded: colors.darkGrey,
    Holded: colors.red,
    Yes: colors.green,
    No: colors.red,
    default: colors.darkGrey,
};

const LoadingTxt = styled(P3)`
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    padding: 4px;
`

const StatusChip = styled(HBox)`
    padding: 2px 5px;
    border-radius: 5px;
    color: white;
    background-color: ${props => colorProps[props.status || 'default']};
    max-width: max-content;
`

const FilterIcon = styled(FiFilter)`
    // size: 16px;
    color: ${colors.red};
`

const OnlineIcon = styled.div`
    background-color: ${colors.green};
    height: 8px;
    width: 8px;
    border-radius: 4px;
`

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        console.log('next button', count);
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <HBox style={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </HBox>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const AppointmentTable = ({ }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [searchKey, setSearchKey] = useState("");
    const [appointments, setAppointments] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState(null);
    const [inputTimeout, setInputTimeout] = useState(null);

    const [openAppointLog, setOpenAppointLog] = useState(false);
    const [openAppointDetails, setOpenAppointDetails] = useState(false);
    const [openChangePayStatus, setOpenChangePayStatus] = useState(false);
    const [openChangeConsultStatus, setOpenChangeConsultStatus] = useState(false);
    const [openPres, setOpenPres] = useState(false);
    const [openFilterBy, setOpenFilterBy] = useState(false);
    const [openConfirmCancelAppoint, setOpenConfirmCancelAppoint] = useState(false);
    const [selectedAppointIndex, setSelectedAppointIndex] = useState(null);

    const [filtering, setFiltering] = useState({
        type: '', isConsulted: '[1,0]',
        specialty: '', paymentStatus: ''
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => {
        getData();
    }, [page, rowsPerPage]);
    useEffect(() => {
        handleSearch();
    }, [searchKey]);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);
    useEffect(() => {
        reloadData();
    }, [filtering]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'nhadmin/appointment-list/',
            params: {
                key: searchKey,
                type: filtering.type,
                is_consulted: filtering.isConsulted,
                specialty: filtering.specialty,
                payment_status: filtering.paymentStatus,
                offset: page * rowsPerPage,
                limit: page * rowsPerPage + rowsPerPage
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
            .then((response) => {
                setIsLoading(false);
                closeSnackbar();
                if (response.status === 200) {
                    setAppointments(response.data.appointments);
                    setCount(response.data.count);
                } else {
                    console.log('APPOINTMENT LIST FETCH FAILED', response.status);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                closeSnackbar();
                console.log('APPOINTMENT LIST FETCH ERROR', error);
            })
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearch = () => {
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setPage(0);
                getData();
            }, 300)
        )
    }

    const reloadData = () => {
        setPage(0);
        getData();
    }

    const clearFilter = () => {
        setFiltering({
            type: '', isConsulted: '[1,0]',
            specialty: '', paymentStatus: ''
        });
    }

    const handleDownloadPayReceipt = (appointmentId) => {
        enqueueSnackbar('Preparing to download... Please wait a moment.', { persist: true });
        axios({
            method: 'GET',
            url: 'nhadmin/download-payment-receipt/',
            params: {
                appointment_id: appointmentId,
            },
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
            .then((response) => {
                closeSnackbar();
                if (response.status === 200) {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `payment_receipt_${appointmentId}_${moment().format('YYYY-MM-DD')}.pdf`);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } else {
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    console.log('PAYMENT RECEIPT DOWNLOAD FAILED', response.status);
                }
            })
            .catch((error) => {
                closeSnackbar();
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                console.log('PAYMENT RECEIPT DOWNLOAD ERROR', error);
            })
    }

    const handleExportAppointmentsExcel = () => {
        enqueueSnackbar('Preparing to download... Please wait a moment.', { persist: true });
        axios({
            method: 'GET',
            url: 'nhadmin/appointments-export-excel/',
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
            .then((response) => {
                closeSnackbar();
                if (response.status === 200) {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `appointments_${moment().format('YYYY-MM-DD')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } else {
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                    console.log('APPOINTMENTS EXPORT TO EXCEL FAILED', response.status);
                }
            })
            .catch((error) => {
                closeSnackbar();
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                console.log('APPOINTMENTS EXPORT TO EXCEL ERROR', error);
            })
    }

    const handleConfirmCancelAppoint = () => {
        axios({
            method: 'DELETE',
            url: 'nhadmin/appointment-details/',
            params: {
                appointment_id: appointments[selectedAppointIndex].id,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    let values = [...appointments];
                    values.splice(selectedAppointIndex, 1);
                    setAppointments(values);

                    enqueueSnackbar('Appointment cancelled.', { variant: 'success' });
                } else {
                    console.log('APPOINTMENT DELETE FAILED', response.status);
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                }
            })
            .catch((error) => {
                console.log('APPOINTMENT DELETE ERROR', error);
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            })
    }

    return (
        <>
            <HBox justify="space-between" align="center" className='mb-1'>
                <HBox align='center'>
                    <H3 color='second' align='left'>Appointments Table</H3>
                    <Button
                        className='ml-1'
                        size='xs'
                        color='first'
                        outlined
                        onClick={handleExportAppointmentsExcel}
                        style={{ paddingLeft: 8, paddingRight: 8 }}
                    >
                        <FiDownload className='mr-0_5' />
                        Excel
                    </Button>
                    {isLoading && <LoadingTxt className='ml-1'>Loading...</LoadingTxt>}
                </HBox>
                <HBox align='center'>
                    <Tooltip title='Filtering' arrow>
                        <IconButton onClick={() => setOpenFilterBy(true)}>
                            <FilterIcon />
                        </IconButton>
                    </Tooltip>
                    <InputText
                        className='ml-1'
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        placeholder="Search for a keyword..."
                    />
                </HBox>
            </HBox>
            <VBox style={{ border: `1px solid ${colors.grey}`, borderRadius: 5 }}>
                <Table sx={{ minWidth: 500 }} size='small'>
                    <TableHead style={{ backgroundColor: colors.lightGrey }}>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Consulted?</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Type</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Specialty</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Doctor Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Patient Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Start Time</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Payment Status</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Pay Amount (৳)</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appointments.map((appointment, index) => (
                            <TableRow key={appointment.id} style={{ height: 33 }}>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                                    {appointment.id}
                                </TableCell>
                                <TableCell>
                                    <StatusChip
                                        className='clickable'
                                        status={appointment.is_consulted ? 'Yes' : 'No'}
                                        onClick={() => { setSelectedAppointIndex(index); setOpenChangeConsultStatus(true) }}
                                    >
                                        {appointment.is_consulted ? 'Yes' : 'No'}
                                    </StatusChip>
                                </TableCell>
                                {/* <TableCell>
                                <P2 color={appointment.is_consulted ? 'third' : 'second'}>{appointment.is_consulted ? "Yes" : "No"}</P2>
                            </TableCell> */}
                                <TableCell>
                                    <P2 color={appointment.type === 'New' ? 'first' : 'third'}>{appointment.type}</P2>
                                </TableCell>
                                <TableCell>
                                    <P2 color='second'>{appointment.doctor_specialty}</P2>
                                </TableCell>
                                <TableCell>
                                    <HBox align='center'>
                                        <P2 className='bold'>{appointment.doctor_name}</P2>
                                        {appointment.doctor_is_online && <Tooltip title='Online' arrow><OnlineIcon className='ml-0_5' /></Tooltip>}
                                    </HBox>
                                </TableCell>
                                <TableCell>
                                    <HBox align='center'>
                                        <P2 className='bold'>{appointment.patient_name}</P2>
                                        {appointment.patient_is_online && <Tooltip title='Online' arrow><OnlineIcon className='ml-0_5' /></Tooltip>}
                                    </HBox>
                                </TableCell>
                                <TableCell>
                                    <P2>{getDate(appointment.date, 'medium')}</P2>
                                </TableCell>
                                <TableCell>
                                    <P2>{getTime(appointment.start_time)}</P2>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Change Status" arrow>
                                        <StatusChip
                                            status={`${appointment.payment_status}`}
                                            className='clickable'
                                            onClick={() => { setSelectedAppointIndex(index); setOpenChangePayStatus(true) }}
                                        >
                                            {appointment.payment_status}
                                        </StatusChip>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <P2>{appointment.payment_status === 'Paid' ? appointment.pay_amount : 'N/A'}</P2>
                                </TableCell>
                                <TableCell style={{ width: 150 }}>
                                    <Tooltip title='Details' arrow>
                                        <IconButton>
                                            <FiEye
                                                fontSize={16}
                                                style={{ color: colors.blue }}
                                                onClick={() => { setSelectedAppointIndex(index); setOpenAppointDetails(true) }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Log' arrow>
                                        <IconButton>
                                            <FiAlignLeft
                                                fontSize={16}
                                                style={{ color: colors.purple }}
                                                onClick={() => { setSelectedAppointIndex(index); setOpenAppointLog(true) }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    {appointment.payment_status === 'Paid' &&
                                        <Tooltip title='Download Payment Receipt' arrow>
                                            <IconButton>
                                                <FiDollarSign
                                                    fontSize={16}
                                                    style={{ color: colors.green }}
                                                    onClick={() => handleDownloadPayReceipt(appointment.id)}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {appointment.is_consulted === true &&
                                        <Tooltip title='Prescription' arrow>
                                            <IconButton>
                                                <FiFileText
                                                    fontSize={16}
                                                    style={{ color: colors.blue }}
                                                    onClick={() => { setSelectedAppointIndex(index); setOpenPres(true) }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {appointment.payment_status === 'Pending' &&
                                        <Tooltip title='Cancel Appointment' arrow>
                                            <IconButton>
                                                <FiTrash
                                                    fontSize={16}
                                                    style={{ color: colors.red }}
                                                    onClick={() => { setSelectedAppointIndex(index); setOpenConfirmCancelAppoint(true) }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 33 * emptyRows }}>
                                <TableCell colSpan={11} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                colSpan={11}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </VBox>

            <ChangeConsultStatusDlg
                open={openChangeConsultStatus}
                setOpen={setOpenChangeConsultStatus}
                appointIndex={selectedAppointIndex}
                appointments={appointments}
                setAppointments={setAppointments}
            />
            <ChangePaymentStatusDlg
                open={openChangePayStatus}
                setOpen={setOpenChangePayStatus}
                appointIndex={selectedAppointIndex}
                appointments={appointments}
                setAppointments={setAppointments}
            />
            <AppointmentDetailsDlg
                open={openAppointDetails}
                setOpen={setOpenAppointDetails}
                appointmentId={appointments[selectedAppointIndex]?.id}
            />
            <AppointmentLogDlg
                open={openAppointLog}
                setOpen={setOpenAppointLog}
                appointmentId={appointments[selectedAppointIndex]?.id}
            />
            <AppointmentFilterDlg
                open={openFilterBy}
                setOpen={setOpenFilterBy}
                filtering={filtering}
                setFiltering={setFiltering}
                clearFilter={clearFilter}
            />
            <PrescriptionPDF
                open={openPres}
                setOpen={setOpenPres}
                prescriptionId={appointments[selectedAppointIndex]?.prescription_id}
            />
            <Confirm
                message='Are you sure to cancel the appointment?'
                open={openConfirmCancelAppoint}
                setOpen={setOpenConfirmCancelAppoint}
                onConfirm={handleConfirmCancelAppoint}
                btnColor='second'
            />
        </>
    );
}

export default AppointmentTable;