import { useState, useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiPlus, FiCalendar, FiX, FiTrash } from "react-icons/fi";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import DayPicker from 'react-day-picker';
import { useSnackbar } from "notistack";
import { Dialog, Tooltip } from "@mui/material";

import { InputText } from "../../../components/InputText";
import { Button, NavBtnActive, NavBtn, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../../components/Containers";
import Confirm from "../../../layouts/Confirm";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { getTime, getDate } from "../../../utils";
import SearchPatientDlg from "./SearchPatientDlg";
import './day_picker.css';

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const DatePanelMobile = styled(HBox)`

`

const Paper = styled.div`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const LoadingChip = styled(HBox)`
    border: 1px solid ${colors.grey};
    border-radius: 5px;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const SlotContainer = styled(HBox)`
    border-radius: 5px;
    border: 1px solid ${colors.grey};
    background-color: ${props => props.occupied ? colors.lightBlue : 'white'};

    :hover {
        background-color: ${props => props.occupied ? colors.lightBlue : colors.lightGrey};
    }
`

const DeleteIconComponent = forwardRef(function (props, ref) {  // As tooltip is not working on react icons
    // Spread the props to the underlying DOM element.
    return <div {...props} ref={ref}>
              <FiX />
           </div>
});

const SlotCard = ({ index, id, timeSpan, isOccupied, isUrgent, slots, setSlots, doctorId, setOpenAppSlotManage }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openPtSearchDlg, setOpenPtSearchDlg] = useState(false);

    const handleDelete = () => {
        axios({
            method: 'DELETE',
            url: `nhadmin/consult-slot-details/`,
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 202) {
                let values = [...slots];
                values.splice(index, 1);
                setSlots(values);

                enqueueSnackbar('Slot delete success.', { variant: 'success' });
            } else {
                enqueueSnackbar('Slot delete failed!', { variant: 'error' });
                console.log('CONSULTATION SLOT DELETE FAILED', response.status);
            }
        })
        .catch((error) => {
            enqueueSnackbar('Slot delete error!', { variant: 'error' });
            console.log('CONSULTATION SLOT DELETE ERROR', error);
        });
    }

    return (
        <SlotContainer className="p-1 m-0_5" occupied={isOccupied}>
            <Tooltip title={isOccupied ? 'Occupied' : 'Book Appointment for a Patient'} arrow>
                <P2 className="clickable" onClick={() => !isOccupied && setOpenPtSearchDlg(true)}>
                    <span style={{ color: colors.red }}>{isUrgent ? "(URGENT) " : ""}</span>{timeSpan}
                </P2>
            </Tooltip>
            {!isOccupied &&
                <Tooltip title='Delete' arrow>
                    <DeleteIconComponent
                        className="ml-1 clickable"
                        style={{ color: colors.red }}
                        onClick={() => setOpenConfirm(true)}
                    />
                </Tooltip>}
            
            <Confirm
                message="Are you sure to delete this slot?"
                btnColor="second"
                onConfirm={handleDelete}
                open={openConfirm}
                setOpen={setOpenConfirm}
            />
            <SearchPatientDlg
                open={openPtSearchDlg}
                setOpen={setOpenPtSearchDlg}
                doctorId={doctorId}
                slotId={id}
                setOpenAppSlotManage={setOpenAppSlotManage}
            />
        </SlotContainer>
    )
}

const DayPickerDlg = ({open, setOpen, date, setDate}) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="xs" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Select a Date</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-4' align='center'>
                <DayPicker
                    onDayClick={(date) => {setDate(date); setOpen(false)}}
                    selectedDays={date}
                />
            </VBox>
        </Dialog>
    )
}

const AppointSlotsManagerDlg = ({ open, setOpen, doctorId, doctorName }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isUrgent, setIsUrgent] = useState(false);
    const [date, setDate] = useState(new Date());  // To view slots
    const [startDate, setStartDate] = useState("");  // To create slots
    const [endDate, setEndDate] = useState("");  // To create slots
    const [startDateDel, setStartDateDel] = useState("");  // To delete slots
    const [endDateDel, setEndDateDel] = useState("");  // To delete slots
    const [time1, setTime1] = useState("");
    const [time2, setTime2] = useState("");
    const [consultDuration, setConsultDuration] = useState("");
    const [slots, setSlots] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [openDate, setOpenDate] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (open) {
            enqueueSnackbar("Loading...", { persist: true });
            getSlots();
        }
    }, [open, doctorId]);

    useEffect(() => getSlots(), [date]);

    const getSlots = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `nhadmin/consult-slot-list/`,
            params: {
                doctor_id: doctorId,
                date: date,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setSlots(response.data);
            } else {
                console.log('CONSULT SLOTS FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('CONSULT SLOTS FETCH ERROR', error);
        })
    }

    const validateCreate = () => {
        if (!startDate | !endDate | !time1 | !time2 | !consultDuration) {
            enqueueSnackbar('Please fill up all the fields.', {variant: 'info'});
            return false;
        }
        else {
            return true;
        }
    }

    const validateDelete = () => {
        if (!startDateDel | !endDateDel) {
            enqueueSnackbar('Please fill up all the fields.', {variant: 'info'});
            return false;
        }
        else {
            return true;
        }
    }

    const handleCreateSlots = () => {
        if (validateCreate()) {
            enqueueSnackbar('Generating slots...', { persist: true });
            axios({
                method: 'POST',
                url: `nhadmin/consult-slot-list/`,
                data: {
                    doctor_id: doctorId,
                    is_urgent: isUrgent,
                    start_date: startDate,
                    end_date: endDate,
                    time1: time1,
                    time2: time2,
                    consult_duration: consultDuration
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
                }
            })
            .then((response) => {
                closeSnackbar();
                if (response.status === 201) {
                    setStartDate("");
                    setEndDate("");
                    setTime1("");
                    setTime2("");
                    setConsultDuration("");
                    getSlots();
                    enqueueSnackbar('Slots generated.', { variant: 'success' });
                } else {
                    enqueueSnackbar('Slots generation failed!', { variant: 'error' });
                    console.log('CONSULTATION SLOTS CREATE FAILED', response.status);
                }
            })
            .catch((error) => {
                closeSnackbar();
                enqueueSnackbar('Slots generation error!', { variant: 'error' });
                console.log('CONSULTATION SLOTS CREATE ERROR', error);
            });
        }
    }

    const handleDeleteSlots = () => {
        enqueueSnackbar('Deleting slots...', { persist: true });
        axios({
            method: 'DELETE',
            url: `nhadmin/consult-slot-details/`,
            data: {
                type: 'bulk',
                doctor_id: doctorId,
                start_date: startDateDel,
                end_date: endDateDel
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 202) {
                setStartDateDel("");
                setEndDateDel("");
                getSlots();
                enqueueSnackbar('Slots deleted.', { variant: 'success' });
            } else {
                enqueueSnackbar('Slots deletion failed!', { variant: 'error' });
                console.log('CONSULTATION SLOTS DELETE FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Slots deletion error!', { variant: 'error' });
            console.log('CONSULTATION SLOTS DELETE ERROR', error);
        });
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }
    
    return (
        <Dialog fullScreen scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="pt-1 pb-1" style={{ backgroundColor: colors.lightGrey }}>
                <HBox align="center">
                    <H3 className='ml-2'>Appointment Slots Manager</H3>
                    <P1 className="ml-1">for <span style={{ color: colors.red }}>{doctorName}</span></P1>
                </HBox>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <HBox>
                {/* {isMobile && <TopPanel justify='center' className='p-3'>
                    <Button size='sm' color='first' className="mr-1">Slots Manager</Button>
                </TopPanel>}
                {!isMobile && <LeftPanel style={{ height: 'calc(100vh - 50px)' }}>
                    <NavBtnActive size="sm" color="first" className="mt-8" outlined>
                        <div className="ml-1">Slots Manager</div>
                    </NavBtnActive>
                </LeftPanel>} */}
                <CenterPanel style={{ width: isMobile ? '100%' : '80%' }}>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 50px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className="p-3">
                            {isMobile && <DatePanelMobile justify='center' align='center' className='p-2 mb-2' onClick={() => setOpenDate(true)}>
                                <Button color='second' size='sm'>
                                    <FiCalendar className='mr-1' />
                                    {getDate(date, 'long')}
                                </Button>
                            </DatePanelMobile>}
                            
                            <Paper className="p-4 mb-2">
                                <H3 className="mb-0_5" color='first'>Slots</H3>
                                <P2 className="mb-3">On {getDate(date, 'long')}</P2>
                                <HBox>
                                    {slots.length ?
                                        slots.map((slot, index) => (
                                            <SlotCard
                                                className="m-1"
                                                index={index}
                                                id={slot.id}
                                                timeSpan={`${getTime(slot.start_time)} - ${getTime(slot.end_time)}`}
                                                isOccupied={slot.is_occupied}
                                                isUrgent={slot.is_urgent}
                                                slots={slots}
                                                setSlots={setSlots}
                                                doctorId={doctorId}
                                                setOpenAppSlotManage={setOpen}
                                            />
                                        ))
                                        :
                                        <P2>No slots.</P2>
                                    }
                                </HBox>
                            </Paper>

                            <Paper className="p-4 mb-2">
                                <H3 color='first' className="mb-0_5">Create Slots</H3>
                                <HBox className="mb-3" style={{ width: '100%' }}>
                                    <P2>Set dates. Fill up start session and end session time.
                                        Each session can have multiple appointments.
                                        Put individual appointment duration in minutes.
                                        After that press <span style={{ color: colors.blue }}>"+ Add"</span> button to generate slots.
                                    </P2>
                                </HBox>
                                {/* <Tooltip title='For appointments between 12 AM to 6AM with higher fees.' arrow>
                                    <HBox align='center' className='mb-1' style={{ width: 'fit-content' }}>
                                        <P2>Mark as Urgent?</P2>
                                        <input
                                            style={{ transform: 'scale(1.25)' }}
                                            className='mx-1'
                                            type='checkbox'
                                            defaultChecked={isUrgent}
                                            onChange={e => setIsUrgent(e.target.checked)}
                                        />
                                    </HBox>
                                </Tooltip> */}
                                <HBox align='flex-end' justify='flex-start' style={{ width: '100%' }}>
                                    <HBox className="mb-0_5" style={{ width: isMobile ? '50%' : '29%' }}>
                                        <InputText
                                            className='mr-1'
                                            style={{ width: '100%' }}
                                            label='Start Date'
                                            type='date'
                                            value={startDate}
                                            onChange={e => setStartDate(e.target.value)}
                                        />
                                    </HBox>
                                    <HBox className="mb-0_5" style={{ width: isMobile ? '50%' : '29%' }}>
                                        <InputText
                                            className='mr-1'
                                            style={{ width: '100%' }}
                                            label='End Date'
                                            type='date'
                                            value={endDate}
                                            onChange={e => setEndDate(e.target.value)}
                                        />
                                    </HBox>
                                </HBox>
                                <HBox align='flex-end' justify='flex-start' style={{ width: '100%' }}>
                                    <HBox className="mb-0_5" style={{ width: isMobile ? '50%' : '29%' }}>
                                        <InputText
                                            className='mr-1'
                                            style={{ width: '100%' }}
                                            label='Session Start Time'
                                            type='time'
                                            value={time1}
                                            onChange={e => setTime1(e.target.value)}
                                        />
                                    </HBox>
                                    <HBox className="mb-0_5" style={{ width: isMobile ? '50%' : '29%' }}>
                                        <InputText
                                            className='mr-1'
                                            style={{ width: '100%' }}
                                            label='Session End Time'
                                            type='time'
                                            value={time2}
                                            onChange={e => setTime2(e.target.value)}
                                        />
                                    </HBox>
                                    <HBox className="mb-0_5" style={{ width: isMobile ? '50%' : '29%' }}>
                                        <InputText
                                            className='mr-1'
                                            style={{ width: '100%' }}
                                            label='Individual Appointment Duration'
                                            type='number'
                                            placeholder='in minutes'
                                            value={consultDuration}
                                            onChange={e => setConsultDuration(e.target.value)}
                                        />
                                    </HBox>
                                    <HBox justify='flex-start' className="mb-0_5" style={{ width: isMobile ? '50%' : '12%' }}>
                                        <Button
                                            className='ml-1'
                                            style={{ height: '2em' }}
                                            size='sm'
                                            color="first"
                                            onClick={handleCreateSlots}
                                        >
                                            <FiPlus className='mr-1' />
                                            Add
                                        </Button>
                                    </HBox>
                                </HBox>
                            </Paper>

                            <Paper className="p-4">
                                <H3 color='first' className="mb-0_5">Delete Slots</H3>
                                <HBox className="mb-3" style={{ width: '100%' }}>
                                    <P2>Set dates.
                                        After that press <span style={{ color: colors.red }}>"Delete"</span> button to delete slots within the date range.
                                    </P2>
                                </HBox>

                                <HBox align='flex-end' justify='flex-start' style={{ width: '100%' }}>
                                    <HBox className="mb-0_5" style={{ width: isMobile ? '50%' : '29%' }}>
                                        <InputText
                                            className='mr-1'
                                            style={{ width: '100%' }}
                                            label='Start Date'
                                            type='date'
                                            value={startDateDel}
                                            onChange={e => setStartDateDel(e.target.value)}
                                        />
                                    </HBox>
                                    <HBox className="mb-0_5" style={{ width: isMobile ? '50%' : '29%' }}>
                                        <InputText
                                            className='mr-1'
                                            style={{ width: '100%' }}
                                            label='End Date'
                                            type='date'
                                            value={endDateDel}
                                            onChange={e => setEndDateDel(e.target.value)}
                                        />
                                    </HBox>
                                    <HBox justify='flex-start' className="mb-0_5" style={{ width: isMobile ? '50%' : '12%' }}>
                                        <Button
                                            className='ml-1'
                                            style={{ height: '2em' }}
                                            size='sm'
                                            color="second"
                                            onClick={() => validateDelete() && setOpenDeleteConfirm(true)}
                                        >
                                            <FiTrash className='mr-1' />
                                            Delete
                                        </Button>
                                    </HBox>
                                </HBox>
                            </Paper>
                        </VBox>
                    </Scrollbars>
                </CenterPanel>
                {!isMobile && <RightPanel style={{ height: 'calc(100vh - 50px)', backgroundColor: colors.lightGrey }}>
                    <Scrollbars
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className="" align="center" style={{ width: '100%' }}>
                            <DayPicker
                                onDayClick={setDate}
                                selectedDays={date}
                            />
                            {isLoading && <LoadingChip className="p-0_5 mt-2">
                                <P2>Loading slots...</P2>
                            </LoadingChip>}
                        </VBox>
                    </Scrollbars>
                </RightPanel>}

                {isMobile && <DayPickerDlg
                    open={openDate}
                    setOpen={setOpenDate}
                    date={date}
                    setDate={setDate}
                />}

                <Confirm
                    message="Are you sure to delete the slots?"
                    btnColor="second"
                    onConfirm={handleDeleteSlots}
                    open={openDeleteConfirm}
                    setOpen={setOpenDeleteConfirm}
                />
            </HBox>
        </Dialog>
    )
}

export default AppointSlotsManagerDlg;
