import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Quill from 'quill';
import ReactQuill from 'react-quill';
import ImageResize from 'quill-image-resize';
import 'react-quill/dist/quill.snow.css';

import { HBox, VBox } from "../../components/Containers";
import { LowerNav } from "../../layouts/Navbar";
import { Button, NavBtnActive, NavBtn } from '../../components/Buttons';
import BlogTable from './components/BlogTable';
import colors from "../../config/colors";
import Footer from "../../layouts/Footer";

const LeftPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-right: 1px solid ${colors.grey};
`

const RightPanel = styled(VBox)`
    width: 80%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Blogs = () => {
    var q = ReactQuill.Quill
    var font = q.import('formats/font');
    font.whitelist = ['SansSerif', 'NotoSans'];
    q.register(font, true);

    Quill.register('modules/imageResize', ImageResize);

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
                        
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      
            [{ 'indent': '-1'}, { 'indent': '+1' }],   
            [{ 'direction': 'rtl' }],                         
          
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          
            [{ 'color': [] }, { 'background': [] }],   
            [{ 'font': font.whitelist }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
          
            ['clean']                                         
        ],

        imageResize: {
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        },
    }

    return (
        <>
            <LowerNav selected='event-logs' />
            <HBox>
                <LeftPanel>
                    <NavBtnActive size="sm" color="first" className="mt-8" outlined>
                        <div className="ml-1">Blogs</div>
                    </NavBtnActive>
                    <SLink to='/blog-categories'>
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">Blog Categories</div>
                        </NavBtn>
                    </SLink>
                </LeftPanel>
                <RightPanel>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 50px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='pl-4 pr-4 pb-4'>
                            <BlogTable modules={modules} />
                        </VBox>
                    </Scrollbars>
                </RightPanel>
            </HBox>
            <Footer />
        </>
    )
}

export default Blogs;