import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const AddPtAppBannerDlg = ({ open, setOpen, setBanners }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const inputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [link, setLink] = useState("");

    useEffect(() => {
        const init = () => {
            setFile(null);
            setLink("");
        }
        if (open) init();
    }, [open]);

    const handleClickBrowse = event => {
        inputRef.current.click();
    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            e.target.value = null;
        }
    };

    const handleSave = () => {
        if (validate()) {
            enqueueSnackbar('Adding the banner...', { persist: true });
            const uploadData = new FormData();
            uploadData.append('file', file, file.name);
    
            axios({
                method: "POST",
                url: 'nhadmin/patient-app-banner-list/',
                data: uploadData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            }).then(function (res) {
                if (res.status === 201) {
                    setBanners(banners => [...banners, res.data]);

                    closeSnackbar();
                    enqueueSnackbar('Successfully added.', {variant: 'success'});
                    setOpen(false);
                }
                else {
                    closeSnackbar();
                    console.log('BANNER ADD FAILURE');
                    enqueueSnackbar('Banner add failure!', {variant: 'error'});
                }
            }).catch((error) => {
                closeSnackbar();
                console.log('BANNER ADD ERROR', error);
                enqueueSnackbar('Banner add error!', {variant: 'error'});
            });
        }
    }

    const validate = () => {
        var ok = true;

        if (!file) {
            enqueueSnackbar('Please select a banner image.', { variant: 'error' });
            ok = false;
        }
        else if (!link) {
            enqueueSnackbar('Please fill up the link field.', { variant: 'error' });
            ok = false;
        }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="xs" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Add Banner</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                <HBox align='center'>
                    <Button
                        size="sm"
                        color="first"
                        className="mr-1"
                        onClick={handleClickBrowse}
                    >
                        Browse Banner Image
                        <input
                            type="file"
                            accept="image/*"
                            ref={inputRef}
                            onChange={onSelectFile}
                            hidden
                        />
                    </Button>
                    {file && <P2 color='second'>File added.</P2>}
                </HBox>
                <InputText
                    className='mt-1'
                    placeholder="Link (Clicking on banner redirect to the link.)"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleSave}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default AddPtAppBannerDlg;