import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { BiImport } from "react-icons/bi";
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from "react-router-dom";

import { HBox, VBox } from "../../components/Containers";
import { Button, NavBtnActive, NavBtn } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import colors from "../../config/colors";
import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import DiagnosticTable from './components/DiagnosticTable';

const LeftPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-right: 1px solid ${colors.grey};
`

const RightPanel = styled(VBox)`
    width: 80%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const DiagnosticDatabase = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const inputRef = useRef(null);

    const handleClickBrowse = event => {
        inputRef.current.click();
    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            handleAddFile(e.target.files[0]);
            e.target.value = null;
        }
    };

    const handleAddFile = (uploadFile) => {
        const uploadData = new FormData();
        uploadData.append('file', uploadFile, uploadFile.name);

        enqueueSnackbar("Importing... Please wait a moment.", { persist: true });
        axios({
            method: "POST",
            url: 'nhadmin/import-diagnostic-database/',
            data: uploadData,
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
            },
        }).then(function (res) {
            if (res.status === 201) {
                closeSnackbar();
                enqueueSnackbar('Successfully imported.', {variant: 'success'});
            }
            else {
                closeSnackbar();
                enqueueSnackbar('Import failure!', {variant: 'error'});
            }
        }).catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Import error!', {variant: 'error'});
        });
    }

    return (
        <>
            <LowerNav selected='databases' />
            <HBox>
                <LeftPanel align="center">
                    <Button
                        size="sm"
                        color="first"
                        className=""
                        outlined
                        onClick={handleClickBrowse}
                    >
                        <BiImport fontSize={16} className='mr-1' />
                        Import from CSV
                        <input
                            type="file"
                            accept=".csv"
                            ref={inputRef}
                            onChange={onSelectFile}
                            hidden
                        />
                    </Button>

                    <SLink to="/drug-database">
                        <NavBtn size="sm" color="first" className="mt-8" outlined>
                            <div className="ml-1">Drugs</div>
                        </NavBtn>
                    </SLink>
                    <NavBtnActive size="sm" color="first" className="mt-1" outlined>
                        <div className="ml-1">Diagnostic Names</div>
                    </NavBtnActive>
                    <SLink to="/institution-database">
                        <NavBtn size="sm" color="first" className="mt-1" outlined>
                            <div className="ml-1">Institutions</div>
                        </NavBtn>
                    </SLink>
                </LeftPanel>
                <RightPanel>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 50px)' }}
                        renderThumbVertical={({ style, ...props }) =>
                            <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
                    }>
                        <VBox className='p-4'>
                            <DiagnosticTable />
                        </VBox>
                    </Scrollbars>
                </RightPanel>
            </HBox>
            <Footer />
        </>
    )
}

export default DiagnosticDatabase;