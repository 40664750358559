import styled from "styled-components";
import { useState } from "react";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { Link } from "react-router-dom";

import { VBox, HBox } from "../../../components/Containers";
import { InputText } from "../../../components/InputText";
import { H1, H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from "../../../components/Buttons";
import colors from "../../../config/colors";
import ResetPasswordDlg from './ResetPasswordDlg';

const SLink = styled(Link)`
    text-decoration: underline;
    color: ${colors.darkGrey};

`

const Form = () => {
    let phonePrefix = '+88';
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [phoneOrEmail, setPhoneOrEmail] = useState("");
    const [openResetPass, setOpenResetPass] = useState(false);

    const [phoneOrEmailError, setPhoneOrEmailError] = useState("");

    const sendOTP = () => {
        if (validate()) {
            enqueueSnackbar('Sending OTP...', { persist: true });
            axios({
                method: 'POST',
                url: 'auth/password-reset-send/',
                data: {
                    phone_or_email: phoneOrEmail.includes('@') ? phoneOrEmail : phonePrefix+phoneOrEmail,
                }
            }).then(res => {
                closeSnackbar();
                if (res.status === 200) {
                    setOpenResetPass(true);
                }
                else {
                    enqueueSnackbar('OTP send failed!', {variant: 'error'});
                }
            }).catch(error => {
                console.log('OTP SEND ERROR', error);
                closeSnackbar();
                if (error.response.status === 400) {
                    enqueueSnackbar(error.response.data.message, {variant: 'error'});
                }
            });
        }
    }

    const validate = () => {
        var ok = true;

        if (phoneOrEmail.includes('@')) {
            if (phoneOrEmail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setPhoneOrEmailError("");
            }
            else {
                setPhoneOrEmailError("Please put a valid email address.")
                ok = false;
            }
        }
        else if (phoneOrEmail.match(/^(?:[+\d].*\d|\d)$/)) {
            if (phoneOrEmail.match(/^01\d{9}$/)) {
                setPhoneOrEmailError("");
            }
            else if (phoneOrEmail.match(/^\+8801\d{9}$/)) {
                setPhoneOrEmailError("Please omit (+88) from your phone number.");
                ok = false;
            }
            else {
                setPhoneOrEmailError("Please enter a valid phone number.");
                ok = false;
            }
        }
        else {
            setPhoneOrEmailError("Username format is incorrect.");
            ok = false;
        }

        return ok;
    }

    return (
        <VBox align="center" style={{ width: '30vw' }}>
            <H1 className="mb-1">Forgot Password?</H1>
            <P1 align="center" className="mb-3">Please enter your phone number or email and click <span style={{ color: colors.blue }}>"Send OTP"</span> button.</P1>
            <HBox>
                <InputText
                    placeholder="Phone or email"
                    value={phoneOrEmail}
                    helpText={phoneOrEmailError}
                    onChange={(e) => setPhoneOrEmail(e.target.value)}
                />
                <Button
                    className="ml-1"
                    size="sm"
                    color="first"
                    onClick={sendOTP}
                >
                    Send OTP
                </Button>
            </HBox>
            <SLink to='/login' className='mt-4'>
                <P1>Go back to login page?</P1>
            </SLink>

            <ResetPasswordDlg
                open={openResetPass}
                setOpen={setOpenResetPass}
                phoneOrEmail={phoneOrEmail}
            />
        </VBox>
    )
}

export default Form;