import styled from "styled-components";
import { Dialog } from '@mui/material';

import { HBox, VBox } from "../components/Containers";
import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import { Button, IconButton } from "../components/Buttons";
import colors from "../config/colors";

const Confirm = ({ message, open, setOpen, onConfirm, btnTxt="Yes", btnColor="first" }) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="xs" open={open} onClose={handleClose} fullWidth>
            <VBox className="p-3" align="center" style={{ backgroundColor: colors.lightGrey }}>
                <H4 className="" align="center">{message}</H4>
                <HBox className="mt-2">
                    <Button size="sm" onClick={handleClose}>Close</Button>
                    <Button
                        className="ml-2"
                        size="sm"
                        color={btnColor}
                        onClick={() => {handleClose(); onConfirm()}}
                    >
                        {btnTxt}
                    </Button>
                </HBox>
            </VBox>
        </Dialog>
    );
}

export default Confirm;