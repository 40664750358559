import { useState, useEffect } from 'react';
import { Dialog } from "@mui/material";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import ReactQuill from 'react-quill';
import axios from "axios";
import moment from 'moment';

import { HBox, VBox } from "../../../components/Containers";
import { H1, H3, H4, P2 } from "../../../components/Typography";
import { IconButton } from "../../../components/Buttons";
import colors from "../../../config/colors";
import 'react-quill/dist/quill.bubble.css';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const BlogContainer = styled(VBox)`
    flex-wrap: nowrap;
    overflow-y: auto;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CategoryChip = styled(HBox)`
    background-color: ${colors.blue};
    border-radius: 5px;
    color: ${colors.veryLightGrey};
`

const BlogViewDlg = ({ open, setOpen, id }) => {
    const [coverImage, setCoverImage] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [authorName, setAuthorName] = useState('');
    const [authorDetails, setAuthorDetails] = useState('');
    const [authorImage, setAuthorImage] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [categories, setCategories] = useState([]);
    
    useEffect(() => {
        if(open) {
            getData();
        }
    }, [open])

    const getData = () => {
        axios({
            method: 'GET',
            url: 'nhadmin/blog-details/',
            params: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if(response.status === 200) {
                setCoverImage(response.data.cover_image ? response.data.cover_image : '');
                setTitle(response.data.title ? response.data.title : '');
                setContent(response.data.content ? response.data.content : '');
                setAuthorName(response.data.author ? (response.data.author.name ? response.data.author.name : '') : '');
                setAuthorDetails(response.data.author ? (response.data.author.details ? response.data.author.details : '') : '');
                setAuthorImage(response.data.author ? (response.data.author.image ? response.data.author.image : '') : '');
                setCreatedAt(response.data.created_at ? response.data.created_at : '');
                setCategories(response.data.category ? response.data.category : []);
            }
            else {
                console.log('BLOG VIEW FETCH FAILED');
            }
        }).catch((error) => {
            console.log('BlOG VIEW FETCH ERROR', error);
        })
    }
    
    const handleClose = (event, reason) => {
        if(reason && reason == 'backdropClick')
            return;
        
        setOpen(false);
        setCoverImage('');
        setTitle('');
        setContent('');
        setAuthorName('');
        setAuthorDetails('');
        setAuthorImage('');
        setCreatedAt('');
        setCategories([]);
    }

    return (
        <Dialog 
            fullScreen 
            scroll='body' 
            open={open} 
            onClose={handleClose}
        >
            <HBox align="center" justify='space-between' className="py-1" style={{ backgroundColor: colors.lightGrey }}>
                <H3 className='ml-2'>Blog Post</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <BlogContainer className='p-4'>
                <HBox className='mb-4' justify='center'>
                    <H1 align='center'>
                        {title}
                    </H1>
                </HBox>
                
                {
                    coverImage ?
                        <HBox className='mb-4' justify='center'>
                            <img src={`${baseURL}${coverImage}`} style={{height: '400px', width: 'auto'}} />
                        </HBox>
                        :
                        <></>
                }

                <ReactQuill value={content} readOnly={true} theme={'bubble'} />

                <HBox className='mt-6'>
                    <HBox align='center'>
                        <H4>Category:</H4>
                    </HBox>
                    {
                        categories?.map((category, index)=>(
                            <CategoryChip className='ml-4 p-1'>{category.name}</CategoryChip>
                        ))
                    }
                </HBox>

                <Line className='mt-6' />
                <HBox className='mt-4 mb-4' justify='space-between'>
                    <HBox>
                        {
                            authorImage ? 
                                <img src={`${baseURL}${authorImage}`} style={{height: '50px', width: 'auto'}} />
                                :
                                <></>
                        }
                        <VBox className='ml-4'>
                            <P2 className='bold'>{authorName}</P2>
                            <P2>{authorDetails}</P2>
                        </VBox>
                    </HBox>
                    <HBox>
                        {moment(createdAt).format('LLL')}
                    </HBox>
                </HBox>
                <Line />
            </BlogContainer>
        </Dialog>
    )
}

export default BlogViewDlg;