import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const AddAppointScheduleDlg = ({doctorId, open, setOpen, schedules, setSchedules}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [schedule, setSchedule] = useState({
        startTime: '',
        endTime: '',
        friday: false,
        saturday: false,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false
    });

    const [startTimeError, setStartTimeError] = useState("");
    const [endTimeError, setEndTimeError] = useState("")
    const [dayCheckError, setDayCheckError] = useState("");

    useEffect(() => {
        const init = () => {
            setSchedule({
                startTime: '',
                endTime: '',
                friday: false,
                saturday: false,
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false
            });

            setStartTimeError("");
            setEndTimeError("");
            setDayCheckError("");
        }
        if (open) init();
    }, [open]);

    const handleSave = () => {
        if (validate()) {
            axios({
                method: "POST",
                url: 'nhadmin/appointment-schedule-list/',
                data: {
                    doctor_id: doctorId,
                    schedule: schedule
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            }).then(function (res) {
                if (res.status === 201) {
                    setSchedules([res.data, ...schedules]);

                    enqueueSnackbar('Appointment schedule add success.', { variant: 'success' });
                    setOpen(false);
                }
                else {
                    console.log('APPOINTMENT SCHEDULE CREATE FAILED');
                    enqueueSnackbar('Appointment schedule add failed!', { variant: 'error' });
                }
            }).catch((error) => {
                console.log('APPOINTMENT SCHEDULE CREATE ERROR', error);
                enqueueSnackbar('Appointment schedule add error!', { variant: 'error' });
            });
        }
    }

    const validate = () => {
        var ok = true;
        
        if (!schedule.startTime) {
            setStartTimeError('This field cannot be kept blank.');
            ok = false;
        }
        else {
            setStartTimeError('');
        }

        if (!schedule.endTime) {
            setEndTimeError('This field cannot be kept blank.');
            ok = false;
        }
        else {
            setEndTimeError('');
        }

        if (!schedule.saturday && !schedule.sunday && !schedule.monday &&
            !schedule.tuesday && !schedule.wednesday && !schedule.thursday &&
            !schedule.friday) {
            setDayCheckError('Please check at least a day.');
            ok = false;
        }
        else {
            setDayCheckError('');
        }

        return ok;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Add Appointment Schedule</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-2'>
                <HBox className=''>
                    <HBox className='m-0_5 clickable' onClick={e => setSchedule({...schedule, saturday: !schedule.saturday})}>
                        <input
                            type='checkbox'
                            checked={schedule.saturday}
                        />
                        <P2>Saturday</P2>
                    </HBox>
                    <HBox className='m-0_5 clickable' onClick={e => setSchedule({...schedule, sunday: !schedule.sunday})}>
                        <input
                            type='checkbox'
                            checked={schedule.sunday}
                        />
                        <P2>Sunday</P2>
                    </HBox>
                    <HBox className='m-0_5 clickable' onClick={e => setSchedule({...schedule, monday: !schedule.monday})}>
                        <input
                            type='checkbox'
                            checked={schedule.monday}
                        />
                        <P2>Monday</P2>
                    </HBox>
                    <HBox className='m-0_5 clickable' onClick={e => setSchedule({...schedule, tuesday: !schedule.tuesday})}>
                        <input
                            type='checkbox'
                            checked={schedule.tuesday}
                        />
                        <P2>Tuesday</P2>
                    </HBox>
                    <HBox className='m-0_5 clickable' onClick={e => setSchedule({...schedule, wednesday: !schedule.wednesday})}>
                        <input
                            type='checkbox'
                            checked={schedule.wednesday}
                        />
                        <P2>Wednesday</P2>
                    </HBox>
                    <HBox className='m-0_5 clickable' onClick={e => setSchedule({...schedule, thursday: !schedule.thursday})}>
                        <input
                            type='checkbox'
                            checked={schedule.thursday}
                        />
                        <P2>Thursday</P2>
                    </HBox>
                    <HBox className='m-0_5 clickable' onClick={e => setSchedule({...schedule, friday: !schedule.friday})}>
                        <input
                            type='checkbox'
                            checked={schedule.friday}
                        />
                        <P2>Friday</P2>
                    </HBox>
                </HBox>
                <HBox className='mb-2'>
                    <P2 color='second'>{dayCheckError}</P2>
                </HBox>
                <HBox className='mb-2'>
                    <InputText
                        style={{ width: 'calc(50% - 4px)' }}
                        className="mr-0_5"
                        label="Start Time*"
                        type='time'
                        value={schedule.startTime}
                        helpText={startTimeError}
                        onChange={e => setSchedule({...schedule, startTime: e.target.value})}
                    />
                    <InputText
                        style={{ width: 'calc(50% - 4px)' }}
                        className="ml-0_5"
                        label="End Time*"
                        type='time'
                        value={schedule.endTime}
                        helpText={endTimeError}
                        onChange={e => setSchedule({...schedule, endTime: e.target.value})}
                    />
                </HBox>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
                <Button
                    size='sm'
                    color='first'
                    className='ml-1'
                    onClick={handleSave}
                >
                    Save
                </Button>
            </HBox>
        </Dialog>
    )
}

export default AddAppointScheduleDlg;