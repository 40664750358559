import styled from "styled-components";

import colors from "../config/colors";

export const HBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${props => props.justify || "left"};
    align-items: ${props => props.align || "left"};
`

export const VBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: ${props => props.justify || "left"};
    align-items: ${props => props.align || "left"};
`

export const LeftPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-right: 1px solid ${colors.grey};
`

export const CenterPanel = styled(VBox)`
    width: 60%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
`

export const RightPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-left: 1px solid ${colors.grey};
`