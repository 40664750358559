import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow, IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from "axios";
import { useSnackbar } from 'notistack';

import { VBox, HBox } from '../../../components/Containers';
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from '../../../components/InputText';
import { Button } from '../../../components/Buttons';
import colors from "../../../config/colors";

const LoadingTxt = styled(P3)`
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    padding: 4px;
`

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        console.log('next button', count);
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <HBox style={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </HBox>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const InstitutionTable = ({  }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [searchKey, setSearchKey] = useState("");
    const [institutions, setInstitutions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState(null);
    const [inputTimeout, setInputTimeout] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => {
        getData();
    }, [page, rowsPerPage]);
    useEffect(() => {
        handleSearch();
    }, [searchKey]);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'nhadmin/institution-list/',
            params: {
                key: searchKey,
                offset: page*rowsPerPage,
                limit: page*rowsPerPage+rowsPerPage
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setInstitutions(response.data.institutions);
                setCount(response.data.count);
            } else {
                console.log('INSTITUTION LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('INSTITUTION LIST FETCH ERROR', error);
        })
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearch = () => {
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setPage(0);
                    getData();
            }, 300)
        )
    }

    return (
        <>
        <HBox justify="space-between" align="center" className='mb-1'>
            <HBox>
                <H3 color='second' align='left'>Institution Table</H3>
                {isLoading && <LoadingTxt className='ml-1'>Loading...</LoadingTxt>}
            </HBox>
            <InputText
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                placeholder="Search for a keyword..."
            />
        </HBox>
        <VBox style={{ border: `1px solid ${colors.grey}`, borderRadius: 5 }}>
            <Table sx={{ minWidth: 500 }} size='small'>
                <TableHead style={{ backgroundColor: colors.lightGrey }}>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>Category</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {institutions.map((institution) => (
                        <TableRow key={institution.id} style={{ height: 33 }}>
                            <TableCell>
                                {institution.category}
                            </TableCell>
                            <TableCell>
                                {institution.name}
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                            <TableCell colSpan={2} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            colSpan={2}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </VBox>
        </>
    );
}

export default InstitutionTable;