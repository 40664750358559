import { useState, useEffect } from 'react';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX } from "react-icons/fi";
import axios from "axios";
import { useSnackbar } from 'notistack';

import { HBox, VBox } from "../../../components/Containers";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button, IconButton } from "../../../components/Buttons";
import { InputText } from "../../../components/InputText";
import colors from "../../../config/colors";
import { getDate, getTime } from '../../../utils';

const colorProps = {
    Pending: '#ff6600',
    Paid: colors.green,
    Refunded: colors.darkGrey,
    Holded: colors.red,
    Yes: colors.green,
    No: colors.red,
    default: colors.darkGrey,
};

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const CloseBtn = styled(Button)`
    border: none;
    color: ${colors.darkGrey};
    background-color: white;

    :hover {
        color: ${colors.darkGrey};
        background-color: ${colors.veryLightGrey};
    }
`

const StatusChip = styled(HBox)`
    padding: 2px 5px;
    border-radius: 5px;
    color: white;
    background-color: ${props => colorProps[props.status || 'default']};
    max-width: max-content;
`

const AppointmentDetailsDlg = ({ appointmentId, open, setOpen }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [appointmentDetails, setAppointmentDetails] = useState({});
    const [doctorDetails, setDoctorDetails] = useState({});
    const [patientDetails, setPatientDetails] = useState({});

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open]);

    const getData = () => {
        enqueueSnackbar("Loading appointment details...", { persist: true });
        axios({
            method: 'GET',
            url: 'nhadmin/appointment-details/',
            params: {
                appointment_id: appointmentId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                setAppointmentDetails(response.data.appointment);
                setDoctorDetails(response.data.doctor);
                setPatientDetails(response.data.patient);
            } else {
                console.log('APPOINTMENT DETAILS FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('APPOINTMENT DETAILS FETCH ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <>
        <Dialog maxWidth="sm" scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <H3 className='ml-2'>Appointment Details</H3>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox className='p-4'>
                <table>
                    <tr>
                        <td style={{ width: '190px' }}><P2>Appointment ID</P2></td>
                        <td><P2 className='bold'>{appointmentDetails.id}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Appointment Type</P2></td>
                        <td><P2 color={appointmentDetails.type === 'New' ? 'first' : 'third'}>{appointmentDetails.type}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Specialty</P2></td>
                        <td><P2 color='second'>{doctorDetails.specialty}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Date</P2></td>
                        <td><P2>{getDate(appointmentDetails.date)}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Start Time</P2></td>
                        <td><P2>{getTime(appointmentDetails.start_time)}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>End Time</P2></td>
                        <td><P2>{getTime(appointmentDetails.end_time)}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Consulted?</P2></td>
                        <td>{appointmentDetails.is_consulted ? <P2 color='third'>Yes</P2> : <P2 color='second'>No</P2>}</td>
                    </tr>
                    <tr>
                        <td><P2>Payment Status</P2></td>
                        <td>
                            <StatusChip
                                status={`${appointmentDetails.payment_status}`}
                                // className='clickable'
                                // onClick={() => {setSelectedAppointIndex(index); setOpenChangePayStatus(true)}}
                            >
                                {appointmentDetails.payment_status}
                            </StatusChip>
                        </td>
                    </tr>
                    <tr>
                        <td><P2>Payment Method</P2></td>
                        <td><P2>{appointmentDetails.payment_method ? appointmentDetails.payment_method : 'N/A'}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Pay Amount (৳)</P2></td>
                        <td>{appointmentDetails.payment_status === 'Paid' ? appointmentDetails.pay_amount : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td><P2>Refund Amount (৳)</P2></td>
                        <td>{appointmentDetails.payment_status === 'Refunded' ? appointmentDetails.refund_amount : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td><P2 className='mt-2'>Doctor ID</P2></td>
                        <td><P2 className='mt-2'>{doctorDetails.id}</P2></td>
                    </tr>
                    <tr>
                        <td><P2 className=''>User ID</P2></td>
                        <td><P2 className=''>{doctorDetails.user_id}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>BMDC#</P2></td>
                        <td><P2>{doctorDetails.doctor_type === 'MBBS' ? 'A-' : ''}{doctorDetails.bmdc}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Doctor Name</P2></td>
                        <td><P2 className='bold'>{doctorDetails.full_name}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Doctor Phone</P2></td>
                        <td><P2>{doctorDetails.phone}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Doctor Email</P2></td>
                        <td><P2>{doctorDetails.email ? doctorDetails.email : 'Not given'}</P2></td>
                    </tr>
                    <tr>
                        <td><P2 className='mt-2'>Patient ID</P2></td>
                        <td><P2 className='mt-2'>{patientDetails.id}</P2></td>
                    </tr>
                    <tr>
                        <td><P2 className=''>User ID</P2></td>
                        <td><P2 className=''>{patientDetails.user_id}</P2></td>
                    </tr>
                    <tr>
                        <td><P2 className=''>Patient Name</P2></td>
                        <td><P2 className='bold'>{patientDetails.full_name}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Patient Phone</P2></td>
                        <td><P2>{patientDetails.phone}</P2></td>
                    </tr>
                    <tr>
                        <td><P2>Patient Email</P2></td>
                        <td><P2>{patientDetails.email ? patientDetails.email : 'Not given'}</P2></td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: 'top' }}><P2>Patient Address</P2></td>
                        <td style={{ verticalAlign: 'top' }}><P2>{patientDetails.address ? patientDetails.address : 'Not given'}</P2></td>
                    </tr>
                </table>
            </VBox>
            <Line />
            <HBox justify="flex-end" className='px-2 py-1'>
                <CloseBtn size='sm' outlined onClick={handleClose}>Close</CloseBtn>
            </HBox>
        </Dialog>
        </>
    )
}

export default AppointmentDetailsDlg;